// app wide hooks
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { useEffect, type EffectCallback } from "react";
import { setCookie } from "./utils/setCookie";
import { getDynamicConfigValue } from "./utils/dynamicConfig";
import { EnvironmentConstants } from "../environmentConstants";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppMountEffect = (fn: EffectCallback) => useEffect(fn, []);
export const useRegion = () => {
  if (getDynamicConfigValue(EnvironmentConstants.ENVIRONMENT) !== "production")
    return "EU";

  const value = `; ${document.cookie}`;
  const parts = value.split(`; userRegion=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }

  const europeanLanguages = [
    "bg",
    "cs",
    "da",
    "de",
    "et",
    "el",
    "en-GB",
    "es",
    "fr",
    "ga",
    "hr",
    "it",
    "lv",
    "lt",
    "hu",
    "mt",
    "nl",
    "pl",
    "pt",
    "ro",
    "sk",
    "sl",
    "fi",
    "sv"
  ];

  const checkEurope = () => {
    const userLanguage = navigator.language;
    const region = europeanLanguages.includes(userLanguage) ? "EU" : "US";
    setCookie("userRegion", region, 365, ".peaka.studio");
    return region;
  };
  return checkEurope();
};
