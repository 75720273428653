import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Dropdown = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonDropdownDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 5
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true
    };
  }
  return commonDef;
};

const getCommonDropdownDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-dropdown-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "Dropdown",
    events: {},
    props: {
      c2FieldType: "Dropdown",
      className: {},
      optionLabel: "label",
      optionValue: "value"
    },
    propMask: {
      options: [
        { label: "Option 1", value: "Option 1" },
        { label: "Option 2", value: "Option 2" },
        { label: "Option 3", value: "Option 3" }
      ]
    },
    name: "Dropdown",
    children: [],
    uiBlock: ["Dropdown", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options",
      displayName: "Options",
      key: "options",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: `{{ [{ label: "Option 1", value: "Option 1" }, { label: "Option 2", value: "Option 2" }, { label: "Option 3", value: "Option 3" }] }}`,
        valueMap: {}
      },
      validateResultAsType: "array"
    },
    {
      name: "optionLabel",
      displayName: "Option Label",
      key: "optionLabel",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "label",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "optionValue",
      displayName: "Option Value",
      key: "optionValue",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "value",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "labelPlacement",
      displayName: "Label Placement",
      key: "labelPlacement",
      type: "SelectButton",
      render: {
        component: "SelectButton",
        options: [
          { label: "Vertical", value: "vertical" },
          { label: "Horizontal", value: "horizontal" }
        ],
        initialValue: "horizontal",
        valueMap: {}
      }
    },
    {
      name: "hint",
      displayName: "Hint",
      key: "hint",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "placeholder",
      displayName: "Placeholder",
      key: "placeholder",
      type: "expr",
      render: { component: "expressioneditor", initialValue: "", valueMap: {} },
      validateResultAsType: "string"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default Dropdown;
