import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Calendar = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonCalendarDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 5
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true
    };
  }
  return commonDef;
};

const getCommonCalendarDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-calendar-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "Calendar",
    events: {},
    props: {
      c2FieldType: "Calendar",
      className: {}
    },
    propMask: {},
    name: "Calendar",
    children: [],
    uiBlock: ["Calendar", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "labelPlacement",
      displayName: "Label Placement",
      key: "labelPlacement",
      type: "SelectButton",
      render: {
        component: "SelectButton",
        options: [
          { label: "Vertical", value: "vertical" },
          { label: "Horizontal", value: "horizontal" }
        ],
        initialValue: "horizontal",
        valueMap: {}
      }
    },
    {
      name: "hint",
      displayName: "Hint",
      key: "hint",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "placeholder",
      displayName: "Placeholder",
      key: "placeholder",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} },
      validateResultAsType: "string"
    },
    {
      name: "showButtonBar",
      displayName: "Show Today",
      key: "showButtonBar",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "range",
      displayName: "Range",
      key: "selectionMode",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: { false: "single", true: "range" }
      },
      validateResultAsType: "boolean"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default Calendar;
