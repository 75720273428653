import { Skeleton } from "primereact/skeleton";

const FlowsSkeleton = () => {
  return (
    <div className="flex w-full h-full">
      <Skeleton
        height="100%"
        width="2.5rem"
        className="border-noround border-right-1 border-800"
      />
      <Skeleton
        height="100%"
        width="calc(100% - 20.5rem)"
        className="border-noround border-right-1 border-800"
      />
      <Skeleton height="100%" width="18rem" className="border-noround" />
    </div>
  );
};

export default FlowsSkeleton;
