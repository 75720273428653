import type { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import createPersistedSlice from "../../app/utils/createPersistedSlice";
import { authSlice } from "../auth/authSlice";
import type { RootState } from "../../app/store";

export interface LocalSessionState {
  data: {
    [key: string]: unknown;
  };
  owner: string | null;
}

const initialState: LocalSessionState = {
  data: {},
  owner: null
};

const [localSessionSlice, localSessionMiddleware] = createPersistedSlice({
  name: "localSession",
  initialState,
  reducers: {
    setLocalSessionDataValue: (
      state,
      action: PayloadAction<{ key: string; value: unknown }>
    ) => {
      if (state.owner) {
        set(state.data, action.payload.key, action.payload.value);
        setLocalSessionData(state.owner, state.data);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.reset, (state) => {
      state.data = {};
      state.owner = null;
    });

    builder.addCase(authSlice.actions.setUser, (state, action) => {
      const user = action.payload;
      if (user == null) {
        state.data = {};
        state.owner = null;
      } else {
        state.data = getLocalSessionData(user.sub);
        state.owner = user.sub;
      }
    });
  }
});

function getLocalSessionData(owner: string) {
  const sessionData = localStorage.getItem(`localSession/${owner}`);
  return sessionData != null ? JSON.parse(sessionData) : {};
}

function setLocalSessionData(owner: string, data: { [key: string]: unknown }) {
  localStorage.setItem(`localSession/${owner}`, JSON.stringify(data));
}

export { localSessionSlice, localSessionMiddleware };

export const { setLocalSessionDataValue } = localSessionSlice.actions;

export const selectLocalSessionData = (state: RootState) =>
  state.localSession.data;

export default localSessionSlice.reducer;
