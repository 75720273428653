import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Badge = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonBadgeDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 1,
      h: 2
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
    commonDef.base.props.style = {
      display: "grid",
      alignContent: "center"
    };
  } else {
    commonDef.base.props.className = {
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonBadgeDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-badge-component",
  disabled: false,
  category: "misc",
  base: {
    package: "primereact",
    type: "Badge",
    key: "Badge",
    events: {},
    props: {
      className: {}
    },
    propMask: {
      severity: "",
      size: "",
      value: "Badge"
    },
    name: "Badge",
    children: [],
    uiBlock: ["DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Label",
      key: "value",
      type: "input",
      render: {
        component: "inputtext",
        initialValue: "Badge",
        valueMap: {}
      }
    },
    {
      name: "severity",
      displayName: "Severity",
      key: "severity",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          { label: "Primary", value: "" },
          { label: "Success", value: "success" },
          { label: "Info", value: "info" },
          { label: "Warning", value: "warning" },
          { label: "Danger", value: "danger" }
        ],
        initialValue: "",
        valueMap: {}
      }
    },
    {
      name: "size",
      displayName: "Size",
      key: "size",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          { label: "Normal", value: "" },
          { label: "Large", value: "large" },
          { label: "XLarge", value: "xlarge" }
        ],
        initialValue: "",
        valueMap: {}
      }
    }
  ],
  eventList: [],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default Badge;
