import * as React from "react";
const SvgIconGithub = (props) => /* @__PURE__ */ React.createElement("svg", { width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_12234_1362)" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip1_12234_1362)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.9275 -5.72205e-06C10.6957 -5.72205e-06 -0.000976562 11 -0.000976562 24.6085C-0.000976562 35.4865 6.85274 44.6945 16.3607 47.9535C17.5494 48.1985 17.9848 47.424 17.9848 46.7725C17.9848 46.202 17.9456 44.2465 17.9456 42.209C11.2893 43.676 9.90319 39.2755 9.90319 39.2755C8.83347 36.4235 7.24849 35.6905 7.24849 35.6905C5.06988 34.183 7.40719 34.183 7.40719 34.183C9.82384 34.346 11.0919 36.709 11.0919 36.709C13.2309 40.457 16.6776 39.398 18.0642 38.746C18.262 37.157 18.8963 36.057 19.5698 35.446C14.2609 34.8755 8.67527 32.757 8.67527 23.3045C8.67527 20.6155 9.62547 18.4155 11.1311 16.7045C10.8936 16.0935 10.0614 13.567 11.3691 10.1855C11.3691 10.1855 13.3896 9.53349 17.9451 12.7115C19.8956 12.1728 21.907 11.8988 23.9275 11.8965C25.9479 11.8965 28.0075 12.182 29.9094 12.7115C34.4655 9.53349 36.4859 10.1855 36.4859 10.1855C37.7936 13.567 36.961 16.0935 36.7234 16.7045C38.2687 18.4155 39.1798 20.6155 39.1798 23.3045C39.1798 32.757 33.5941 34.8345 28.2456 35.446C29.1174 36.22 29.8697 37.6865 29.8697 40.009C29.8697 43.309 29.8305 45.9575 29.8305 46.772C29.8305 47.424 30.2665 48.1985 31.4547 47.954C40.9626 44.694 47.8163 35.4865 47.8163 24.6085C47.8555 11 37.1197 -5.72205e-06 23.9275 -5.72205e-06Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} }))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_12234_1362" }, /* @__PURE__ */ React.createElement("rect", { width: 48, height: 48, fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
}, transform: "translate(-0.000976562)" })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip1_12234_1362" }, /* @__PURE__ */ React.createElement("rect", { width: 48, height: 48, fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
}, transform: "translate(-0.000976562)" }))));
export default SvgIconGithub;
