import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import type { AIMessage } from "./components/QueryAISQLEditor/types";
import type { MultipleTableOrQuerySelectorValue } from "./components/QueryAISQLEditor/helpers/MultipleTableOrQuerySelector";

interface AIMessageAction {
  queryId: string;
  aiSessionId: string;
  messages: AIMessage[];
}

interface AITableSelectAction {
  queryId: string;
  values: MultipleTableOrQuerySelectorValue[];
}

interface AITableSelectedAction {
  queryId: string;
  value: boolean;
}

const initialState: {
  tableSelectedWithQueryId: { queryId: string; value: boolean }[];
  messagesWithQueryId: {
    queryId: string;
    messages: AIMessage[];
    aiSessionId: string;
  }[];
  selectedTablesWithQueryId: {
    queryId: string;
    values: MultipleTableOrQuerySelectorValue[];
  }[];
} = {
  messagesWithQueryId: [],
  selectedTablesWithQueryId: [],
  tableSelectedWithQueryId: []
};

export const AIQuerySlice = createSlice({
  name: "ai-chat",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<AIMessageAction>) => {
      const messagesForQuery = state.messagesWithQueryId.find(
        (messageWithQueryId) =>
          messageWithQueryId.queryId === action.payload.queryId
      );
      if (messagesForQuery) {
        messagesForQuery.messages = action.payload.messages;
      } else {
        state.messagesWithQueryId.push({
          queryId: action.payload.queryId,
          messages: action.payload.messages,
          aiSessionId: action.payload.aiSessionId
        });
      }
    },
    setSelectedTables: (state, action: PayloadAction<AITableSelectAction>) => {
      const selectedTablesForQuery = state.selectedTablesWithQueryId.find(
        (tableWithQueryId) =>
          tableWithQueryId.queryId === action.payload.queryId
      );
      if (selectedTablesForQuery) {
        selectedTablesForQuery.values = action.payload.values;
      } else {
        state.selectedTablesWithQueryId.push({
          queryId: action.payload.queryId,
          values: action.payload.values
        });
      }
    },
    setTableSelected: (state, action: PayloadAction<AITableSelectedAction>) => {
      const tableSelectedForQuery = state.tableSelectedWithQueryId.find(
        (selectedWithQueryId) =>
          selectedWithQueryId.queryId === action.payload.queryId
      );
      if (tableSelectedForQuery) {
        tableSelectedForQuery.value = action.payload.value;
      } else {
        state.tableSelectedWithQueryId.push({
          queryId: action.payload.queryId,
          value: action.payload.value
        });
      }
    },
    reset: (state) => {
      state.messagesWithQueryId = [];
      state.selectedTablesWithQueryId = [];
      state.tableSelectedWithQueryId = [];
    }
  }
});

export const { setMessages, setSelectedTables, reset, setTableSelected } =
  AIQuerySlice.actions;

export const selectAIMessagesWithQueryId = (state: RootState) =>
  state.ai.messagesWithQueryId;

export const selectSelectedTablesWithQueryId = (state: RootState) =>
  state.ai.selectedTablesWithQueryId;

export const selectTableSelectedWithQueryId = (state: RootState) =>
  state.ai.tableSelectedWithQueryId;

export default AIQuerySlice.reducer;
