import type { DesignMode } from "@code2io/fe-engine/dist/types";
import {
  PropertyDefinitionCategory,
  type ComponentDefinition
} from "../../types";
import {
  getEventList,
  getProps,
  getPropDefs,
  getSampleChartColumns,
  getSampleChartDataItems
} from "./utils";

const HeatmapChart = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonAreaChartDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 6,
      h: 22
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      relative: true,
      "h-30rem": true,
      "w-12": true,
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonAreaChartDef = (): ComponentDefinition => ({
  icon: "fa-kit-duotone fa-heatmap-component",
  category: "charts",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Chart",
    key: "HeatmapChart",
    events: {},
    props: {
      className: {},
      chartType: "HeatmapChart"
    },
    propMask: {
      ...getProps({
        options: {
          legend: {
            enabled: true
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                color: ""
              }
            }
          },
          series: [],
          title: { text: "Heatmap Chart" },
          xAxis: {},
          yAxis: {},
          colorAxis: {
            maxColor: ""
          }
        }
      }),
      dataColumns: getSampleChartColumns("HeatmapChart"),
      sampleValue: getSampleChartDataItems("HeatmapChart")
    },
    name: "Heatmap Chart",
    children: [],
    uiBlock: ["DefaultComponent", "Chart"]
  },
  propDefs: getPropDefs(
    [
      {
        name: "options.colorAxis.maxColor",
        displayName: "Color",
        key: "options.colorAxis.maxColor",
        category: PropertyDefinitionCategory.COLORS,
        type: "ColorSelector",
        render: {
          component: "ColorSelector",
          initialValue: "",
          valueMap: {}
        }
      },
      {
        name: "options.plotOptions.series.dataLabels.color",
        displayName: "Label Color",
        key: "options.plotOptions.series.dataLabels.color",
        category: PropertyDefinitionCategory.COLORS,
        type: "ColorSelector",
        render: {
          component: "ColorSelector",
          initialValue: "",
          valueMap: {}
        }
      }
    ],
    {
      excludedKeys: ["options.chart.style.color", "colors"]
    }
  ),
  eventList: getEventList([]),
  styleDefs: ["spacing", "size", "text", "border", "background"],
  availableDesignMode: "both",
  privateVariablePropList: ["onLoadCounter"]
});

export default HeatmapChart;
