import { Skeleton } from "primereact/skeleton";

interface IFormSkeletonProps {
  count?: number;
}

const FormSkeleton = ({ count }: IFormSkeletonProps) => {
  if (!count) {
    return (
      <div className="py-2">
        <Skeleton width="5rem" className="mb-2" />
        <Skeleton height="2rem" className="mb-2" />
      </div>
    );
  }
  return (
    <>
      {Array.from(Array(count)).map((_, index) => (
        <div key={index} className="py-2">
          <Skeleton key={index} width="5rem" className="mb-2" />
          <Skeleton height="2rem" className="mb-2" />
        </div>
      ))}
    </>
  );
};

export default FormSkeleton;
