import { isEqual } from "lodash";
import type { Table } from "../dataModel/types";
import { getSelectAllVisualSQLQuery } from "../dataModel/utils";
import type { IEndSwitchOptionsCases } from "./components/helpers/EndSwitchOptions";
import type { TransformationConfig } from "./components/helpers/transformation/types";
import { NodeTypes } from "./constants";
import type {
  DeleteARecordActionParams,
  GetARecordActionParams,
  GetRecordsActionParams,
  IActionType,
  INode,
  INodeData,
  INodeParameterDefinitions,
  InsertARecordActionParams,
  ReloadComponentDataActionParams,
  SetAppVariablesActionParams,
  SetPageVariablesActionParams,
  SetVariableActionParams,
  SingleVariableValue,
  UpdateARecordActionParams
} from "./types";

export const flowNodeDefaults: {
  [key in IActionType]: INodeData;
} = {
  "webhook:response": {
    params: {
      statusCode: 200,
      body: "",
      header: {
        contentType: "application/json"
      }
    },
    label: "Webhook Response",
    actionType: "webhook:response",
    output: {
      type: "void",
      properties: {}
    },
    variables: []
  },
  http: {
    params: {
      auth: null,
      body: null,
      header: [],
      method: "GET",
      query: [],
      url: "",
      path: []
    },
    label: "HTTP Request",
    actionType: "http",
    output: {
      type: "void",
      properties: {}
    },
    variables: []
  },
  "db:get": {
    params: {
      query: false,
      tableId: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 1,
      offset: 0
    },
    label: "Get a record",
    actionType: "db:get",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  "db:query": {
    params: {
      tableId: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 50,
      offset: 0
    },
    label: "Get all records",
    actionType: "db:query",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  "db:insert": {
    params: {
      table: null,
      record: {}
    },
    label: "Add a record",
    actionType: "db:insert",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  "db:bulkInsert": {
    params: {
      table: null,
      records: []
    },
    label: "Add records",
    actionType: "db:bulkInsert",
    output: {
      type: "array",
      properties: {}
    },
    variables: []
  },
  "db:update": {
    params: {
      query: true,
      valueColumns: [],
      record: {},
      values: [],
      stringValue: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 1,
      offset: 0,
      table: null
    },
    label: "Update a record",
    actionType: "db:update",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  "db:bulkUpdate": {
    params: {
      query: true,
      valueColumns: [],
      records: [],
      values: [],
      stringValue: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 1,
      offset: 0
    },
    label: "Update records",
    actionType: "db:bulkUpdate",
    output: {
      type: "array",
      properties: {}
    },
    variables: []
  },
  "db:delete": {
    params: {
      query: false,
      tableId: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 1,
      offset: 0
    },
    label: "Delete a record",
    actionType: "db:delete",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  "db:bulkDelete": {
    params: {
      query: false,
      tableId: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 1,
      offset: 0
    },
    label: "Delete records",
    actionType: "db:bulkDelete",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  showNotification: {
    params: {
      severity: "info",
      summary: "",
      detail: ""
    },
    label: "Show Notification",
    actionType: "showNotification",
    output: {
      type: "object",
      properties: {
        success: "boolean"
      }
    },
    variables: []
  },
  setVariable: {
    params: {
      variableName: "",
      variableValue: "",
      scope: "app",
      pageId: ""
    },
    label: "Set Variable",
    actionType: "setVariable",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  setAppVariables: {
    params: {
      variables: [
        {
          name: "",
          value: ""
        }
      ]
    },
    label: "Set App Variables",
    actionType: "setAppVariables",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  setPageVariables: {
    params: {
      variables: [
        {
          name: "",
          value: ""
        }
      ]
    },
    label: "Set Page Variables",
    actionType: "setPageVariables",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  setComponentProperty: {
    params: {
      variables: [
        {
          name: "",
          value: "",
          scope: "page"
        }
      ]
    },
    label: "Set Component Property",
    actionType: "setComponentProperty",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  reloadComponentData: {
    params: {
      component: ""
    },
    label: "Reload Component Data",
    actionType: "reloadComponentData",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  log: {
    params: {
      input: ""
    },
    label: "Log",
    actionType: "log",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  navigate: {
    params: {
      path: ""
    },
    label: "Navigate",
    actionType: "navigate",
    output: {
      type: "object",
      properties: {}
    },
    variables: []
  },
  switch: {
    params: {
      cases: ["{{true}}"]
    },
    label: "Switch",
    actionType: "switch",
    output: {
      type: "void",
      properties: {}
    },
    variables: []
  },
  endSwitch: {
    label: "End Switch",
    actionType: "endSwitch",
    params: {
      cases: []
    },
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  loopList: {
    params: {
      forever: false,
      array: "",
      variableName: ""
    },
    label: "Loop List",
    actionType: "loopList",
    output: {
      type: "void",
      properties: {}
    },
    variables: []
  },
  loopForever: {
    params: {
      forever: true
    },
    label: "Loop Forever",
    actionType: "loopForever",
    output: {
      type: "void",
      properties: {}
    },
    variables: []
  },
  fail: {
    params: {
      error: "",
      cause: ""
    },
    label: "Fail",
    actionType: "fail",
    output: {
      type: "none",
      properties: {}
    },
    variables: []
  },
  callable: {
    label: "Callable",
    params: {
      callableInputs: []
    },
    actionType: "callable",
    output: {
      type: "void",
      properties: {}
    },
    variables: []
  },
  webhook: {
    label: "Webhook",
    actionType: "webhook",
    params: {
      method: "GET",
      url: "",
      params: []
    },
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  scheduled: {
    label: "Scheduled",
    actionType: "scheduled",
    params: {
      cronExpression: "",
      timezone: "",
      repeatDuration: ""
    },
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  executeBackendFlow: {
    label: "Reusable Flow",
    actionType: "executeBackendFlow",
    params: {
      flowId: ""
    },
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  success: {
    label: "Success",
    actionType: "success",
    params: {},
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  endLoop: {
    label: "End Loop",
    actionType: "endLoop",
    params: {},
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  break: {
    label: "Break",
    actionType: "break",
    params: {},
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  end: {
    label: "End",
    actionType: "end",
    params: {},
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  customAction: {
    label: "Custom Action",
    actionType: "customAction",
    params: {
      actionId: ""
    },
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  transformation: {
    label: "Set Flow Variables",
    actionType: "transformation",
    params: {},
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  transform: {
    label: "Transformation",
    actionType: "transform",
    params: {
      type: "object",
      input: "",
      variableName: "item",
      template: {},
      extend: true
    },
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  trigger: {
    label: "",
    actionType: "trigger",
    params: {},
    output: {
      type: "void",
      properties: null
    },
    variables: []
  },
  "insider:upsert": {
    label: "Upsert",
    executionType: "be",
    icon: "insider",
    actionType: "insider:upsert",
    params: {
      connection: "",
      cursorColumnName: "",
      tableId: "",
      columns: null,
      filters: null,
      from: [],
      orderBy: null,
      limit: 50,
      offset: 0,
      mapping: {}
    },
    output: { type: "void", properties: {} },
    variables: []
  }
};

export const nodeParameterDefinitions: INodeParameterDefinitions = {
  "db:get": [
    {
      label: "Table / Query",
      type: "TableSelector",
      dataType: "string",
      valKey: "tableId",
      onChange: (
        params: GetARecordActionParams,
        value: Table
      ): GetARecordActionParams => {
        return {
          ...params,
          ...getSelectAllVisualSQLQuery(value)
        };
      }
    },
    {
      label: "",
      dataType: "",
      type: "VisualSQLEditorFlowProperty",
      valKey: ""
    }
  ],
  "db:delete": [
    {
      label: "Table",
      type: "InternalTableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: DeleteARecordActionParams,
        value: Table
      ): DeleteARecordActionParams => {
        return {
          ...params,
          ...value,
          ...getSelectAllVisualSQLQuery(value),
          table: value,
          limit: 1,
          query: true
        };
      }
    },
    {
      label: "",
      type: "VisualSQLEditorFlowProperty",
      typeOptions: {
        editorProps: {
          hidden: ["select", "from", "order by", "limit", "offset"]
        }
      },
      valKey: "",
      dataType: ""
    }
  ],
  "db:bulkDelete": [
    {
      label: "Table",
      type: "InternalTableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: DeleteARecordActionParams,
        value: Table
      ): DeleteARecordActionParams => {
        return {
          ...params,
          ...value,
          ...getSelectAllVisualSQLQuery(value),
          table: value,
          limit: 1,
          query: true
        };
      }
    },
    {
      label: "",
      type: "VisualSQLEditorFlowProperty",
      typeOptions: {
        editorProps: {
          hidden: ["select", "from", "order by", "limit", "offset"]
        }
      },
      valKey: "",
      dataType: ""
    }
  ],
  "db:insert": [
    {
      label: "Table",
      type: "InternalTableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: InsertARecordActionParams,
        value: Table
      ): InsertARecordActionParams => {
        return {
          ...params,
          ...value,
          table: value,
          record: {}
        };
      }
    },
    {
      label: "Record",
      type: "RecordEditor",
      dataType: "object",
      valKey: "record",
      onChange: (
        params: InsertARecordActionParams,
        value: string | { [key: string]: string }
      ) => {
        return {
          ...params,
          record: value
        };
      }
    }
  ],
  "db:bulkInsert": [
    {
      label: "Table",
      type: "InternalTableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: InsertARecordActionParams,
        value: Table
      ): InsertARecordActionParams => {
        return {
          ...params,
          ...value,
          table: value
        };
      }
    },
    {
      label: "Records",
      type: "input",
      dataType: "array",
      valKey: "records" // unused
    }
  ],
  "db:query": [
    {
      label: "Table",
      type: "TableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: GetRecordsActionParams,
        value: Table
      ): GetRecordsActionParams => {
        return {
          ...params,
          ...getSelectAllVisualSQLQuery(value),
          table: value
        };
      }
    },
    {
      label: "",
      type: "VisualSQLEditorFlowProperty",
      valKey: "",
      dataType: ""
    }
  ],
  "db:update": [
    {
      label: "Table",
      type: "InternalTableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: UpdateARecordActionParams,
        value: Table
      ): UpdateARecordActionParams => {
        return {
          ...params,
          ...value,
          ...getSelectAllVisualSQLQuery(value),
          limit: 1,
          table: value,
          record: {}
        };
      }
    },
    {
      label: "",
      dataType: "object",
      type: "VisualSQLEditorFlowProperty",
      typeOptions: {
        className: "mb-3",
        editorProps: {
          hidden: ["select", "from", "order by", "limit", "offset"]
        }
      },
      valKey: ""
    },
    {
      label: "Record",
      type: "RecordEditor",
      dataType: "object",
      valKey: "record",
      onChange: (
        params: InsertARecordActionParams,
        value: string | { [key: string]: string }
      ) => {
        return {
          ...params,
          record: value
        };
      }
    }
  ],
  "db:bulkUpdate": [
    {
      label: "Table",
      type: "InternalTableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: UpdateARecordActionParams,
        value: Table
      ): UpdateARecordActionParams => {
        return {
          ...params,
          ...value,
          ...getSelectAllVisualSQLQuery(value),
          limit: 1,
          table: value
        };
      }
    },
    {
      label: "",
      dataType: "array",
      type: "VisualSQLEditorFlowProperty",
      typeOptions: {
        className: "mb-3",
        editorProps: {
          hidden: ["select", "from", "order by", "limit", "offset"]
        }
      },
      valKey: ""
    },
    {
      label: "Records",
      type: "input",
      dataType: "array",
      valKey: "records" // unused
    }
  ],
  setVariable: [
    {
      label: "Variable Name",
      type: "input",
      valKey: "variableName",
      dataType: "",
      onChange: (
        params: SetVariableActionParams,
        value: string
      ): SetVariableActionParams => {
        return {
          ...params,
          variableName: value
        };
      }
    },
    {
      label: "",
      type: "variableScopeSelector",
      valKey: "scope",
      dataType: "",
      onChange: (
        params: SetVariableActionParams,
        scope: "page" | "app",
        pageId: string
      ) => {
        return {
          ...params,
          scope,
          pageId
        };
      }
    },
    {
      label: "Variable Value",
      type: "input",
      valKey: "variableValue",
      dataType: "",
      onChange: (params: SetVariableActionParams, value: string) => {
        return {
          ...params,
          variableValue: value
        };
      }
    }
  ],
  setAppVariables: [
    {
      valKey: "variables",
      dataType: "array",
      label: "",
      type: "MultipleVariableSelector",
      props: {
        variableScope: "app"
      },
      onChange: (
        params: SetAppVariablesActionParams,
        variables: SingleVariableValue[]
      ): SetAppVariablesActionParams => {
        return {
          ...params,
          variables
        };
      }
    }
  ],
  setPageVariables: [
    {
      valKey: "variables",
      dataType: "array",
      label: "",
      type: "MultipleVariableSelector",
      props: {
        variableScope: "page"
      },
      onChange: (
        params: SetPageVariablesActionParams,
        variables: SingleVariableValue[]
      ): SetPageVariablesActionParams => {
        return {
          ...params,
          variables
        };
      }
    }
  ],
  reloadComponentData: [
    {
      valKey: "component",
      dataType: "string",
      label: "",
      type: "ComponentSelector",
      props: {
        filter: ["C2DataTable", "C2Chart"]
      },
      onChange: (
        params: { component: string },
        value: string
      ): ReloadComponentDataActionParams => {
        return {
          component: value
        };
      }
    }
  ],
  setComponentProperty: [
    {
      valKey: "variables",
      dataType: "array",
      label: "",
      type: "MultipleVariableSelector",
      props: {
        variableScope: "component"
      },
      onChange: (
        params: SetPageVariablesActionParams,
        variables: SingleVariableValue[]
      ): SetPageVariablesActionParams => {
        return {
          ...params,
          variables
        };
      }
    }
  ],
  "webhook:response": [
    {
      label: "Status Code",
      type: "number",
      dataType: "number",
      valKey: "statusCode"
    },
    {
      label: "",
      type: "WebhookResponseBody",
      dataType: "object",
      valKey: "",
      onChange: (params: object, value: object) => {
        return {
          ...params,
          ...value
        };
      }
    }
  ],
  http: [
    {
      label: "",
      dataType: "",
      type: "RestAPIEditorButton",
      valKey: ""
    }
  ],
  log: [
    {
      label: "Value",
      type: "input",
      valKey: "input",
      dataType: "string"
    }
  ],
  navigate: [
    {
      label: "To",
      type: "PageSelector",
      valKey: "path",
      dataType: ""
    }
  ],
  showNotification: [
    {
      label: "Severity",
      type: "select",
      options: [
        {
          label: "Success",
          value: "success"
        },
        {
          label: "Info",
          value: "info"
        },
        {
          label: "Warning",
          value: "warn"
        },
        {
          label: "Error",
          value: "error"
        }
      ],
      valKey: "severity",
      dataType: "string"
    },
    {
      label: "Message",
      type: "input",
      valKey: "summary",
      dataType: "string"
    },
    {
      label: "Detail",
      type: "input",
      valKey: "detail",
      dataType: "string"
    }
  ],
  switch: [
    {
      label: "Cases",
      type: "switchNodeCases",
      valKey: "cases",
      dataType: "array",
      onChange: (
        node: INode<INodeData>,
        newCases: string[],
        newNext: INode<INodeData>["next"]
      ): INode<INodeData> => {
        return {
          ...node,
          data: {
            ...node.data,
            params: {
              ...node.data.params,
              cases: newCases
            }
          },
          next: newNext
        };
      }
    }
  ],
  endSwitch: [
    {
      label: "",
      type: "endSwitch",
      valKey: "cases",
      dataType: "array",
      onChange: (
        node: INode<INodeData>,
        newCases: IEndSwitchOptionsCases[],
        newNext: INode<INodeData>["next"]
      ): { oldNode: INode<INodeData>; newNode: INode<INodeData> }[] => {
        const updatedNodes: {
          oldNode: INode<INodeData>;
          newNode: INode<INodeData>;
        }[] = [];
        newCases.forEach((item) => {
          const isChanged = (itemCase: IEndSwitchOptionsCases) => {
            let res = false;
            if (
              itemCase.node?.next.find((nextId) =>
                nextId?.endsWith("-switchend")
              )
            ) {
              res = true;
            }
            return res;
          };
          const getNext = (itemCase: IEndSwitchOptionsCases) => {
            if (!itemCase.node) return;
            let newNexts = [...itemCase.node.next];
            if (item.bounded && itemCase.node.type === NodeTypes.Switch) {
              newNexts[itemCase.index] = node.id;
            } else if (
              !item.bounded &&
              itemCase.node.type === NodeTypes.Switch
            ) {
              newNexts[itemCase.index] = null;
            } else {
              newNexts = item.bounded
                ? [node.id]
                : isChanged(item)
                ? [null]
                : itemCase.node.next;
            }
            return newNexts;
          };
          if (!item.node) return;
          const nodeChanges = {
            oldNode: item.node,
            newNode: {
              ...item.node,
              next: getNext(item) ?? [null]
            }
          };
          if (!isEqual(nodeChanges.oldNode, nodeChanges.newNode))
            updatedNodes.push(nodeChanges);
        });
        return updatedNodes;

        /*return {
        ...node,
        data: {
          ...node.data,
          params: {
            ...node.data.params,
            cases: newCases
          }
        },
        next: newNext
      };*/
      }
    }
  ],
  loopList: [
    {
      label: "Items",
      type: "array",
      valKey: "array",
      dataType: "array"
    },
    {
      label: "Variable Name",
      type: "stringLiteral",
      valKey: "variableName",
      dataType: "string"
    }
  ],
  loopForever: [],
  callable: [
    {
      dataType: "",
      label: "Inputs",
      type: "callableInput",
      valKey: "callableInputs"
    }
  ],
  webhook: [],
  scheduled: [
    {
      label: "Schedule Options",
      type: "scheduleSettings",
      dataType: "object",
      valKey: ""
    }
  ],
  executeBackendFlow: [
    {
      label: "Flow",
      type: "flowSelector",
      valKey: "flowId",
      dataType: ""
    }
  ],
  fail: [
    {
      label: "Error",
      type: "input",
      valKey: "error",
      dataType: ""
    },
    {
      label: "Error Cause",
      type: "input",
      valKey: "cause",
      dataType: ""
    }
  ],
  success: [],
  endLoop: [],
  break: [],
  end: [
    {
      label: "Output",
      type: "input",
      valKey: "output",
      dataType: ""
    }
  ],
  customAction: [],
  transformation: [],
  trigger: [],
  transform: [
    {
      label: "Properties",
      type: "TransformationEditor",
      dataType: "object",
      valKey: "",
      onChange: (
        params: TransformationConfig,
        value: { [key: string]: string }
      ) => {
        return {
          ...params,
          ...value
        };
      }
    }
  ],
  "insider:upsert": [
    {
      label: "Connection",
      type: "Connection.Insider",
      dataType: "Connection.Insider",
      valKey: "connection"
    },
    {
      label: "Table",
      type: "TableSelector",
      dataType: "object",
      valKey: "table",
      onChange: (
        params: GetRecordsActionParams,
        value: Table
      ): GetRecordsActionParams => {
        return {
          ...params,
          ...getSelectAllVisualSQLQuery(value),
          table: value
        };
      }
    },
    {
      label: "Cursor Column",
      type: "cursorColumnSelector",
      dataType: "string",
      valKey: "cursorColumnName"
    },
    {
      label: "Connection",
      type: "InsiderMapper",
      dataType: "object",
      valKey: "mapping"
    }
  ]
};
