import { CatalogSubType } from "./types";
import type { IConnection } from "../connections/types";
import { ConnectionStage } from "../connections/types";

const customConnections: IConnection[] = [
  {
    authorizationType: "",
    connectionType: CatalogSubType.Segment,
    groupCode: CatalogSubType.Segment,
    credentialSchemaType: "",
    category: "Analytics",
    name: "Segment",
    provider: "",
    custom: true,
    stage: ConnectionStage.Production,
    description: "Optimize your data management process.",
    documentationUrl: "https://docs.peaka.com/integrations/segment/",
    dataCatalogSupported: true
  }
];

export default customConnections;
