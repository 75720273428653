import type { FlowExecutions, IFlow } from "../types";
import { toFlowManifest } from "./toFlowManifest";

export function getFlowExecutions(
  flow: IFlow,
  isDraft?: boolean
): FlowExecutions {
  const converted = toFlowManifest(flow, isDraft);
  const executions: FlowExecutions = {};
  if (converted.feFlow) {
    executions[converted.feFlow.id] = {
      id: converted.feFlow.id,
      manifest: converted.feFlow.manifest,
      type: "fe",
      watchList: converted.feFlow.watchList!
    };
  }
  converted.beFlows.forEach((beFlow) => {
    executions[beFlow.id] = {
      ...beFlow.manifest,
      type: "be"
    };
  });
  return executions;
}
