import { Skeleton } from "primereact/skeleton";

const StudioLayoutSkeleton = () => {
  return (
    <>
      <Skeleton height="3rem" className="border-noround mb-2" />
      <Skeleton height="calc(100% - 7rem)" className="border-noround" />
      <Skeleton height="3rem" className="border-noround mt-2" />
    </>
  );
};

export default StudioLayoutSkeleton;
