import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";
import { iconOptions } from "./utils";

const ToggleButton = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonToggleButtonDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 1,
      h: 3
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonToggleButtonDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-toggle-button-component",
  category: "forms",
  disabled: false,
  base: {
    package: "primereact",
    type: "ToggleButton",
    key: "ToggleButton",
    events: {},
    props: {
      offLabel: "No",
      onLabel: "Yes",
      className: {}
    },
    propMask: {},
    name: "Toggle Button",
    children: [],
    uiBlock: ["ToggleButton", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "checked",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "onLabel",
      displayName: "On Label",
      key: "onLabel",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "On",
        valueMap: {}
      }
    },
    {
      name: "onIcon",
      displayName: "On Icon",
      key: "onIcon",
      type: "select",
      render: {
        component: "dropdown",
        options: iconOptions,
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "offLabel",
      displayName: "Off Label",
      key: "offLabel",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "Off",
        valueMap: {}
      }
    },
    {
      name: "offIcon",
      displayName: "Off Icon",
      key: "offIcon",
      type: "select",
      render: {
        component: "dropdown",
        options: iconOptions,
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "iconPos",
      displayName: "Show Icon At Right",
      key: "iconPos",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: "right",
        valueMap: {},
        trueValue: "right",
        falseValue: "left"
      },
      validateResultAsType: "string"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    },
    {
      name: "onFocus",
      readableName: "On Focus",
      description: "Fires when component receives focus."
    },
    {
      name: "onBlur",
      readableName: "On Blur",
      description: "Fires when component loses focus."
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default ToggleButton;
