import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";

const SettingSkeleton = () => {
  return (
    <div
      style={{ padding: "1.75rem" }}
      className={classNames("m-6 border-round-3xl h-full ml-6")}
    >
      <Skeleton className="w-full max-w-10rem h-2rem mb-4" />
      <div className="bg-white border-round-3xl p-4">
        <Skeleton className="w-full max-w-12rem h-1rem mb-4" />
        <Skeleton className="h-2rem w-full max-w-20rem mb-2" />
        <Skeleton className="h-2rem w-full max-w-10rem mb-5" />

        <Skeleton className="w-full max-w-12rem h-2rem mb-2" />
        <Skeleton className="w-full max-w-22rem h-1rem mb-3" />
        <Skeleton className="h-2rem w-full max-w-8rem mb-5" />
      </div>
    </div>
  );
};

export default SettingSkeleton;
