import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const SplitButton = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonSplitButtonDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 3
    };
    commonDef.styleDefs = ["effects"];
  } else {
    commonDef.base.props.className = {
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonSplitButtonDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-split-buttons-component",
  category: "buttons",
  disabled: false,
  base: {
    package: "primereact",
    type: "SplitButton",
    key: "SplitButton",
    events: {},
    props: {
      label: "New Button",
      icon: "pi pi-plus",
      model: [
        {
          label: "Update",
          icon: "pi pi-refresh"
        },
        {
          label: "Delete",
          icon: "pi pi-times"
        }
      ],
      className: {}
    },
    propMask: {},
    name: "Split Button",
    children: []
  },
  propDefs: [],
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when button is clicked"
    }
  ],
  styleDefs: ["spacing", "size", "effects"],
  availableDesignMode: "both"
});

export default SplitButton;
