import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Button = (designMode: DesignMode): ComponentDefinition =>
  getButtonDefs(designMode);

const getCommonButtonDefs = (): ComponentDefinition => ({
  icon: "fa-kit fa-button-component",
  category: "mostUsed",
  disabled: false,
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when button is clicked."
    },
    {
      name: "onMouseEnter",
      readableName: "On Mouse Enter",
      description: "Fires when button is hovered."
    },
    {
      name: "onMouseLeave",
      readableName: "On Mouse Leave",
      description: "Fires when button is unhovered."
    }
  ],
  base: {
    name: "Button",
    package: "primereact",
    type: "Button",
    key: "Button",
    events: {},
    children: [],
    props: {},
    propMask: {
      label: "New Button",
      className: {
        "p-button-primary": "primary",
        "": "normal",
        "p-button-rounded": false,
        "p-button-outlined": false,
        "p-button-text": false,
        "p-button-raised": false
      }
    },
    uiBlock: ["Button", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "New Button",
        valueMap: {}
      }
    },
    {
      name: "severity",
      displayName: "Severity",
      key: "className",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          {
            label: "Primary",
            value: "primary"
          },
          {
            label: "Secondary",
            value: "secondary"
          },
          {
            label: "Success",
            value: "success"
          },
          {
            label: "Info",
            value: "info"
          },
          {
            label: "Warning",
            value: "warning"
          },
          {
            label: "Help",
            value: "help"
          },
          {
            label: "Danger",
            value: "danger"
          }
        ],
        initialValue: "primary",
        valueMap: {
          primary: "p-button-primary",
          secondary: "p-button-secondary",
          success: "p-button-success",
          info: "p-button-info",
          warning: "p-button-warning",
          help: "p-button-help",
          danger: "p-button-danger"
        }
      }
    },
    {
      name: "size",
      displayName: "Size",
      key: "className",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          {
            label: "Small",
            value: "small"
          },
          {
            label: "Normal",
            value: "normal"
          },
          {
            label: "Large",
            value: "large"
          }
        ],
        initialValue: "normal",
        valueMap: {
          small: "p-button-sm",
          normal: "",
          large: "p-button-lg"
        }
      }
    },
    {
      name: "raised",
      displayName: "Raised",
      key: "className",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {
          true: "p-button-raised"
        }
      },
      validateResultAsType: "boolean"
    },
    {
      name: "rounded",
      displayName: "Rounded",
      key: "className",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {
          true: "p-button-rounded"
        }
      },
      validateResultAsType: "boolean"
    },
    {
      name: "text",
      displayName: "Text Button",
      key: "className",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {
          true: "p-button-text"
        }
      },
      validateResultAsType: "boolean"
    },
    {
      name: "outlined",
      displayName: "Outlined",
      key: "className",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {
          true: "p-button-outlined"
        }
      },
      validateResultAsType: "boolean"
    }
  ],
  styleDefs: [],
  availableDesignMode: "both"
});

const getButtonDefs = (designMode: DesignMode): ComponentDefinition => {
  const buttonDef: ComponentDefinition = getCommonButtonDefs();
  if (designMode === "basic") {
    buttonDef.styleDefs = ["typography", "background", "effects", "border"];
    buttonDef.basicDesignInitialSize = {
      w: 2,
      h: 2
    };
    buttonDef.propDefs = buttonDef.propDefs.filter(
      (propDef) => propDef.name !== "size"
    );
  } else {
    buttonDef.styleDefs = [
      "spacing",
      "size",
      "typography",
      "background",
      "effects",
      "border"
    ];
    buttonDef.base.props = {
      className: {
        "mt-1": true,
        "mb-1": true
      }
    };
  }
  return buttonDef;
};

export default Button;
