import type {
  Child,
  Component,
  ComponentPropertyType,
  Events,
  Package,
  Props
} from "@code2io/fe-engine/dist/types";
import type { VisualSQLQuery } from "../features/dataModel/types";
import type { GenericFunction } from "../types";
import type { ActionColumnValue } from "@code2io/fe-engine/dist/Engine/modules/code2/customComponents/C2DataTable/types";

export type ComponentProps = PropertyDefinition[];

export type PropertyDefinition =
  | SelectPropertyDefinition
  | SwitchPropertyDefinition
  | InputPropertyDefinition
  | ExprPropertyDefinition
  | InternalTableSelectorPropertyDefinition
  | TableSelectorPropertyDefinition
  | NonePropertyDefinition
  | TableColumnMappingPropertyDefinition
  | ChartColumnMappingPropertyDefinition
  | VisualSQLEditorPropertyDefinition
  | SelectButtonPropertyDefinition
  | YAxisArrayPropertyDefinition
  | PageSelectorPropertyDefinition
  | LinkPropertyDefinition
  | ColorPalettePropertyDefinition
  | ColorSelectorPropertyDefinition
  | DataSourceSelectorPropertyDefinition
  | ActionButtonPropertyDefinition;

export enum PropertyDefinitionCategory {
  BASIC_SETTINGS = "Basic Settings",
  DATA = "Data",
  COLORS = "Colors",
  OTHER_SETTINGS = "Other Settings"
}

interface ActionButtonPropertyDefinition extends PropertyDefinitionBase {
  type: "ActionButton";
  render: {
    component: "ActionButton";
    initialValue: ActionColumnValue;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface PropertyDefinitionBase {
  name: string;
  key: string;
  displayName: string;
  category?: PropertyDefinitionCategory;
  validateResultAsType?: ComponentPropertyType | ComponentPropertyType[];
  isPropObj?: boolean;
  onChange?: GenericFunction;
}

export type ComponentEvents = EventDefinition[];

export interface EventDefinition {
  name: string;
  readableName: string;
  description: string;
}

interface SelectPropertyDefinition extends PropertyDefinitionBase {
  type: "select";
  render: {
    component: "dropdown";
    options: {
      label: string;
      value: string;
    }[];
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface SelectButtonPropertyDefinition extends PropertyDefinitionBase {
  type: "SelectButton";
  render: {
    component: "SelectButton";
    options: {
      label: string;
      value: unknown;
    }[];
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface SwitchPropertyDefinition extends PropertyDefinitionBase {
  type: "switch";
  render: {
    component: "inputswitch";
    initialValue: unknown;
    valueMap: {
      [key: string]: unknown;
    };
    trueValue?: unknown;
    falseValue?: unknown;
  };
}

interface InputPropertyDefinition extends PropertyDefinitionBase {
  type: "input";
  render:
    | {
        component: "inputtext";
        initialValue: string | null;
        valueMap: {
          [key: string]: unknown;
        };
      }
    | {
        component: "inputnumber";
        initialValue: number;
        valueMap: {
          [key: string]: unknown;
        };
      };
}

interface ExprPropertyDefinition extends PropertyDefinitionBase {
  type: "expr";
  render: {
    component: "expressioneditor";
    initialValue: string | null;
    skipValidityCheck?: boolean;
    valueMap: {
      [key: string]: unknown;
    };
    readonly?: boolean;
  };
}

interface InternalTableSelectorPropertyDefinition
  extends PropertyDefinitionBase {
  type: "InternalTableSelector";
  render: {
    component: "InternalTableSelector";
    initialValue: null;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface TableSelectorPropertyDefinition extends PropertyDefinitionBase {
  type: "TableSelector";
  render: {
    component: "TableSelector";
    initialValue: null;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface NonePropertyDefinition extends PropertyDefinitionBase {
  type: "none";
  render: {
    component: "none";
    initialValue: string | boolean;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface TableColumnMappingPropertyDefinition extends PropertyDefinitionBase {
  type: "TableColumnMapping";
  render: {
    component: "TableColumnMapping";
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface ChartColumnMappingPropertyDefinition extends PropertyDefinitionBase {
  type: "ChartColumnMapping";
  render: {
    component: "ChartColumnMapping";
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface VisualSQLEditorPropertyDefinition extends PropertyDefinitionBase {
  type: "VisualSQLEditorFlowProperty";
  render: {
    component: "VisualSQLEditorFlowProperty";
    initialValue: null | VisualSQLQuery;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface YAxisArrayPropertyDefinition extends PropertyDefinitionBase {
  type: "YAxisArrayProperty";
  render: {
    component: "YAxisArrayProperty";
    initialValue: [];
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface LinkPropertyDefinition extends PropertyDefinitionBase {
  type: "LinkPropertyEditor";
  render: {
    component: "LinkPropertyEditor";
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface ColorPalettePropertyDefinition extends PropertyDefinitionBase {
  type: "ColorPaletteSelector";
  render: {
    component: "ColorPaletteSelector";
    initialValue: string[];
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface ColorSelectorPropertyDefinition extends PropertyDefinitionBase {
  type: "ColorSelector";
  render: {
    component: "ColorSelector";
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface PageSelectorPropertyDefinition extends PropertyDefinitionBase {
  type: "PageSelector";
  render: {
    component: "PageSelector";
    initialValue: string;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

interface DataSourceSelectorPropertyDefinition extends PropertyDefinitionBase {
  type: "DataSourceSelector";
  render: {
    component: "DataSourceSelector";
    initialValue: null;
    valueMap: {
      [key: string]: unknown;
    };
  };
}

export interface ComponentList {
  [key: string]: ComponentDefinition;
}

export interface BlockList {
  [key: string]: (parentId: string) => BlockDefinition;
}

interface DataModelDefinition {
  [key: string]: unknown;
}

export interface ComponentDefinition {
  icon: string;
  category: string;
  disabled: boolean;
  base: ComponentRenderBase;
  propDefs: ComponentProps;
  eventList: ComponentEvents;
  styleDefs: string[];
  hidden?: boolean;
  dataModelDefs?: DataModelDefinition[];
  basicDesignInitialSize?: {
    w: number;
    h: number;
  };
  availableDesignMode: "basic" | "advanced" | "both";
  privateVariablePropList?: string[];
}

export interface BlockDefinition {
  icon: string;
  key: string;
  category: string;
  disabled: boolean;
  components: {
    [id: string]: Component;
  };
  hidden?: boolean;
  name: string;
  type: string;
  availableDesignMode: "basic" | "advanced" | "both";
}

export interface ComponentRenderBase {
  package: Package;
  type: string;
  events: Events;
  props: Props;
  propMask?: Props;
  name: string;
  children: Child[];
  uiBlock?: string[];
  key: string;
}
