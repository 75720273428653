import { TieredMenu } from "primereact/tieredmenu";
import { classNames } from "primereact/utils";
import { memo, useMemo, useRef, useState } from "react";
import type { EdgeProps } from "reactflow";
import { EdgeText } from "reactflow";
import { ConstantNodeIds } from "../../constants";
import type { IAdderEdgeData } from "../../types";
import { getCenterPoint, getPath } from "./svgPathUtils";

const ForeignObjectSize = {
  Small: 12,
  Large: 30
} as const;

const ActionAdderEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  style = {},
  data = {
    menuItems: [],
    hoverable: true,
    points: []
  }
}: EdgeProps<IAdderEdgeData>) => {
  const { points, menuItems, hoverable, label } = data;

  const sourceOffset = {
    x: points[0].x - sourceX,
    y: points[0].y - sourceY
  };
  const targetOffset = {
    x: points[points.length - 1].x - targetX,
    y: points[points.length - 1].y - targetY
  };

  const mappedPoints = points.flatMap((p, i) => {
    if (i === 0) {
      return [
        {
          x: p.x - sourceOffset.x,
          y: p.y - sourceOffset.y
        },
        {
          x: points[1].x,
          y: p.y - sourceOffset.y
        }
      ];
    }
    if (i === points.length - 1) {
      return [
        {
          x: points[points.length - 2].x,
          y: p.y - targetOffset.y
        },
        {
          x: p.x - targetOffset.x,
          y: p.y - targetOffset.y
        }
      ];
    }
    return [p];
  });
  const { x: edgeCenterX, y: edgeCenterY } = getCenterPoint(points);

  const [hovering, setHovering] = useState(false);
  const foreignObjectSize = useMemo(() => {
    if (hovering) return ForeignObjectSize.Large;
    else return ForeignObjectSize.Small;
  }, [hovering]);

  const menu = useRef<TieredMenu>(null);

  const lastMappedPoint = mappedPoints[mappedPoints.length - 1];
  const targetLabelPosition =
    target === ConstantNodeIds.EndNode
      ? {
          x: edgeCenterX + ForeignObjectSize.Small * 4,
          y: edgeCenterY
        }
      : {
          x: lastMappedPoint.x - 25,
          y: lastMappedPoint.y
        };

  const cutEdgeShort =
    typeof label !== "undefined" && target !== ConstantNodeIds.EndNode;

  const edgeTarget = cutEdgeShort
    ? {
        x: targetLabelPosition.x,
        y: targetLabelPosition.y
      }
    : lastMappedPoint;

  const filteredPoints = mappedPoints.filter((p) => {
    return p.x <= edgeTarget.x;
  });
  if (target !== ConstantNodeIds.EndNode) {
    filteredPoints.push({
      x: targetLabelPosition.x - 25,
      y: targetLabelPosition.y
    });
  }
  const dagrePath = getPath(filteredPoints);

  return (
    <>
      {typeof label !== "undefined" && (
        <EdgeText
          className="pointer-events-none cursor-auto"
          x={targetLabelPosition.x}
          y={targetLabelPosition.y}
          label={label}
          labelStyle={{
            fill: "var(--indigo-500)",
            fontSize: "10px",
            lineHeight: "10px"
          }}
          labelShowBg={true}
          labelBgPadding={[6, 2]}
          labelBgStyle={{ fill: "var(--indigo-100)", lineHeight: "10px" }}
          labelBgBorderRadius={12}
        />
      )}
      <g
        onMouseEnter={() => setHovering(hoverable)}
        onMouseLeave={() => setHovering(false)}
      >
        <path
          id={id}
          style={style}
          strokeDasharray={8}
          className="react-flow__edge-path"
          d={dagrePath}
        />
        {hoverable && (
          <foreignObject
            width={foreignObjectSize}
            height={foreignObjectSize}
            x={edgeCenterX - foreignObjectSize / 2}
            y={edgeCenterY - foreignObjectSize / 2}
            className="border-circle"
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            <div className="w-full h-full justify-content-center align-items-center">
              <TieredMenu
                model={menuItems}
                popup
                ref={menu}
                id="overlay_tmenu_edge"
              />
              <button
                className="w-full h-full border-circle p-0 border-1 border-primary flex align-items-center justify-content-center cursor-pointer text-primary cursor-pointer"
                onClick={(event) => {
                  menu.current?.toggle(event);
                }}
              >
                <i
                  className={classNames("c2-icons-add", {
                    "text-xl": hovering,
                    "text-xs": !hovering
                  })}
                />
              </button>
            </div>
          </foreignObject>
        )}
      </g>
    </>
  );
};

export default memo(ActionAdderEdge);
