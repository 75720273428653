import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Skeleton = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonSkeletonDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 3
    };
    commonDef.styleDefs = ["background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "w-12": true,
      "h-15rem": true,
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonSkeletonDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-skeleton-component",
  disabled: false,
  category: "misc",
  base: {
    package: "primereact",
    type: "Skeleton",
    key: "Skeleton",
    events: {},
    props: {
      animation: "wave",
      className: {},
      shape: "rectangle"
    },
    propMask: {},
    name: "Skeleton",
    children: [],
    uiBlock: ["DefaultComponent"]
  },
  propDefs: [
    {
      name: "animation",
      displayName: "Animation",
      key: "animation",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: "wave",
        trueValue: "wave",
        falseValue: "none",
        valueMap: {}
      },
      validateResultAsType: "string"
    }
  ],
  eventList: [],
  styleDefs: ["spacing", "size", "background", "effects", "border"],
  availableDesignMode: "both"
});

export default Skeleton;
