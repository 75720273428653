import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { Table } from "./types";
import type { RootState } from "../../app/store";

interface AddDataRestTableAction {
  table: Table | undefined;
}

const initialState: {
  dataRestTable: Table | undefined;
} = { dataRestTable: undefined };

export const dataModelSlice = createSlice({
  name: "dataModel",
  initialState,
  reducers: {
    setDataRestTable: (
      state,
      action: PayloadAction<AddDataRestTableAction>
    ) => {
      state.dataRestTable = action.payload.table;
    },
    reset: (state) => {
      state.dataRestTable = undefined;
    }
  }
});

export const { reset, setDataRestTable } = dataModelSlice.actions;
export const selectDataRestTable = (state: RootState) =>
  state.dataModel.dataRestTable;

export default dataModelSlice.reducer;
