import { Skeleton } from "primereact/skeleton";

const DataModelTableListSkeleton = () => {
  return (
    <>
      <Skeleton
        height="100%"
        width="calc(22.5rem - 1px)"
        className="border-noround border-right-1 border-800"
      />
      <Skeleton
        height="100%"
        width="calc(100% - 20.85rem - 1px)"
        className="border-noround"
      />
    </>
  );
};

export default DataModelTableListSkeleton;
