import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { getDynamicConfigValue } from "../utils/dynamicConfig";
import { EnvironmentConstants } from "../../environmentConstants";

const c2BaseQuery = fetchBaseQuery({
  baseUrl: getDynamicConfigValue(EnvironmentConstants.STUDIO_API_URL)
});

const c2BaseQueryWithRetry = retry(c2BaseQuery, { maxRetries: 3 });

const oneDriveBaseQuery = fetchBaseQuery({
  baseUrl: "https://graph.microsoft.com/v1.0"
});

const oneDriveBaseQueryWithRetry = retry(oneDriveBaseQuery, { maxRetries: 3 });

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const tagTypes = [
  "ApiKeys",
  "Catalog",
  "Table",
  "Schema",
  "Column",
  "Row",
  "Application",
  "Applications",
  "Auth",
  "Share",
  "MyInvitations",
  "AllConnections",
  "MyConnections",
  "ConnectionView",
  "AuthRefresh",
  "Subscription",
  "CacheStatus",
  "CacheSettings",
  "CDCCacheConfigs",
  "CDCCacheStatuses",
  "UsageReport",
  "EmailRequest",
  "FlowTriggerStatus",
  "Templates",
  "TableCache",
  "SampleData",
  "RequesDeveloperAccessAndPartnerAPIKey",
  "RequesDeveloperAccessAndPartnerAPIKeyStatus",
  "SharedQueryList",
  "Queries",
  "StripeAccountLink",
  "ParametricTables",
  "SemanticsQuery",
  "SharedCatalog",
  "AppCache"
] as const;

export type TagType = (typeof tagTypes)[number];

export const api = createApi({
  baseQuery: customFetchBase,
  tagTypes,
  endpoints: () => ({})
});

export const historyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: getDynamicConfigValue(EnvironmentConstants.FLOW_HISTORY_BASE_URL)
  }),
  reducerPath: "historyApi",
  tagTypes,
  endpoints: () => ({})
});

export const c2Api = createApi({
  baseQuery: c2BaseQueryWithRetry,
  tagTypes: ["Application"],
  endpoints: () => ({})
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => "test"
  })
});

export const oneDriveApi = createApi({
  baseQuery: oneDriveBaseQueryWithRetry,
  reducerPath: "oneDriveApi",
  tagTypes: [],
  endpoints: () => ({})
});
