import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../../types";
import { getEventList, getPropDefs, getProps } from "./utils";

const BarChart = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonBarChartDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 6,
      h: 22
    };
    commonDef.styleDefs = ["text", "border", "background"];
  } else {
    commonDef.base.props.className = {
      relative: true,
      "h-30rem": true,
      "w-12": true,
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonBarChartDef = (): ComponentDefinition => ({
  icon: "fa-kit-duotone fa-bar-chart-component---horizontal",
  category: "charts",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Chart",
    key: "BarChart",
    events: {},
    props: {
      className: {},
      chartType: "BarChart"
    },
    propMask: getProps({
      options: {
        plotOptions: {
          bar: {
            stacking: false
          }
        },
        title: { text: "Bar Chart" }
      }
    }),
    name: "Bar Chart",
    children: [],
    uiBlock: ["Chart", "DefaultComponent"]
  },
  propDefs: getPropDefs(
    [
      {
        name: "options.plotOptions.bar.stacking",
        displayName: "Stacking",
        key: "options.plotOptions.bar.stacking",
        type: "switch",
        render: {
          component: "inputswitch",
          initialValue: false,
          valueMap: {}
        },
        validateResultAsType: "boolean"
      }
    ],
    {
      excludedKeys: ["options.plotOptions.series.marker.enable"]
    }
  ),
  eventList: getEventList([]),
  styleDefs: ["spacing", "size", "text", "border", "background"],
  basicDesignInitialSize: {
    w: 6,
    h: 16
  },
  availableDesignMode: "both",
  privateVariablePropList: ["onLoadCounter"]
});

export default BarChart;
