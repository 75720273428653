import PeakaLogo from "../../app/assets/svg/peaka-logo-color-with-text.svg";
import AccessDeniedImage from "../../app/assets/images/403.svg";

const AccessDenied = () => {
  return (
    <div className="w-screen h-screen surface-900">
      <div className="px-5 py-4">
        <img src={PeakaLogo} alt="peaka-logo" />
      </div>

      <div className="w-full flex align-items-center justify-content-center flex-column mt-6">
        <img
          className="w-full max-w-30rem"
          src={AccessDeniedImage}
          alt="not-found-image"
          style={{
            padding: "74px 46px"
          }}
        />
      </div>
      <div>
        <h1
          style={{
            fontFamily: "Inter",
            fontSize: "40px",
            fontWeight: "600",
            lineHeight: "44px",
            letterSpacing: "-0.04em",
            textAlign: "center",
            color: "#ffffff"
          }}
        >
          Access Denied
        </h1>
        <p
          style={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "-0.015em",
            textAlign: "center",
            color: "#ffffff",
            paddingTop: "12px"
          }}
        >
          We’re sorry, but you do not have permission to access this page with
          the credentials you have.
        </p>
      </div>
    </div>
  );
};

export default AccessDenied;
