import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const RadioButtonGroup = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonRadioButtonGroupDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 6
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonRadioButtonGroupDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-radio-group-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2RadioButtonGroup",
    key: "RadioButtonGroup",
    events: {},
    props: {
      className: {}
    },
    propMask: {
      options: [
        { label: "Option 1", value: "Option 1" },
        { label: "Option 2", value: "Option 2" },
        { label: "Option 3", value: "Option 3" }
      ],
      optionLabel: "label",
      optionValue: "value",
      id: "radio-group-1"
    },
    name: "Radio Group",
    children: [],
    uiBlock: ["RadioGroup", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options",
      displayName: "Options",
      key: "options",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: `{{ [{ label: "Option 1", value: "Option 1" }, { label: "Option 2", value: "Option 2" }, { label: "Option 3", value: "Option 3" }] }}`,
        valueMap: {}
      },
      validateResultAsType: "array"
    },
    {
      name: "optionLabel",
      displayName: "Option Label",
      key: "optionLabel",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "label",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "optionValue",
      displayName: "Option Value",
      key: "optionValue",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "value",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default RadioButtonGroup;
