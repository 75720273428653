import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import type { Component, EngineMode } from "@code2io/fe-engine/dist/types";

export interface DesignerState {
  mode: EngineMode;
  selectedElementIds: string[];
  currentPageId: string;
}

const initialState: DesignerState = {
  mode: "design",
  selectedElementIds: [],
  currentPageId: ""
};

export interface AddComponentPayload {
  meta: Component;
  index: number;
}

export interface AddComponentBatchPayload {
  items: {
    meta: Component;
    index: number;
  }[];
  existingItemId: string;
}

export type MoveComponentPayload = AddComponentPayload & {
  oldParentId: string;
};

export interface MoveComponentBatchPayload {
  items: AddComponentPayload[];
  oldParentId: string;
  existingItemIds: string[];
}

export interface DeleteComponentPayload {
  meta: Component;
}

export interface CloneComponentPayload {
  meta: Component;
}

export interface EditComponentPayload {
  id: string;
  key: string;
  value: unknown;
  valueMap: {
    [key: string]: unknown;
  };
  isPropObject?: boolean;
}

export interface PartialMetadataUpdatePayload {
  path: (string | number)[];
  value: unknown;
}

export interface ComponentTreeNode {
  component: Component;
  key: string;
  label: string;
  icon: string;
  children: ComponentTreeNode[];
  parentIndex: number;
}

export const designerSlice = createSlice({
  name: "designer",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<EngineMode>) => {
      state.mode = action.payload;
    },
    setSelectedElement: (state, action: PayloadAction<string[]>) => {
      state.selectedElementIds = action.payload;
    },
    addSelectedElement: (state, action: PayloadAction<string>) => {
      state.selectedElementIds.push(action.payload);
    },
    removeSelectedElement: (state, action: PayloadAction<string>) => {
      state.selectedElementIds = state.selectedElementIds.filter(
        (id) => id !== action.payload
      );
    },
    setCurrentPageId: (state, action: PayloadAction<string>) => {
      state.currentPageId = action.payload;
    },
    resetDesignerState: (state) => {
      state.mode = "design";
      state.selectedElementIds = [];
      state.currentPageId = "";
    }
  }
});

export const {
  setMode,
  setSelectedElement,
  setCurrentPageId,
  resetDesignerState,
  addSelectedElement,
  removeSelectedElement
} = designerSlice.actions;

export const selectCurrentPageId = (state: RootState) =>
  state.designer.currentPageId;

export const selectMode = (state: RootState) => state.designer.mode;
export const selectSelectedElementIds = (state: RootState) =>
  state.designer.selectedElementIds;

export default designerSlice.reducer;
