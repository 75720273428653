import type { XYPosition } from "reactflow";

const distance = (a: XYPosition, b: XYPosition) =>
  Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));

function getBend(
  a: XYPosition,
  b: XYPosition,
  c: XYPosition,
  size: number
): string {
  const bendSize = Math.min(distance(a, b) / 2, distance(b, c) / 2, size);
  const { x, y } = b;

  // no bend
  if ((a.x === x && x === c.x) || (a.y === y && y === c.y)) {
    return `L${x} ${y}`;
  }

  // first segment is horizontal
  if (a.y === y) {
    const xDir = a.x < c.x ? -1 : 1;
    const yDir = a.y < c.y ? 1 : -1;
    return `L ${x + bendSize * xDir},${y}Q ${x},${y} ${x},${
      y + bendSize * yDir
    }`;
  }

  const xDir = a.x < c.x ? 1 : -1;
  const yDir = a.y < c.y ? -1 : 1;
  return `L ${x},${y + bendSize * yDir}Q ${x},${y} ${x + bendSize * xDir},${y}`;
}

function getPath(points: XYPosition[]): string {
  return points.reduce<string>((res, p, i) => {
    let segment = "";

    if (i > 0 && i < points.length - 1) {
      segment = getBend(points[i - 1], p, points[i + 1], 12);
    } else {
      segment = `${i === 0 ? "M" : "L"}${p.x} ${p.y}`;
    }

    res += segment;

    return res;
  }, "");
}

function getCenterPoint(points: XYPosition[]): XYPosition {
  if (points.length === 0) return { x: 0, y: 0 };
  if (points.length === 1) return points[0];

  if (points.length % 2 === 1) return points[Math.floor(points.length / 2)];

  return {
    x:
      (points[Math.floor(points.length / 2)].x +
        points[Math.ceil(points.length / 2)].x) /
      2,
    y:
      (points[Math.floor(points.length / 2)].y +
        points[Math.ceil(points.length / 2)].y) /
      2
  };
}

export { getBend, getPath, getCenterPoint };
