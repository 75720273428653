import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../../types";
import { getEventList, getPropDefs, getProps } from "./utils";

const sampleDataColumns = [
  { field: "title", header: "Title", show: true, type: "VARCHAR" },
  { field: "avg", header: "Average", show: true, type: "BIGINT" }
];

const sampleDataItems = [
  { title: "Net Profit", avg: 44 },
  { title: "Revenue", avg: 85 },
  { title: "Free Cash Flow", avg: 36 }
];

const PieChart = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonPieChartDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 6,
      h: 22
    };
    commonDef.styleDefs = ["text", "border", "background"];
  } else {
    commonDef.base.props.className = {
      relative: true,
      "h-30rem": true,
      "w-12": true,
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonPieChartDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-pie-chart-component",
  category: "charts",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Chart",
    key: "PieChart",
    events: {},
    props: {
      className: {},
      chartType: "PieChart"
    },
    propMask: {
      ...getProps({
        options: {
          plotOptions: {
            pie: {
              showInLegend: true
            }
          },
          title: { text: "Pie Chart" }
        }
      }),
      dataColumns: sampleDataColumns,
      sampleValue: sampleDataItems
    },
    name: "Pie Chart",
    children: [],
    uiBlock: ["Chart", "DefaultComponent"]
  },
  propDefs: getPropDefs([], {
    excludedKeys: ["options.yAxis.title.text", "colors"]
  }),
  eventList: getEventList([]),
  styleDefs: ["spacing", "size", "text", "border", "background"],
  availableDesignMode: "both",
  privateVariablePropList: ["onLoadCounter"]
});

export default PieChart;
