import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getDynamicConfigValue } from "../utils/dynamicConfig";
import { EnvironmentConstants } from "../../environmentConstants";

export const contactFetchBase = fetchBaseQuery({
  baseUrl: getDynamicConfigValue(EnvironmentConstants.CONTACT_US_API_URL)
});

export const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: contactFetchBase,
  endpoints: (builder) => ({
    contactUs: builder.mutation<unknown, sendContactInformationsQueryArg>({
      query: (arg) => ({
        url: "/submit-contact",
        method: "POST",
        body: arg
      })
    })
  })
});

export const { useContactUsMutation } = contactApi;

export interface sendContactInformationsQueryArg {
  first_name: string;
  last_name: string;
  company_name: string;
  company_email: string;
  job_title: string;
  phone: string;
  country: string;
  employee_range: string;
  form_type: string;
}
