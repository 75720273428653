import { createSlice } from "@reduxjs/toolkit";

export type SubscriptionState = unknown;

const initialState: SubscriptionState = {};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {}
});

export default subscriptionSlice.reducer;
