import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const InputSwitch = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonInputSwitchDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 3
    };
    commonDef.styleDefs = ["effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true
    };
  }
  return commonDef;
};

const getCommonInputSwitchDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-switch-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "InputSwitch",
    events: {},
    propMask: {},
    props: {
      c2FieldType: "InputSwitch",
      className: {}
    },
    name: "Input Switch",
    children: [],
    uiBlock: ["InputSwitch", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "value",
      displayName: "Value",
      key: "checked",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: ["spacing", "size", "effects"],
  availableDesignMode: "both"
});

export default InputSwitch;
