import equal from "fast-deep-equal";

/**
 * Compares two values for deep equality.
 * @param a The first value to compare.
 * @param b The second value to compare.
 * @returns `true` if the values are equal, otherwise `false`.
 */
export function isEqual(a: unknown, b: unknown) {
  return equal(a, b);
}
