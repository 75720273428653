import type { CSSProperties } from "react";
import { forwardRef } from "react";
import { classNames } from "primereact/utils";
import C2Icon from "@code2io/fe-c2-icons";
import { getIconSVG } from "./utils";
import type { IconProp } from "./types";
import styles from "./Icon.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IconProps {
  className?: string;
  icon: IconProp;
  spin?: boolean;
  style?: CSSProperties;
}

// eslint-disable-next-line react/display-name
const Icon = forwardRef<HTMLElement, IconProps>(
  ({ className, icon, spin, style }, ref) => {
    const sharedClassName = classNames(
      styles.icon,
      { [styles.spin]: spin },
      className
    );

    if (icon instanceof Array) {
      if (icon[0] === "ci") {
        return (
          <C2Icon
            icon={icon[1]}
            className={classNames(sharedClassName, `pi`)}
            style={style}
            ref={ref}
          />
        );
      } else if (icon[0] === "pi") {
        return (
          <i
            className={classNames(
              sharedClassName,
              `pi pi-${icon[1] as string}`
            )}
            ref={ref}
            style={style}
          />
        );
      } else if (icon[0] === "fa") {
        return (
          <div className="h-2rem flex align-items-center justify-content-center">
            <FontAwesomeIcon
              size="xs"
              className={classNames(sharedClassName)}
              icon={icon[1]}
            />
          </div>
        );
      } else if (icon[0] === "fa-nowrapper") {
        return (
          <FontAwesomeIcon
            className={classNames(sharedClassName)}
            icon={icon[1]}
          />
        );
      } else {
        const IconSVG = getIconSVG(icon[1]) ?? getIconSVG("code-2-logo");
        return (
          <IconSVG
            className={sharedClassName}
            height="1rem"
            width="1rem"
            style={style}
          />
        );
      }
    } else {
      return (
        <C2Icon
          icon={icon}
          className={classNames(sharedClassName, `pi`)}
          ref={ref}
          style={style}
        />
      );
    }
  }
);

export default Icon;
