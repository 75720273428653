import { Skeleton } from "primereact/skeleton";
import styles from "./Skeletons.module.css";
import { classNames } from "primereact/utils";

const HomeSkeleton = () => {
  return (
    <div className="flex w-full h-full bg-white-50">
      <div
        className={classNames(
          "w-full h-3rem bg-white-500 fixed top-0",
          styles.mobileHeader
        )}
      >
        <Skeleton className="h-3rem w-full" />
      </div>
      <div
        className={classNames(
          "h-full w-16rem bg-white-700 border-noround py-4 px-4 flex flex-column justify-content-between fixed overflow-y-auto overflow-x-hidden",
          styles.sidebarContainer
        )}
      >
        <div className={classNames(styles.sidebar)}>
          <Skeleton
            className={classNames(
              styles.sidebarItem,
              "w-8rem h-2rem bg-white-500 mb-5"
            )}
          />
          <Skeleton
            className={classNames(
              "w-6rem h-1rem bg-white-500 mb-3 border-round-md",
              styles.sidebarSmallTitle
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-5",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-6rem h-1rem bg-white-500 mb-3 border-round-md",
              styles.sidebarSmallTitle
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-5",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-6rem h-1rem bg-white-500 mb-3 border-round-md",
              styles.sidebarSmallTitle
            )}
          />

          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-3",
              styles.sidebarItem
            )}
          />
          <Skeleton
            className={classNames(
              "w-full h-2rem bg-white-500 mb-5",
              styles.sidebarItem
            )}
          />
        </div>
        <div
          className={classNames(
            styles.sidebarUserContainer,
            "flex-row gap-3 flex"
          )}
        >
          <Skeleton className="border-circle w-2rem h-2rem bg-white-500" />
          <div className={styles.sidebarUserInfo}>
            <Skeleton className="bg-white-500 w-5rem h-1rem mb-1" />
            <Skeleton className="bg-white-500 w-9rem h-1rem" />
          </div>
        </div>
      </div>
      <Skeleton
        className={classNames(
          "m-6 w-full border-round-3xl bg-white h-25rem",
          styles.projectCards
        )}
      />
    </div>
  );
};

export default HomeSkeleton;
