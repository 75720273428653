import type {
  ICollaborativeDocumentPath,
  ICollaborativeOperation,
  IListDeleteOp,
  IListInsertOp,
  IListReplaceOp,
  IObjectDeleteOp,
  IObjectInsertOp,
  IObjectReplaceOp
} from "./types";

function ObjectInsertOp(
  path: ICollaborativeDocumentPath,
  key: string,
  value: unknown
): IObjectInsertOp {
  return {
    p: [...path, key],
    oi: value
  };
}

function isObjectInsertOp(op: ICollaborativeOperation): op is IObjectInsertOp {
  return (
    typeof (op as IObjectInsertOp).oi !== "undefined" &&
    typeof (op as IObjectDeleteOp).od === "undefined"
  );
}

function ObjectDeleteOp(
  path: ICollaborativeDocumentPath,
  key: string,
  value: unknown
): IObjectDeleteOp {
  return {
    p: [...path, key],
    od: value
  };
}

function isObjectDeleteOp(op: ICollaborativeOperation): op is IObjectDeleteOp {
  return (
    typeof (op as IObjectDeleteOp).od !== "undefined" &&
    typeof (op as IObjectInsertOp).oi === "undefined"
  );
}

function ListInsertOp(
  path: ICollaborativeDocumentPath,
  index: number,
  value: unknown
): IListInsertOp {
  return {
    p: [...path, index],
    li: value
  };
}

function isListInsertOp(op: ICollaborativeOperation): op is IListInsertOp {
  return (
    typeof (op as IListInsertOp).li !== "undefined" &&
    typeof (op as IListDeleteOp).ld === "undefined"
  );
}

function ListDeleteOp(
  path: ICollaborativeDocumentPath,
  index: number,
  value: unknown
): IListDeleteOp {
  return {
    p: [...path, index],
    ld: value
  };
}

function isListDeleteOp(op: ICollaborativeOperation): op is IListDeleteOp {
  return (
    typeof (op as IListDeleteOp).ld !== "undefined" &&
    typeof (op as IListInsertOp).li === "undefined"
  );
}

function ObjectReplaceOp(
  path: ICollaborativeDocumentPath,
  key: string,
  oldValue: unknown,
  newValue: unknown
) {
  return {
    p: [...path, ...key.split(".")],
    od: oldValue,
    oi: newValue
  };
}

function isObjectReplaceOp(
  op: ICollaborativeOperation
): op is IObjectReplaceOp {
  return (
    typeof (op as IObjectReplaceOp).oi !== "undefined" &&
    typeof (op as IObjectReplaceOp).od !== "undefined"
  );
}

function ListReplaceOp(
  path: ICollaborativeDocumentPath,
  index: number,
  oldValue: unknown,
  newValue: unknown
): IListReplaceOp {
  return {
    p: [...path, index],
    ld: oldValue,
    li: newValue
  };
}

function isListReplaceOp(op: ICollaborativeOperation): op is IListReplaceOp {
  return (
    typeof (op as IListReplaceOp).li !== "undefined" &&
    typeof (op as IListReplaceOp).ld !== "undefined"
  );
}

export {
  ObjectInsertOp,
  ListInsertOp,
  ObjectDeleteOp,
  ListDeleteOp,
  ObjectReplaceOp,
  ListReplaceOp,
  isListDeleteOp,
  isListInsertOp,
  isObjectDeleteOp,
  isObjectInsertOp,
  isObjectReplaceOp,
  isListReplaceOp
};
