export const COLLECTION_KEYS = {
  PAGES: "pages",
  FLOWS: "flows",
  MISC: "misc",
  VARIABLES: "variables"
} as const;

export const DOCUMENT_KEYS = {
  APP_META: "appMeta",
  VARIABLES: "variables"
} as const;

export const ACTION_TYPES = {
  SELECT_APP: "SELECT_APP",
  COLLAB_CLONE_COMPONENT: "COLLAB_CLONE_COMPONENT",
  COLLAB_DELETE_COMPONENT: "COLLAB_DELETE_COMPONENT",
  COLLAB_EDIT_COMPONENT: "COLLAB_EDIT_COMPONENT",
  COLLAB_EDIT_COMPONENT_NAME: "COLLAB_EDIT_COMPONENT_NAME",
  COLLAB_ADD_FLOW_NODE: "COLLAB_ADD_FLOW_NODE",
  COLLAB_ADD_FLOW_CONNECTION: "COLLAB_ADD_FLOW_CONNECTION",
  COLLAB_UPDATE_FLOW_NODE: "COLLAB_UPDATE_FLOW_NODE",
  COLLAB_DELETE_FLOW_NODE: "COLLAB_DELETE_FLOW_NODE",
  COLLAB_DELETE_FLOW_EDGE: "COLLAB_DELETE_FLOW_EDGE",
  COLLAB_CREATE_FLOW_EXECUTABLES: "COLLAB_CREATE_FLOW_EXECUTABLES",
  COLLAB_CREATE_FLOW_TRIGGER: "COLLAB_CREATE_FLOW_TRIGGER",
  COLLAB_EDIT_FOLDER_STRUCTURE: "COLLAB_EDIT_FOLDER_STRUCTURE",
  COLLAB_SET_HOME_PAGE: "COLLAB_SET_HOME_PAGE",
  COLLAB_SET_PAGE: "COLLAB_SET_PAGE",
  COLLAB_CREATE_PAGE: "COLLAB_CREATE_PAGE",
  COLLAB_DELETE_PAGE: "COLLAB_DELETE_PAGE",
  COLLAB_EDIT_PAGE: "COLLAB_EDIT_PAGE",
  COLLAB_CREATE_FOLDER: "COLLAB_CREATE_FOLDER",
  COLLAB_EDIT_FOLDER: "COLLAB_EDIT_FOLDER",
  COLLAB_DELETE_FOLDER: "COLLAB_DELETE_FOLDER",
  COLLAB_ADD_VARIABLE: "COLLAB_ADD_VARIABLE",
  COLLAB_EDIT_VARIABLE: "COLLAB_EDIT_VARIABLE",
  COLLAB_DELETE_VARIABLE: "COLLAB_DELETE_VARIABLE",
  COLLAB_BATCH_OPS: "COLLAB_BATCH_OPS",
  CLOSE: "CLOSE",
  ON_CLOSE_APP: "ON_CLOSE_APP"
};

export const COLLAB_EVENTS = {
  CONNECTION_READY: "connectionReady",
  APP_META_FETCHED: "appMetaFetched",
  PAGE_FETCHED: "pageFetched",
  FLOW_FETCHED: "flowFetched",
  VARIABLES_FETCHED: "variablesFetched",
  PAGE_CREATED: "pageCreated",
  SET_FLOW: "setFlow",
  FLOWS_FETCHED: "flowsFetched"
};

export const PAGE_META_COMPONENTS_KEY = "components";
export const COMPONENT_META_CHILDREN_KEY = "children";
export const COMPONENT_META_PROPS_KEY = "propMask";
export const COMPONENT_META_EVENTS_KEY = "events";
export const COMPONENT_META_NAME_KEY = "name";

export const FLOW_UI_KEY = "flowUI";
export const FLOW_EXECUTIONS_KEY = "executions";
