import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Checkbox = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonCheckboxDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 3
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true
    };
  }
  return commonDef;
};

const getCommonCheckboxDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-checkbox-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "Checkbox",
    events: {},
    props: {
      c2FieldType: "Checkbox",
      className: {}
    },
    propMask: {},
    name: "Checkbox",
    children: [],
    uiBlock: ["Checkbox", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "value",
      displayName: "Value",
      key: "checked",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: ["spacing", "effects"],
  availableDesignMode: "both"
});

export default Checkbox;
