import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Column, Query, Row } from "../dataModel/types";
import type { RootState } from "../../app/store";

export interface QueryStateData {
  queryId: string;
  queryRows?: Row[];
  queryColumns?: Column[];
  queryElapsedTime?: number;
  isQueryRunning?: boolean;
  isQueryCanceling?: boolean;
  queryCancelUri?: string;
  initialQueryRun?: boolean;
  runError?: string;
}

export interface DataModelQueryState {
  selectedQuery: Query | null;
  queryStateMap: { [key: string]: QueryStateData | undefined };
}

const initialState: DataModelQueryState = {
  queryStateMap: {},
  selectedQuery: null
};

export const dataModelQuerySlice = createSlice({
  name: "dataModelQuery",
  initialState,
  reducers: {
    setQueryState: (state, action: PayloadAction<QueryStateData>) => {
      state.queryStateMap[action.payload.queryId] = action.payload;
    },
    setSelectedQuery: (state, action: PayloadAction<Query | null>) => {
      state.selectedQuery = action.payload;
    },
    setQueryRows: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.queryRows = action.payload.queryRows;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setQueryColumns: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.queryColumns = action.payload.queryColumns;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setQueryElapsedTime: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.queryElapsedTime = action.payload.queryElapsedTime;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setIsQueryRunning: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.isQueryRunning = action.payload.isQueryRunning;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setIsQueryCanceling: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.isQueryCanceling = action.payload.isQueryCanceling;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setQueryCancelUri: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.queryCancelUri = action.payload.queryCancelUri;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setInitialQueryRun: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.initialQueryRun = action.payload.initialQueryRun;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    },
    setRunError: (state, action: PayloadAction<QueryStateData>) => {
      const queryState = state.queryStateMap[action.payload.queryId];
      if (queryState) {
        queryState.runError = action.payload.runError;
        state.queryStateMap[action.payload.queryId] = queryState;
      }
    }
  }
});

export const {
  setQueryState,
  setSelectedQuery,
  setQueryRows,
  setQueryColumns,
  setQueryElapsedTime,
  setIsQueryRunning,
  setIsQueryCanceling,
  setQueryCancelUri,
  setInitialQueryRun,
  setRunError
} = dataModelQuerySlice.actions;

export const selectSelectedQuery = (state: RootState) =>
  state.dataModelQuery.selectedQuery;

export const selectQueryStateMap = (state: RootState) =>
  state.dataModelQuery.queryStateMap;

export default dataModelQuerySlice.reducer;
