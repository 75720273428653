import { useContext, useEffect } from "react";
import OnboardingContext from "../context";

const useEvaluationContextUpdate = (key: string, value: unknown) => {
  const { setEvaluationContext } = useContext(OnboardingContext);

  useEffect(() => {
    setEvaluationContext((evaluationContext) => ({
      ...evaluationContext,
      [key]: value
    }));
  }, [key, setEvaluationContext, value]);
};

export default useEvaluationContextUpdate;
