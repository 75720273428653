import * as React from "react";
const SvgDynamics365 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_12234_1342)" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip1_12234_1342)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_12234_1342", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -1, y: 0, width: 49, height: 48 }, /* @__PURE__ */ React.createElement("path", { d: "M47.999 0H-0.000976562V48H47.999V0Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_12234_1342)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask1_12234_1342", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 6, y: 0, width: 35, height: 48 }, /* @__PURE__ */ React.createElement("path", { d: "M41 15.5235C41 13.4104 39.6717 11.5255 37.6817 10.8148L8.67265 0.454509C7.3702 -0.0106603 6 0.954935 6 2.33799V18.0905C6 18.9357 6.53135 19.6897 7.32735 19.974L20.3274 24.6169C21.6298 25.082 23 24.1164 23 22.7334V13.6884C23 12.9897 23.6983 12.5063 24.3522 12.7524L27.7611 14.0353C29.7098 14.7687 31 16.6328 31 18.7149V22.6538L16.3136 28.0199C15.5248 28.3082 15 29.0586 15 29.8985V45.6398C15 47.0291 16.3815 47.9951 17.6864 47.5183L37.716 40.1998C39.6881 39.4792 41 37.6032 41 35.5035L41 15.5235Z", fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_12234_1342)" }, /* @__PURE__ */ React.createElement("path", { d: "M6 -0.5L41 11.9999V29.1623C41 30.5453 39.63 31.5109 38.3275 31.0459L31 28.4296V18.712C31 16.629 29.7086 14.7641 27.7586 14.0315L24.3517 12.7514C23.6978 12.5058 23 12.9891 23 13.6875V25.5714L6 19.5V-0.5Z", fill: "url(#paint0_linear_12234_1342)", style: {} }), /* @__PURE__ */ React.createElement("g", { filter: "url(#filter0_f_12234_1342)" }, /* @__PURE__ */ React.createElement("path", { d: "M41.001 15.6999C41.001 17.7996 39.6891 19.6793 37.7169 20.3999L15.001 28.6999V48.7L41.001 39.1999V15.6999Z", fill: "black", fillOpacity: 0.24, style: {
  fill: "black",
  fill: "black",
  fillOpacity: 0.24
} })), /* @__PURE__ */ React.createElement("g", { filter: "url(#filter1_f_12234_1342)" }, /* @__PURE__ */ React.createElement("path", { d: "M41.001 16.4999C41.001 18.5996 39.6891 20.4792 37.7169 21.1998L15.001 29.4999V49.4999L41.001 39.9999V16.4999Z", fill: "black", fillOpacity: 0.32, style: {
  fill: "black",
  fill: "black",
  fillOpacity: 0.32
} })), /* @__PURE__ */ React.createElement("path", { d: "M41 15.4999C41 17.5996 39.6882 19.4792 37.716 20.1998L15 28.4999V48.4999L41 38.9999V15.4999Z", fill: "url(#paint1_linear_12234_1342)", style: {} }), /* @__PURE__ */ React.createElement("path", { opacity: 0.5, d: "M41 15.4999C41 17.5996 39.6882 19.4792 37.716 20.1998L15 28.4999V48.4999L41 38.9999V15.4999Z", fill: "url(#paint2_linear_12234_1342)", style: {} }), /* @__PURE__ */ React.createElement("path", { opacity: 0.5, d: "M31.0006 22.6601L22.9961 25.5884L22.9961 37.3174C22.9961 38.0161 23.6945 38.4994 24.3484 38.2533L27.762 36.9684C29.7105 36.2349 31.0006 34.3709 31.0006 32.2889V22.6601Z", fill: "#B0ADFF", style: {
  fill: "#B0ADFF",
  fill: "color(display-p3 0.6902 0.6784 1.0000)",
  fillOpacity: 1
} }))))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", { id: "filter0_f_12234_1342", x: 14.601, y: 15.2999, width: 26.8, height: 33.8001, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" }, /* @__PURE__ */ React.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }), /* @__PURE__ */ React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }), /* @__PURE__ */ React.createElement("feGaussianBlur", { stdDeviation: 0.2, result: "effect1_foregroundBlur_12234_1342" })), /* @__PURE__ */ React.createElement("filter", { id: "filter1_f_12234_1342", x: 11.001, y: 12.4999, width: 34, height: 41, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" }, /* @__PURE__ */ React.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }), /* @__PURE__ */ React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }), /* @__PURE__ */ React.createElement("feGaussianBlur", { stdDeviation: 2, result: "effect1_foregroundBlur_12234_1342" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_12234_1342", x1: 19.0226, y1: -0.5, x2: 28.3293, y2: 23.8617, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#0B53CE", style: {
  stopColor: "#0B53CE",
  stopColor: "color(display-p3 0.0431 0.3255 0.8078)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#7252AA", style: {
  stopColor: "#7252AA",
  stopColor: "color(display-p3 0.4471 0.3216 0.6667)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "paint1_linear_12234_1342", x1: 32.0689, y1: 46.7461, x2: 32.0689, y2: 17.7075, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#2266E3", style: {
  stopColor: "#2266E3",
  stopColor: "color(display-p3 0.1333 0.4000 0.8902)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#AE7FE2", style: {
  stopColor: "#AE7FE2",
  stopColor: "color(display-p3 0.6824 0.4980 0.8863)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "paint2_linear_12234_1342", x1: 41, y1: 28.3929, x2: 31.0382, y2: 28.3929, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#94B9FF", style: {
  stopColor: "#94B9FF",
  stopColor: "color(display-p3 0.5804 0.7255 1.0000)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.287843, stopColor: "#94B9FF", stopOpacity: 0.523646, style: {
  stopColor: "#94B9FF",
  stopColor: "color(display-p3 0.5804 0.7255 1.0000)",
  stopOpacity: 0.523646
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#538FFF", stopOpacity: 0, style: {
  stopColor: "none",
  stopColor: "none",
  stopOpacity: 0
} })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_12234_1342" }, /* @__PURE__ */ React.createElement("rect", { width: 48, height: 48, fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
}, transform: "translate(-0.000976562)" })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip1_12234_1342" }, /* @__PURE__ */ React.createElement("rect", { width: 48, height: 48, fill: "white", style: {
  fill: "white",
  fill: "white",
  fillOpacity: 1
}, transform: "translate(-0.000976562)" }))));
export default SvgDynamics365;
