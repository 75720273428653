import type { IVariables, PageMeta } from "@code2io/fe-engine/dist/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { cloneDeep, get, set, unset } from "lodash";
import ReconnectingWebSocket from "reconnecting-websocket";
import type { Connection } from "sharedb/lib/client";
import ShareDB from "sharedb/lib/client";
import type { Socket } from "sharedb/lib/sharedb";
import { EnvironmentConstants } from "../../../environmentConstants";
import { setToken } from "../../../features/auth/authSlice";
import type { Query } from "../../../features/dataModel/types";
import {
  getEmptyBEFlowManifest,
  getEmptyFEFlowManifest,
  getEmptyReusableFlowManifest,
  getEmptyScheduledFlowManifest,
  getEmptyWebhookFlowManifest
} from "../../../features/flowDesigner/data";
import { setSelectedFlow } from "../../../features/flowDesigner/flowDesignerSlice";
import {
  isBackendFlow,
  isFlow,
  isFrontendFlow,
  isReusableFlow
} from "../../../features/flowDesigner/flowUtilities";
import type {
  IFlow,
  IFrontendFlow,
  INewComponentFlowConfig
} from "../../../features/flowDesigner/types";
import type { Command } from "../../../features/undoRedo/types";
import {
  isCreateCommand,
  isDeleteCommand,
  isOpCommand
} from "../../../features/undoRedo/types";
import {
  redo,
  redoWithoutPushingToUndo,
  undo,
  undoWithoutPushingToRedo
} from "../../../features/undoRedo/undoRedoSlice";
import {
  invertCreateCommand,
  invertDeleteCommand,
  invertOpCommand
} from "../../../features/undoRedo/undoRedoUtils";
import type { Nullable } from "../../../types";
import type { AppDispatch, RootState } from "../../store";
import { getDynamicConfigValue } from "../../utils/dynamicConfig";
import { authApi } from "../auth";
import { migrateFrom100to110 } from "./appMetaUtils";
import { COLLECTION_KEYS } from "./constants";
import {
  isListDeleteOp,
  isListInsertOp,
  isObjectDeleteOp,
  isObjectInsertOp,
  isObjectReplaceOp
} from "./opCreators";
import type {
  CreateSharedDocumentParameters,
  DeleteSharedDocumentParameters,
  ICollaborativeDocument,
  ICollaborativeError,
  ICollaborativeOperation,
  ISharedAppMeta,
  ISharedAppMetav100,
  SubmitOperationsParameters
} from "./types";
import type {
  QueryFolderStructure,
  QueryTabsStructure
} from "../../../features/dataModelQuery/types";
import type {
  ReusableFlowMetadata,
  ScheduledFlowMetadata,
  WebhookFlowMetadata,
  WebhookMethodParameterOption
} from "../../../features/flowsPage/types";
import customFetchBase from "../customFetchBase";
import { getAppId } from "../../utils/helpers";

let socket: ReconnectingWebSocket | null = null;
let connection: Connection | null = null;
let connectedAppId: string | null = null;

async function getConnection(): Promise<Connection> {
  if (connection) {
    return Promise.resolve(connection);
  }
  return new Promise((resolve, reject) => {
    const listener = (event: CustomEventInit<{ connection: Connection }>) => {
      if (typeof event.detail === "undefined") {
        reject("Could not get connection");
      } else {
        connection = event.detail.connection;
        resolve(event.detail.connection);
      }
    };
    document.removeEventListener("connected", listener);
    document.addEventListener("connected", listener);
  });
}

function setConnection(con: Connection) {
  connection = con;
}

async function getComponentFlowIds(componentId: string): Promise<string[]> {
  const connection = await getConnection();
  const query = connection.createFetchQuery("flows", {
    componentId
  });
  return new Promise((resolve, reject) => {
    query.on("ready", () => {
      resolve(query.results.map((doc) => doc.id));
    });
    query.on("error", (err) => {
      reject(err);
    });
  });
}

async function getData<T>(
  collectionId: string,
  documentId: string
): Promise<T | null> {
  const connection = await getConnection();
  return new Promise((resolve, reject) => {
    const doc = connection.get(collectionId, documentId);
    doc.fetch((err?: ICollaborativeError) => {
      if (err) {
        reject(err.message);
      }
      resolve(doc.data as T | null);
    });
  });
}

export const collaborationApi = createApi({
  reducerPath: "collaborationApi",
  baseQuery: customFetchBase,
  tagTypes: [
    "existence",
    "componentFlows",
    "beFlow",
    "feFlow",
    "reusableFlow",
    "pages",
    "page",
    "misc",
    "variables",
    "webhookFlows",
    "scheduledFlows",
    "reusableFlows",
    "FlowTriggerStatus"
  ],
  endpoints: (builder) => {
    return {
      apply: builder.mutation<unknown, Command[]>({
        queryFn: async (commands, { dispatch }) => {
          const appDispatch = dispatch as AppDispatch;

          const opCommands = commands.filter(isOpCommand);
          const createCommands = commands.filter(isCreateCommand);
          const deleteCommands = commands.filter(isDeleteCommand);

          const opPromises = opCommands.map((command) => {
            const inverted = invertOpCommand(command);
            return {
              apply: () =>
                appDispatch(
                  collaborationApi.endpoints.submitOperations.initiate(
                    command.payload
                  )
                ),
              rollback: () =>
                appDispatch(
                  collaborationApi.endpoints.submitOperations.initiate(
                    inverted.payload
                  )
                )
            };
          });
          const createDocumentPromises = createCommands.map((command) => {
            const inverted = invertCreateCommand(command);
            return {
              apply: () =>
                appDispatch(
                  collaborationApi.endpoints.createSharedDocument.initiate(
                    command.payload
                  )
                ),
              rollback: () =>
                appDispatch(
                  collaborationApi.endpoints.deleteSharedDocument.initiate(
                    inverted.payload
                  )
                )
            };
          });
          const deleteDocumentPromises = deleteCommands.map((command) => {
            const inverted = invertDeleteCommand(command);
            return {
              apply: () =>
                appDispatch(
                  collaborationApi.endpoints.deleteSharedDocument.initiate(
                    command.payload
                  )
                ),
              rollback: () =>
                appDispatch(
                  collaborationApi.endpoints.createSharedDocument.initiate(
                    inverted.payload
                  )
                )
            };
          });
          await Promise.all([
            ...opPromises.map((promise) => promise.apply()),
            ...createDocumentPromises.map((promise) => promise.apply()),
            ...deleteDocumentPromises.map((promise) => promise.apply())
          ])
            .then(async (results) => {
              if (results.some((result) => "error" in result)) {
                const fullfilledPromiseIndices = results.reduce<number[]>(
                  (acc, result, index) => {
                    if (!("error" in result)) {
                      acc.push(index);
                    }
                    return acc;
                  },
                  []
                );
                const promises = [
                  ...opPromises,
                  ...createDocumentPromises,
                  ...deleteDocumentPromises
                ];
                const rollbackPromises = fullfilledPromiseIndices.map(
                  (index) => {
                    return promises[index].rollback();
                  }
                );
                await Promise.all(rollbackPromises);
              }
            })
            .catch(() => {
              // IGNORE
            });

          return {
            data: "ignored"
          };
        }
      }),
      undo: builder.mutation<unknown, unknown>({
        queryFn: async (_, { dispatch, getState }) => {
          const appDispatch = dispatch as AppDispatch;
          const appState = getState as () => RootState;
          const undoStack = appState().undoRedo.undo;
          if (undoStack.length === 0) {
            console.warn("Nothing to undo");
            return Promise.resolve({
              data: "ignored"
            });
          }
          const commands = undoStack[undoStack.length - 1];
          await appDispatch(collaborationApi.endpoints.apply.initiate(commands))
            .then((result) => {
              if ("error" in result) {
                appDispatch(undoWithoutPushingToRedo());
              } else {
                appDispatch(undo());
              }
            })
            .catch(() => {
              // TODO
            });
          return {
            data: "ignored"
          };
        }
      }),
      redo: builder.mutation<unknown, unknown>({
        queryFn: async (_, { dispatch, getState }) => {
          const appDispatch = dispatch as AppDispatch;
          const appState = getState as () => RootState;
          const redoStack = appState().undoRedo.redo;
          if (redoStack.length === 0) {
            console.warn("Nothing to redo");
            return Promise.resolve({
              data: "ignored"
            });
          }
          const commands = redoStack[redoStack.length - 1];
          await appDispatch(
            collaborationApi.endpoints.apply.initiate(commands)
          ).then((result) => {
            if ("error" in result) {
              appDispatch(redoWithoutPushingToUndo());
            } else {
              appDispatch(redo());
            }
          });
          return {
            data: "ignored"
          };
        }
      }),
      connect: builder.mutation<string, string>({
        queryFn: async (appId, { dispatch, getState }) => {
          // these are not typed correctly
          const appDispatch = dispatch as AppDispatch;
          const appState = getState as () => RootState;
          if (connection !== null && connectedAppId === appId) {
            console.warn(`Already connected to ${appId}`);
            return {
              data: appId
            };
          }
          if (connection !== null && connectedAppId !== appId) {
            connection.close();
            connection = null;
            socket?.close();
            socket = null;
          }
          return new Promise((resolve, reject) => {
            socket = new ReconnectingWebSocket(
              `${getDynamicConfigValue(
                EnvironmentConstants.COLLABORATION_BACKEND_ADDRESS
              )}?appId=${appId}`,
              [],
              { debug: false }
            );
            const listener = async (event: MessageEvent<string>) => {
              const data = JSON.parse(event.data) as {
                type: string;
                success: boolean;
                code: number;
              };
              const { success, type, code } = data;
              if (type !== "authorization") {
                throw new Error(
                  "Type Is Not Authorization! " + JSON.stringify(data)
                );
                return;
              }
              if (!success && code === 401) {
                await navigator.locks.request("refreshToken", () => {
                  const result = appDispatch(
                    authApi.endpoints.getRefreshToken.initiate()
                  );
                  result
                    .then((res) => {
                      if (res.data) {
                        appDispatch(setToken(res.data.access_token));
                      }
                      result.unsubscribe();
                    })
                    .catch((err) => {
                      window.location.href =
                        "/login?redirectPath=" + window.location.pathname;
                      console.error(err);
                    });
                });
              } else {
                connection = new ShareDB.Connection(socket as Socket);
                socket?.removeEventListener(
                  "message",
                  listener as (event: MessageEvent) => void
                );
                document.dispatchEvent(
                  new CustomEvent("connected", { detail: { connection } })
                );
                setConnection(connection);
                connectedAppId = appId;
                console.log("Connected to ", appId);
                resolve({
                  data: appId
                });
              }
            };
            socket.addEventListener("error", (err) => {
              throw new Error(err.message);
            });
            socket.addEventListener("close", (event) => {
              if (event.code === 4401) {
                void navigator.locks.request("refreshToken", () => {
                  //appDispatch(authApi.util.invalidateTags(["AuthRefresh"]));
                  const result = appDispatch(
                    authApi.endpoints.getRefreshToken.initiate()
                  );
                  void result
                    .then((res) => {
                      if (res.data) {
                        dispatch(setToken(res.data.access_token));
                      }
                      result.unsubscribe();
                    })
                    .catch((err) => {
                      window.location.href =
                        "/login?redirectPath=" + window.location.pathname;
                      reject(err);
                    });
                });
              }
            });
            socket.addEventListener("open", () => {
              socket?.send(
                JSON.stringify({
                  type: "authorization",
                  token: `Bearer ${appState().auth.token ?? ""}`
                })
              );
            });
            socket.addEventListener(
              "message",
              listener as (event: MessageEvent) => void
            );
          });
        }
      }),
      disconnect: builder.mutation<string, string>({
        queryFn: async (appId) => {
          return new Promise((resolve) => {
            connection?.close();
            socket?.close();
            connection = null;
            socket = null;
            connectedAppId = null;
            resolve({
              data: appId
            });
          });
        }
      }),

      getComponentFlows: builder.query<
        { id: string; name: string; autoGenerated?: boolean }[],
        { id: string; componentId: string }
      >({
        providesTags: (_result, _error, args) => [
          { type: "componentFlows", id: args.componentId }
        ],
        query: ({ id, componentId }) => ({
          url: `apps/${id}/definitions/components/${componentId}`,
          method: "GET"
        })
      }),
      renameFlow: builder.mutation<
        | { type: "fe"; componentId: string }
        | {
            type: "be" | "reusable";
            id: string;
            triggerType?: "webhook" | "scheduled";
          },
        { id: string; oldName: string; newName: string }
      >({
        invalidatesTags: (result, error) => {
          if (!result || error) return [];
          if (result.type === "fe") {
            return [{ type: "componentFlows", id: result.componentId }];
          }
          if (result.type === "be") {
            if (result.triggerType === "webhook") {
              return [{ type: "webhookFlows" }];
            }
            if (result.triggerType === "scheduled") {
              return [{ type: "scheduledFlows" }];
            }
            return [{ type: "beFlow", id: result.id }];
          }
          return ["reusableFlows"];
        },
        queryFn: async ({ id, oldName, newName }) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              id
            ) as ICollaborativeDocument<IFlow>;
            doc.fetch((err?: ICollaborativeError) => {
              if (err) reject(err.message);
              const type = doc.data.type;
              const id = doc.data.id;
              const triggerType =
                doc.data.type === "be"
                  ? (doc.data.draft.trigger.type as "webhook" | "scheduled")
                  : undefined;
              const componentId =
                doc.data.type === "fe" ? doc.data.componentId : undefined;
              doc.submitOp(
                {
                  p: ["name"],
                  od: oldName,
                  oi: newName
                },
                {},
                (err?: ICollaborativeError) => {
                  if (err) reject(err.message);
                  const data =
                    type === "fe"
                      ? {
                          type,
                          componentId: componentId!
                        }
                      : {
                          type,
                          id,
                          triggerType
                        };
                  resolve({ data });
                }
              );
            });
          });
        }
      }),
      createComponentFlow: builder.mutation<string, INewComponentFlowConfig>({
        invalidatesTags: (_result, _error, args) => [
          { type: "componentFlows", id: args.relatedComponentId },
          { type: "feFlow", id: args.id },
          { type: "existence", id: args.id }
        ],
        onQueryStarted: async (
          args,
          { dispatch, queryFulfilled, getState }
        ) => {
          const state = getState() as RootState;
          const appId = getAppId();
          const prevSelectedFlow = state.flowDesigner.selectedFlow;
          const selectedElementId =
            state.designer.selectedElementIds.length === 1
              ? state.designer.selectedElementIds[0]
              : null;
          const patchResult = dispatch(
            collaborationApi.util.updateQueryData(
              "getComponentFlows",
              { id: appId, componentId: args.relatedComponentId },
              (draft) => {
                if (!draft) {
                  return [
                    {
                      id: args.id,
                      name: args.name,
                      autoGenerated: args.autoGenerated
                    }
                  ];
                }
                draft.push({
                  id: args.id,
                  name: args.name,
                  autoGenerated: args.autoGenerated
                });
              }
            )
          );
          if (selectedElementId === args.relatedComponentId) {
            dispatch(setSelectedFlow(args.id));
          }
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
            dispatch(setSelectedFlow(prevSelectedFlow));
          }
        },
        queryFn: async (config) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              config.id
            ) as ICollaborativeDocument<IFlow>;
            doc.create(
              getEmptyFEFlowManifest(config),
              (err?: ICollaborativeError) => {
                if (err) {
                  reject("There was an error creating the new flow");
                  return;
                }
                resolve({
                  data: doc.id
                });
              }
            );
          });
        }
      }),
      createComponentFlowWithExistFlow: builder.mutation<
        unknown,
        { id: string; flow: IFrontendFlow }
      >({
        invalidatesTags: (_result, _error, { flow }) => [
          { type: "componentFlows", id: flow.componentId },
          { type: "feFlow", id: flow.id },
          { type: "existence", id: flow.id }
        ],
        onQueryStarted: async (
          args,
          { dispatch, queryFulfilled, getState }
        ) => {
          await queryFulfilled;
          const appDispatch = dispatch as AppDispatch;
          const state = getState() as RootState;
          if (
            state.designer.selectedElementIds.find(
              (item) => item === args.flow.componentId
            )
          ) {
            appDispatch(setSelectedFlow(args.flow.id));
          }
        },
        queryFn: async (config) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              config.id
            ) as ICollaborativeDocument<IFlow>;
            doc.create(config.flow, (err?: ICollaborativeError) => {
              if (err) {
                reject("There was an error creating the new flow");
                return;
              }
              resolve({
                data: doc.id
              });
            });
          });
        }
      }),
      deleteComponentFlows: builder.mutation<IFlow[], string>({
        invalidatesTags: (result, _, id) => [
          { type: "componentFlows", id },
          ...(result?.map((flow) => ({
            type: "feFlow" as const,
            id: flow.id
          })) ?? [])
        ],
        onQueryStarted: async (arg, { queryFulfilled, dispatch, getState }) => {
          await queryFulfilled;
          const appDispatch = dispatch as AppDispatch;
          const state = getState() as RootState;
          if (state.designer.selectedElementIds.find((item) => item === arg)) {
            appDispatch(setSelectedFlow(null));
          } else {
            const pageId = state.designer.currentPageId;
            const page =
              collaborationApi.endpoints.getPageMetaDocument.select(pageId)(
                state
              );
            if (page.data) {
              if (typeof page.data.components[arg] === "undefined") {
                appDispatch(setSelectedFlow(null));
              }
            }
          }
        },
        queryFn: async (id) => {
          const connection = await getConnection();
          const docIds = await getComponentFlowIds(id);
          const data = await Promise.all(
            docIds.map((docId) => {
              return new Promise<IFlow>((resolve, reject) => {
                const doc = connection.get(
                  "flows",
                  docId
                ) as ICollaborativeDocument<IFlow>;
                doc.fetch((err?: ICollaborativeError) => {
                  if (err) {
                    reject(err.message);
                    return;
                  }
                  const data = cloneDeep(doc.data);
                  doc.del({}, (err?: ICollaborativeError) => {
                    if (err) reject(err.message);
                    resolve(data);
                  });
                });
              });
            })
          );
          return {
            data
          };
        }
      }),
      deleteFlow: builder.mutation<IFlow, string>({
        invalidatesTags: (result, error, args) => {
          if (error || !result) {
            return [];
          }
          if (isFrontendFlow(result)) {
            return [
              { type: "componentFlows", id: result.componentId },
              { type: "feFlow", id: result.id },
              { type: "existence", id: result.id }
            ];
          }
          if (isBackendFlow(result)) {
            if (result.draft.trigger.type === "webhook") {
              return ["webhookFlows"];
            } else {
              return ["scheduledFlows"];
            }
          }
          if (isReusableFlow(result)) {
            return ["reusableFlows"];
          }
          return [
            {
              type: "existence",
              id: args
            }
          ];
        },
        onQueryStarted: async (_, { queryFulfilled, getState, dispatch }) => {
          const result = await queryFulfilled;
          const appDispatch = dispatch as AppDispatch;
          const state = getState() as RootState;
          const appId = getAppId();
          const flowData = result.data;
          if (flowData.type === "fe") {
            const selectedFlowId = state.flowDesigner.selectedFlow;
            if (flowData.id !== selectedFlowId) {
              return;
            }
            const selectedComponentId =
              state.designer.selectedElementIds.length === 1
                ? state.designer.selectedElementIds[0]
                : "";
            const { data } =
              collaborationApi.endpoints.getComponentFlows.select({
                id: appId,
                componentId: selectedComponentId
              })(state);
            if (data) {
              appDispatch(
                data.length > 0
                  ? setSelectedFlow(data[0].id)
                  : setSelectedFlow(null)
              );
            }
          } else {
            appDispatch(setSelectedFlow(null));
          }
        },
        queryFn: async (id) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              id
            ) as ICollaborativeDocument<IFlow>;
            doc.fetch((err?: ICollaborativeError) => {
              if (err) {
                reject(
                  `Error deleting flow ${id}, doc.fetch failed: ${err.message}`
                );
                return;
              }
              const data = cloneDeep(doc.data);
              doc.del({}, (err?: ICollaborativeError) => {
                if (err) {
                  reject(
                    `Error deleting flow ${id}, doc.del failed: ${err.message}`
                  );
                  return;
                }
                resolve({ data });
              });
            });
          });
        }
      }),
      createReusableFlow: builder.mutation<
        unknown,
        { id: string; name: string; description: string }
      >({
        invalidatesTags: ["reusableFlow", "reusableFlows"],
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(setSelectedFlow(args.id));
        },
        queryFn: async ({ id, name, description }) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              id
            ) as ICollaborativeDocument<IFlow>;
            doc.create(
              getEmptyReusableFlowManifest({ id, name, description }),
              (err?: ICollaborativeError) => {
                if (err) {
                  reject("There was an error creating the new flow");
                  return;
                }
                resolve({
                  data: doc.id
                });
              }
            );
          });
        }
      }),
      createBackendFlow: builder.mutation<
        unknown,
        { id: string; name: string }
      >({
        invalidatesTags: ["beFlow"],
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(setSelectedFlow(args.id));
        },
        queryFn: async ({ id, name }) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              id
            ) as ICollaborativeDocument<IFlow>;
            doc.create(
              getEmptyBEFlowManifest({ id, name }),
              (err?: ICollaborativeError) => {
                if (err) {
                  reject("There was an error creating the new flow");
                  return;
                }
                resolve({
                  data: doc.id
                });
              }
            );
          });
        }
      }),
      createWebhookFlow: builder.mutation<
        unknown,
        {
          id: string;
          name: string;
          url: string;
          method: WebhookMethodParameterOption;
          description: string;
        }
      >({
        invalidatesTags: ["webhookFlows"],
        queryFn: async (arg) => {
          const connection = await getConnection();
          const base = getEmptyWebhookFlowManifest(arg);
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              arg.id
            ) as ICollaborativeDocument<IFlow>;
            doc.create(base, (err?: ICollaborativeError) => {
              if (err) {
                reject("There was an error creating the new flow");
                return;
              }
              resolve({
                data: doc.id
              });
            });
          });
        }
      }),
      createScheduledFlow: builder.mutation<
        unknown,
        {
          id: string;
          name: string;
          description: string;
        }
      >({
        invalidatesTags: ["scheduledFlows"],
        queryFn: async (arg) => {
          const connection = await getConnection();
          const base = getEmptyScheduledFlowManifest(arg);
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              "flows",
              arg.id
            ) as ICollaborativeDocument<IFlow>;
            doc.create(base, (err?: ICollaborativeError) => {
              if (err) {
                reject("There was an error creating the new flow");
                return;
              }
              resolve({
                data: doc.id
              });
            });
          });
        }
      }),
      getBackendFlows: builder.query<{ id: string; name: string }[], void>({
        providesTags: ["beFlow"],
        queryFn: async () => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const q = { type: "be" };
            const query = connection.createFetchQuery<IFlow>("flows", q);
            query.on("ready", () => {
              resolve({
                data: query.results.map((doc) => ({
                  id: doc.data.id,
                  name: doc.data.name
                }))
              });
            });
            query.on("error", (err) => {
              reject(err.message);
            });
          });
        }
      }),
      getReusableFlows: builder.query<
        ReusableFlowMetadata[],
        { appId: string }
      >({
        providesTags: ["reusableFlows"],
        query: ({ appId }) => ({
          url: `apps/${appId}/definitions?type=reusable`,
          method: "GET"
        })
      }),
      getWebhookFlows: builder.query<WebhookFlowMetadata[], { appId: string }>({
        providesTags: ["webhookFlows"],
        query: ({ appId }) => ({
          url: `apps/${appId}/definitions?type=webhook`,
          method: "GET"
        })
      }),
      getScheduledFlows: builder.query<
        ScheduledFlowMetadata[],
        { appId: string }
      >({
        providesTags: ["scheduledFlows"],
        query: ({ appId }) => ({
          url: `apps/${appId}/definitions?type=scheduled`,
          method: "GET"
        })
      }),
      getBackendFlowActivationStatus: builder.query<
        boolean,
        { id: string; flowId: string }
      >({
        query: ({ id, flowId }) => ({
          url: `/editor/trigger/apps/${id}/flows/${flowId}`,
          method: "GET"
        }),
        transformResponse: (response) => {
          return typeof response === "object" && response !== null;
        },
        providesTags: (_, error, { flowId }) => {
          if (error) {
            return [];
          }
          return [{ type: "FlowTriggerStatus", id: flowId }];
        }
      }),
      activateBackendFlow: builder.mutation<
        { data?: unknown; error?: unknown },
        { id: string; flowId: string }
      >({
        query: ({ id, flowId }) => ({
          url: `/editor/trigger/apps/${id}/flows/${flowId}`,
          method: "POST",
          body: {
            flowId: flowId
          }
        }),
        invalidatesTags: (_, error, { flowId }) => {
          if (error) {
            return [];
          }
          return [{ type: "FlowTriggerStatus", id: flowId }];
        }
      }),

      deactivateBackendFlow: builder.mutation<
        { data?: unknown; error?: unknown },
        { id: string; flowId: string }
      >({
        query: ({ id, flowId }) => ({
          url: `/editor/trigger/apps/${id}/flows/${flowId}`,
          method: "DELETE",
          body: {
            flowId: flowId
          }
        }),
        invalidatesTags: (_, error, { flowId }) => {
          if (error) {
            return [];
          }
          return [{ type: "FlowTriggerStatus", id: flowId }];
        }
      }),
      submitFlowOperations: submitOperations<IFlow>("flows"),
      getPagesMetadata: builder.query<
        Nullable<Pick<PageMeta, "id" | "name" | "path">[]>,
        void
      >({
        queryFn: () => {
          return {
            data: null
          };
        },
        async onCacheEntryAdded(
          _,
          { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
        ) {
          await cacheDataLoaded;
          const connection = await getConnection();
          const query = connection.createSubscribeQuery<PageMeta>("pages", {});
          query.on("ready", () => {
            updateCachedData(() => {
              return query.results.map((doc) => ({
                id: doc.data.id,
                name: doc.data.name,
                path: doc.data.path
              }));
            });
          });
          query.on("insert", (docs) => {
            updateCachedData((draft) => {
              const newDocData = docs.map((doc) => ({
                id: doc.data.id,
                name: doc.data.name,
                path: doc.data.path
              }));
              if (draft === null) {
                return newDocData;
              }
              return [...draft, ...newDocData];
            });
          });
          query.on("remove", (_, index) => {
            updateCachedData((draft) => {
              if (draft === null) {
                return;
              }
              return draft.filter((_, i) => i !== index);
            });
          });

          query.on("error", (error) => {
            console.error(error.message);
          });

          await cacheEntryRemoved;

          query.destroy((err?: ICollaborativeError) => {
            if (err) console.error(err.message);
          });
        }
      }),

      submitOperations: builder.mutation<unknown, SubmitOperationsParameters>({
        queryFn: async ({ collectionId, documentId, ops }) => {
          if (connection === null) {
            return Promise.reject("Connection not initialized");
          }
          const doc = connection.get(collectionId, documentId);
          return new Promise((resolve, reject) => {
            doc.submitOp(ops, {}, (err?: ICollaborativeError) => {
              if (err) {
                reject(err);
              } else {
                resolve({
                  data: doc.id
                });
              }
            });
          });
        }
      }),

      checkIfDocumentExists: builder.query<boolean, string>({
        providesTags: (_result, _error, args) => {
          return [
            {
              type: "existence",
              id: args
            }
          ];
        },
        queryFn: async (id) => {
          const connection = await getConnection();
          const doc = connection.get("flows", id);
          return new Promise((resolve) => {
            doc.fetch((err?: ICollaborativeError) => {
              if (err || doc.type === null) {
                resolve({ data: false });
              } else {
                resolve({ data: true });
              }
            });
          });
        }
      }),

      createSharedDocument: builder.mutation<
        unknown,
        CreateSharedDocumentParameters
      >({
        invalidatesTags: (_, error, args) => {
          if (args.collectionId === "flows" && isFrontendFlow(args.data)) {
            return [
              {
                type: "componentFlows",
                id: args.data.componentId
              },
              {
                type: "existence",
                id: args.data.id
              }
            ];
          } else if (
            args.collectionId === "flows" &&
            isBackendFlow(args.data)
          ) {
            return [
              "beFlow",
              "webhookFlows",
              "scheduledFlows",
              { type: "existence", id: args.data.id }
            ];
          } else if (
            args.collectionId === "flows" &&
            isReusableFlow(args.data)
          ) {
            return [
              "reusableFlow",
              "reusableFlows",
              { type: "existence", id: args.data.id }
            ];
          }
          if (!error) {
            return [{ type: "existence", id: args.documentId }];
          }
          return [];
        },
        queryFn: async ({ collectionId, documentId, data }) => {
          const connection = await getConnection();
          const doc = connection.get(collectionId, documentId);
          return new Promise((resolve, reject) => {
            doc.create(data, (err?: ICollaborativeError) => {
              if (err) {
                reject(err);
              }
              resolve({
                data
              });
            });
          });
        }
      }),

      deleteSharedDocument: builder.mutation<
        unknown,
        DeleteSharedDocumentParameters
      >({
        invalidatesTags: (result, error, args) => {
          if (error) {
            return [];
          }
          if (args.collectionId === "flows" && isFrontendFlow(result)) {
            return [
              {
                type: "componentFlows",
                id: result.componentId
              },
              {
                type: "feFlow",
                id: result.id
              },
              {
                type: "existence",
                id: args.documentId
              }
            ];
          } else if (args.collectionId === "flows" && isBackendFlow(result)) {
            return [
              "beFlow",
              "webhookFlows",
              "scheduledFlows",
              { type: "existence", id: args.documentId }
            ];
          } else if (args.collectionId === "flows" && isReusableFlow(result)) {
            return [
              "reusableFlow",
              "reusableFlows",
              { type: "existence", id: args.documentId }
            ];
          }
          return [{ type: "existence", id: args.documentId }];
        },
        onQueryStarted: async (
          { collectionId },
          { queryFulfilled, dispatch, getState }
        ) => {
          if (collectionId !== "flows") {
            return;
          }
          const result = await queryFulfilled;
          const appDispatch = dispatch as AppDispatch;
          const state = getState() as RootState;
          const flowData = result.data as IFlow;
          const appId = getAppId();
          if (flowData.type === "fe") {
            const selectedFlowId = state.flowDesigner.selectedFlow;
            if (flowData.id !== selectedFlowId) {
              return;
            }
            const selectedComponentId =
              state.designer.selectedElementIds.length === 1
                ? state.designer.selectedElementIds[0]
                : "";
            const { data } =
              collaborationApi.endpoints.getComponentFlows.select({
                id: appId,
                componentId: selectedComponentId
              })(state);
            if (data) {
              appDispatch(
                data.length > 0
                  ? setSelectedFlow(data[0].id)
                  : setSelectedFlow(null)
              );
            } else {
              appDispatch(setSelectedFlow(null));
            }
          } else {
            appDispatch(setSelectedFlow(null));
          }
        },
        queryFn: async ({ collectionId, documentId }) => {
          const connection = await getConnection();
          const doc = connection.get(collectionId, documentId);
          return new Promise((resolve, reject) => {
            doc.fetch((err?: ICollaborativeError) => {
              if (err) {
                reject(err);
              }
              const data = cloneDeep(doc.data as unknown);
              doc.del({}, (err?: ICollaborativeError) => {
                if (err) {
                  reject(err);
                }
                resolve({
                  data
                });
              });
            });
          });
        }
      }),

      createFlowsDocument: createSharedDocument<IFlow>("flows"),

      createPageDocument: createSharedDocument<PageMeta>("pages"),

      createVariablesDocument: createSharedDocument<IVariables>("variables"),

      createAppMetaDocument: createSharedDocument<ISharedAppMeta>("misc"),

      createQueriesDocument: createSharedDocument<Query>("queries"),

      createQueryFolderDocument:
        createSharedDocument<QueryFolderStructure>("query-folders"),

      createQueryTabsDocument:
        createSharedDocument<QueryTabsStructure>("query-tabs"),

      fetchFlowsDocument: getSharedDocument<IFlow>("flows"),

      fetchAppMetaDocument: getSharedDocument<ISharedAppMeta>("misc"),

      fetchVariablesDocument: getSharedDocument<IVariables>("variables"),

      fetchPageMetaDocument: getSharedDocument<PageMeta>("pages"),

      getFlowData: subscribeSharedDocumentData<IFlow>("flows"),

      getVariablesDocument:
        subscribeSharedDocumentData<IVariables>("variables"),

      getAppMetaDocument: subscribeSharedDocumentData<ISharedAppMeta>("misc"),

      getPageMetaDocument: subscribeSharedDocumentData<PageMeta>("pages"),

      getOrCreateQueriesDocument:
        getOrCreateSharedDocumentQuery<Query>("queries"),

      getOrCreateQueryFoldersDocument:
        getOrCreateSharedDocumentQuery<QueryFolderStructure>("query-folders"),

      getOrCreateQueryTabsDocument:
        getOrCreateSharedDocumentQuery<QueryTabsStructure>("query-tabs"),

      getOrCreateFlowsDocument: getOrCreateSharedDocumentQuery<IFlow>("flows"),

      getQueryData: subscribeSharedDocumentData<Query>("queries"),

      getQueryFolderData:
        subscribeSharedDocumentData<QueryFolderStructure>("query-folders"),

      getQueryTabData:
        subscribeSharedDocumentData<QueryTabsStructure>("query-tabs"),

      submitQueryOperations: submitOperations<Query>("queries"),

      submitQueryFolderOperations:
        submitOperations<QueryFolderStructure>("query-folders"),

      submitQueryTabsOperations:
        submitOperations<QueryTabsStructure>("query-tabs"),

      submitPageOperations: submitOperations<PageMeta>("pages"),

      submitVariablesOperations: submitOperations<IVariables>("variables"),

      submitAppMetaOperations: submitOperations<ISharedAppMeta>("misc"),

      deletePageDocument: deleteSharedDocument("pages"),

      deleteSharedQueries: deleteSharedDocument("queries")
    };

    function createSharedDocument<T>(collectionId: string) {
      return builder.mutation<
        T,
        { documentId: string; defaultValue: T | null }
      >({
        invalidatesTags: (result, _, args) => {
          if (args.defaultValue === null)
            return [
              {
                type: "existence",
                id: args.documentId
              }
            ];
          if (!result) return [];
          if (isFlow(result)) {
            if (isFrontendFlow(result)) {
              return [
                {
                  type: "componentFlows",
                  id: result.componentId
                },
                {
                  type: "feFlow",
                  id: result.id
                },
                {
                  type: "existence",
                  id: result.id
                }
              ];
            }
            if (isBackendFlow(result)) {
              return ["beFlow", { type: "existence", id: result.id }];
            }
            if (isReusableFlow(result)) {
              return [
                "reusableFlow",
                {
                  type: "existence",
                  id: result.id
                }
              ];
            }
          }
          return [
            {
              type: "existence",
              id: args.documentId
            }
          ];
        },
        queryFn: async ({
          documentId,
          defaultValue
        }: {
          documentId: string;
          defaultValue: T;
        }) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              collectionId,
              documentId
            ) as ICollaborativeDocument<T>;
            doc.create(defaultValue, (err?: ICollaborativeError) => {
              if (err) {
                reject(err.message);
              } else {
                resolve({
                  data: cloneDeep(doc.data)
                });
              }
            });
          });
        }
      });
    }

    function deleteSharedDocument(collectionId: string) {
      return builder.mutation<{ data: unknown }, string>({
        invalidatesTags: (result) => {
          if (!result) return [];
          switch (collectionId) {
            case "pages": {
              return ["pages"];
            }
            case "misc": {
              return ["misc"];
            }
            case "variables": {
              return ["variables"];
            }
            case "flows": {
              const flow = result.data as IFlow;
              if (isFrontendFlow(flow)) {
                return [
                  {
                    type: "componentFlows",
                    id: flow.componentId
                  },
                  {
                    type: "existence",
                    id: flow.id
                  }
                ];
              }
              if (isBackendFlow(flow)) {
                return ["beFlow", { type: "existence", id: flow.id }];
              }
              return ["reusableFlow", { type: "existence", id: flow.id }];
            }
          }
          return [];
        },
        queryFn: async (id) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              collectionId,
              id
            ) as ICollaborativeDocument<unknown>;
            doc.fetch((err?: ICollaborativeError) => {
              if (err) {
                reject(err.message);
              }
              const data = cloneDeep(doc.data);
              doc.del({}, (err?: ICollaborativeError) => {
                if (err) {
                  reject(err.message);
                } else {
                  resolve({
                    data: {
                      data
                    }
                  });
                }
              });
            });
          });
        }
      });
    }

    function getSharedDocument<T>(collectionId: string) {
      return builder.query<Nullable<T>, { documentId: string }>({
        providesTags: (result, error, args) => {
          if (error) return [];
          if (!result) {
            return [
              {
                type: "existence",
                id: args.documentId
              }
            ];
          }
          if (collectionId === COLLECTION_KEYS.FLOWS) {
            if (isFrontendFlow(result)) {
              return [
                {
                  type: "componentFlows",
                  id: result.componentId
                },
                {
                  type: "feFlow",
                  id: result.id
                },
                {
                  type: "existence",
                  id: result.id
                }
              ];
            }
            if (isBackendFlow(result)) {
              return ["beFlow", { type: "existence", id: result.id }];
            }
            if (isReusableFlow(result)) {
              return ["reusableFlow", { type: "existence", id: result.id }];
            }
          }
          return [
            {
              type: "existence",
              id: args.documentId
            }
          ];
        },
        queryFn: async ({ documentId }) => {
          const connection = await getConnection();
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              collectionId,
              documentId
            ) as ICollaborativeDocument<T>;
            if (documentId.length === 0) {
              resolve({
                data: null
              });
              return;
            }
            doc.fetch((err?: ICollaborativeError) => {
              if (err) {
                reject(err.message);
              } else if (!doc.type) {
                resolve({
                  data: null
                });
              } else {
                resolve({
                  data: cloneDeep(doc.data)
                });
              }
            });
          });
        }
      });
    }

    function getOrCreateSharedDocumentQuery<T>(collectionId: string) {
      return builder.query<T, { documentId: string; defaultValue: T }>({
        queryFn: async ({ documentId, defaultValue }) => {
          const connection = await getConnection();
          const data = await getData<T>(collectionId, documentId);
          if (data) {
            return {
              data: cloneDeep(data)
            };
          }
          return new Promise((resolve, reject) => {
            const doc = connection.get(
              collectionId,
              documentId
            ) as ICollaborativeDocument<T>;
            doc.create(defaultValue, (err?: ICollaborativeError) => {
              if (err) {
                reject(err.message);
                return;
              }
              resolve({
                data: cloneDeep(doc.data)
              });
            });
          });
        }
      });
    }

    function subscribeSharedDocumentData<T>(collectionId: string) {
      return builder.query<Nullable<T>, string>({
        providesTags: (result, error, id) => {
          if (error) return [];
          if (!result) {
            return [
              {
                type: "existence",
                id
              }
            ];
          }
          if (collectionId === COLLECTION_KEYS.FLOWS) {
            if (isFrontendFlow(result)) {
              return [
                {
                  type: "componentFlows",
                  id: result.componentId
                },
                {
                  type: "feFlow",
                  id: result.id
                },
                {
                  type: "existence",
                  id: result.id
                }
              ];
            }
            if (isBackendFlow(result)) {
              return ["beFlow", { type: "existence", id: result.id }];
            }
            if (isReusableFlow(result)) {
              return ["reusableFlow", { type: "existence", id: result.id }];
            }
          }
          return [
            {
              type: "existence",
              id
            }
          ];
        },
        queryFn: async (id) => {
          const connection = await getConnection();
          const doc = connection.get(
            collectionId,
            id
          ) as ICollaborativeDocument<T>;
          return new Promise((resolve, reject) => {
            doc.fetch((err?: ICollaborativeError) => {
              if (err) {
                reject(err);
                return;
              }
              if (!doc.type) {
                resolve({
                  data: null
                });
                return;
              }
              if (collectionId === COLLECTION_KEYS.MISC) {
                const data = doc.data as ISharedAppMeta | ISharedAppMetav100;
                if (
                  typeof (data as ISharedAppMeta | undefined)?.version ===
                  "undefined"
                ) {
                  const newAppMeta = migrateFrom100to110(
                    data as ISharedAppMetav100
                  );

                  doc.submitOp(
                    [
                      {
                        p: ["folderStructure"],
                        od: data.folderStructure,
                        oi: newAppMeta.folderStructure
                      },
                      {
                        p: ["version"],
                        oi: newAppMeta.version
                      }
                    ],
                    {},
                    (err?: ICollaborativeError) => {
                      if (err) {
                        reject("Migration error");
                        return;
                      }
                      resolve({
                        data: cloneDeep(newAppMeta as T)
                      });
                      return;
                    }
                  );
                } else {
                  resolve({
                    data: cloneDeep(data as T)
                  });
                  return;
                }
              }
              resolve({
                data: cloneDeep(doc.data)
              });
            });
          });
        },
        async onCacheEntryAdded(
          id,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          await cacheDataLoaded;
          const connection = await getConnection();
          const doc = connection.get(
            collectionId,
            id
          ) as ICollaborativeDocument<T>;
          try {
            doc.subscribe((err?: ICollaborativeError) => {
              if (err) {
                throw new Error(err.message);
              }
              doc.on("op batch", (ops: ICollaborativeOperation[]) => {
                updateCachedData((draft) => {
                  if (!draft) {
                    return draft;
                  }
                  ops.forEach((op) => {
                    if (isObjectInsertOp(op)) {
                      const { p, oi } = op;
                      set(draft, p, oi);
                    } else if (isObjectDeleteOp(op)) {
                      const { p } = op;
                      unset(draft, p);
                    } else if (isObjectReplaceOp(op)) {
                      const { p, oi } = op;
                      set(draft, p, oi);
                    } else if (isListInsertOp(op)) {
                      const { p, li } = op;
                      const path = p;
                      const end = path.length - 1;
                      const idx = path[end];
                      if (typeof idx === "number") {
                        const list = get(
                          draft,
                          path.slice(0, end)
                        ) as unknown[];
                        list.splice(idx, 0, li);
                      }
                    } else if (isListDeleteOp(op)) {
                      const { p } = op;
                      const path = p;
                      const end = path.length - 1;
                      const idx = path[end];
                      if (typeof idx === "number") {
                        const list = get(
                          draft,
                          path.slice(0, end)
                        ) as unknown[];
                        list.splice(idx, 1);
                      }
                    }
                  });
                });
              });
            });
          } catch (e) {
            console.error(e);
          }
          await cacheEntryRemoved;
          doc.destroy();
        }
      });
    }

    function submitOperations<T>(collectionId: string) {
      return builder.mutation<
        unknown,
        { id: string; ops: ICollaborativeOperation[] }
      >({
        queryFn: ({ id, ops }) => {
          if (connection === null) return { data: null };
          const doc = connection.get(
            collectionId,
            id
          ) as ICollaborativeDocument<T>;
          doc.submitOp(ops);
          return {
            data: doc.id
          };
        }
      });
    }
  }
});

export const {
  useGetComponentFlowsQuery,
  useRenameFlowMutation,
  useCreateComponentFlowMutation,
  useCreateComponentFlowWithExistFlowMutation,
  useDeleteFlowMutation,
  useCreateBackendFlowMutation,
  useCreateReusableFlowMutation,
  useGetReusableFlowsQuery,
  useGetBackendFlowsQuery,
  useSubmitFlowOperationsMutation,
  useGetFlowDataQuery,
  useDeleteComponentFlowsMutation,
  useCreateQueriesDocumentMutation,
  useGetOrCreateQueriesDocumentQuery,
  useGetQueryDataQuery,
  useLazyGetQueryDataQuery,
  useSubmitQueryOperationsMutation,
  useSubmitVariablesOperationsMutation,
  useCreateVariablesDocumentMutation,
  useGetVariablesDocumentQuery,
  useGetAppMetaDocumentQuery,
  useGetPageMetaDocumentQuery,
  useGetPagesMetadataQuery,
  useSubmitPageOperationsMutation,
  useCreateAppMetaDocumentMutation,
  useDeletePageDocumentMutation,
  useSubmitAppMetaOperationsMutation,
  useCreatePageDocumentMutation,
  useLazyFetchAppMetaDocumentQuery,
  useLazyFetchVariablesDocumentQuery,
  useLazyFetchPageMetaDocumentQuery,
  useConnectMutation,
  useDisconnectMutation,
  useUndoMutation,
  useRedoMutation,
  useCheckIfDocumentExistsQuery,
  useFetchFlowsDocumentQuery,
  useCreateQueryFolderDocumentMutation,
  useSubmitQueryFolderOperationsMutation,
  useGetOrCreateQueryFoldersDocumentQuery,
  useGetQueryFolderDataQuery,
  useDeleteSharedQueriesMutation,
  useGetOrCreateQueryTabsDocumentQuery,
  useGetQueryTabDataQuery,
  useSubmitQueryTabsOperationsMutation,
  useGetWebhookFlowsQuery,
  useGetScheduledFlowsQuery,
  useCreateWebhookFlowMutation,
  useCreateScheduledFlowMutation,
  useActivateBackendFlowMutation,
  useDeactivateBackendFlowMutation,
  useGetBackendFlowActivationStatusQuery,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  usePrefetch: usePrefetchCollaborationApi
} = collaborationApi;
