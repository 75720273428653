import { getIdentifierPaths } from "@code2io/fe-engine/dist/common-flow-utils";
import type {
  ASLState,
  ChoiceState,
  FlowDefinition,
  MapState
} from "@code2io/fe-engine/dist/flowExecutor";
import { getExecutionType } from "../config";
import { NodeTypes } from "../constants";
import { getEndLoopNodeIdFor, getStartNode } from "../data";
import { notNull } from "../typeGuards";
import type { IFlow, INode, INodeData, NodeMap, StateMap } from "../types";
import { ResourceTypes } from "./constants";

/**
 * @param uri Resource identifier, example scheme: c2:fe|be:actionType
 * @returns actionType
 */
export function getResource(uri: string): string {
  const schemeParts = uri.split(":");
  if (schemeParts[0] !== "c2" && schemeParts[0] === "be") {
    return ResourceTypes.executeBackendFlow;
  } else if (schemeParts[1] === "be") {
    return ResourceTypes.executeBackendFlow;
  } else if (schemeParts[1] === "fe") {
    return "";
  }
  return schemeParts[2];
}

function isBackendResource(uri: string): boolean {
  const parts = uri.split(":");
  if (parts[0] === "be") {
    return true;
  }
  if (parts[0] === "c2" && parts[1] === "be") {
    return true;
  }

  return false;
}

export function isBackendMapState(state: MapState): boolean {
  return Object.values(state.Iterator.States).every((aslState) =>
    isBackendStep(aslState)
  );
}

export function isBackendChoiceStep(
  state: ChoiceState,
  states: StateMap,
  inBackendChain?: boolean
): boolean {
  if (!inBackendChain) return false;
  let result = true;
  state.Choices.forEach((rule) => {
    const nextState = states[rule.Next] as ASLState | undefined;
    if (nextState && !isBackendStep(nextState, inBackendChain)) {
      result = false;
    }
  });
  if (state.Default) {
    const defaultState = states[state.Default] as ASLState | undefined;
    if (defaultState && !isBackendStep(states[state.Default], inBackendChain)) {
      result = false;
    }
  }
  return result;
}

export function isBackendStep(
  state: ASLState,
  inBackendChain?: boolean
): boolean {
  if (state.Type === "Task") {
    return isBackendResource(state.Resource);
  }
  if (inBackendChain) {
    return true;
  }
  if (state.Type === "Succeed" || state.Type === "Fail") {
    return true;
  }
  return false;
}

export function isFinalStepInManifest(state: ASLState): boolean {
  if (
    state.Type !== "Fail" &&
    state.Type !== "Choice" &&
    state.Type !== "Succeed"
  ) {
    return state.Next == null;
  }
  if (state.Type === "Choice") {
    return !state.Choices.some((choice) => choice.Next !== "");
  }
  return true;
}

export function getNextStepsFromStep(state: ASLState): string[] {
  if (
    state.Type !== "Fail" &&
    state.Type !== "Choice" &&
    state.Type !== "Succeed"
  ) {
    return [state.Next ?? ""];
  }
  if (state.Type === "Choice") {
    return [...state.Choices.map((choice) => choice.Next), state.Default ?? ""];
  }
  return [""];
}

export function getNodesBetween(
  nodes: NodeMap,
  start: INode<INodeData>,
  end: INode<INodeData>
): NodeMap {
  const builder: NodeMap = {};
  const stack = start.next
    .filter(notNull)
    .filter((id) => id !== end.id)
    .map((id) => nodes[id]);
  while (stack.length > 0) {
    const node = stack.pop();
    if (!node) break;
    builder[node.id] = node;
    node.next
      .filter(notNull)
      .filter((id) => id !== end.id)
      .forEach((idOrNull) => {
        stack.push(nodes[idOrNull]);
      });
  }
  return builder;
}

export function excludeFromConversion(node: INode<INodeData>): boolean {
  if (node.type === NodeTypes.EndLoop) {
    return true;
  }
  return false;
}

export function getPreviousNodeOf(node: INode<INodeData>, nodes: NodeMap) {
  return Object.values(nodes).find((aNode) => {
    return aNode.next.includes(node.id);
  });
}

function getExecutionTypeOf(
  node: INode<INodeData>,
  flow: IFlow,
  executionTypes: Map<string, "fe" | "be">
): "fe" | "be" {
  if (!flow.live) throw new Error("Flow is not live");
  const nodes = flow.live.nodes;
  const executionType = getExecutionType(node.data);
  if (executionType === "be") {
    return "be";
  }
  if (executionType === "fe") {
    return "fe";
  }
  if (node.type === "switch") {
    const nextNodes = node.next
      .filter(notNull)
      .map((id) => nodes[id])
      .filter((node) => !excludeFromConversion(node));
    const isFrontendSwitch = nextNodes.some(
      (nextNode) => getExecutionType(nextNode.data) === "fe"
    );
    if (isFrontendSwitch) {
      return "fe";
    }
  }
  if (node.type === "loop") {
    const subFlowNodes = getNodesBetween(
      nodes,
      node,
      nodes[getEndLoopNodeIdFor(node.id)]
    );
    const isFrontendLoop = Object.values(subFlowNodes).some(
      (subFlowNode) => getExecutionType(subFlowNode.data) === "fe"
    );
    if (isFrontendLoop) {
      return "fe";
    }
  }
  const previous = getPreviousNodeOf(node, nodes);
  if (typeof previous === "undefined") {
    if (["fe", "be"].includes(getExecutionType(flow.live.trigger.data))) {
      return getExecutionType(flow.live.trigger.data) as "fe" | "be";
    }
    return "fe";
  }
  if (executionTypes.get(previous.id)) {
    if (
      executionTypes.get(previous.id)! === "be" &&
      ["be", "both"].includes(getExecutionType(flow.live.trigger.data))
    ) {
      return "be";
    }
    return "fe";
  }
  return "fe";
}

export function getExecutionTypes(flow: IFlow): Map<string, "fe" | "be"> {
  if (!flow.live) throw new Error("Flow is not live");
  const executionTypes = new Map<string, "fe" | "be">();
  const { nodes } = flow.live;
  const visited = new Map<INode<INodeData>, boolean>(
    Object.values(nodes).map((node) => [node, false])
  );
  const start = getStartNode(nodes);
  if (!start) {
    return executionTypes;
  }
  const queue: INode<INodeData>[] = [start];
  while (queue.length > 0) {
    const node = queue.shift()!;
    executionTypes.set(node.id, getExecutionTypeOf(node, flow, executionTypes));
    visited.set(node, true);
    queue.push(
      ...node.next
        .filter(notNull)
        .filter((id) => !excludeFromConversion(nodes[id]))
        .filter((id) => !visited.get(nodes[id]))
        .map((id) => nodes[id])
    );
  }
  return executionTypes;
}

export function feResource(actionType: string) {
  return `c2:fe:${actionType}`;
}

export function beResource(actionType: string) {
  return `be:${actionType}`;
}

interface VariableConfig {
  scope: "page" | "app";
  name: string;
}

function getVariablesFromString(value: string): VariableConfig[] {
  const identifierPaths = getIdentifierPaths(value);
  return identifierPaths.flatMap<VariableConfig>((path) => {
    if (path.length === 0 || path.length === 1) {
      return [];
    }
    if (path[0] === "page") {
      return [
        {
          scope: "page",
          name: path[1]
        }
      ];
    } else if (path[0] === "app") {
      return [
        {
          scope: "app",
          name: path[1]
        }
      ];
    }
    return [];
  });
}

export function getVariablesRecursive(
  obj: unknown,
  accumulator: VariableConfig[]
) {
  if (!obj) {
    return;
  }
  if (typeof obj === "string") {
    accumulator.push(...getVariablesFromString(obj));
  } else if (Array.isArray(obj)) {
    obj.forEach((value) => getVariablesRecursive(value, accumulator));
  } else if (typeof obj === "object") {
    Object.values(obj).forEach((value) =>
      getVariablesRecursive(value, accumulator)
    );
  }
}

export function getVariablesFrom(obj?: unknown): VariableConfig[] {
  if (!obj) {
    return [];
  }
  const accumulator: VariableConfig[] = [];
  getVariablesRecursive(obj, accumulator);
  return accumulator;
}

export function getUsedVariables(flow: FlowDefinition): VariableConfig[] {
  const accumulator: VariableConfig[] = [];

  Object.values(flow.States).forEach((state) => {
    if (state.Type === "Map") {
      accumulator.push(...getVariablesFrom(state.Parameters));
      accumulator.push(...getUsedVariables(state.Iterator));
    } else if (state.Type === "Task") {
      accumulator.push(...getVariablesFrom(state.Parameters));
    } else if (state.Type === "Pass") {
      accumulator.push(...getVariablesFrom(state.Result));
      accumulator.push(...getVariablesFrom(state.Parameters));
    } else if (state.Type === "Choice") {
      const cases = state.Choices.map((choice) => choice.Variable);
      accumulator.push(...getVariablesFrom(cases));
    } else if (state.Type === "Fail") {
      accumulator.push(...getVariablesFrom(state.Cause));
    }
  });

  return accumulator;
}
