import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../../types";
import { getEventList, getPropDefs, getProps } from "./utils";

const AreaChart = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonAreaChartDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 6,
      h: 22
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      relative: true,
      "h-30rem": true,
      "w-12": true,
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonAreaChartDef = (): ComponentDefinition => ({
  icon: "fa-kit-duotone fa-area-chart-component",
  category: "charts",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Chart",
    key: "AreaChart",
    events: {},
    props: {
      className: {},
      chartType: "AreaChart"
    },
    propMask: getProps({
      options: {
        title: { text: "Area Chart" }
      }
    }),
    name: "Area Chart",
    children: [],
    uiBlock: ["DefaultComponent", "Chart"]
  },
  propDefs: getPropDefs([]),
  eventList: getEventList([]),
  styleDefs: ["spacing", "size", "text", "border", "background"],
  availableDesignMode: "both",
  privateVariablePropList: ["onLoadCounter"]
});

export default AreaChart;
