import { v4 as uuidv4 } from "uuid";
import type { BlockDefinition } from "../../types";
import { getComponentDefinition } from "..";

export const HeaderFooterLayout = (parentId: string): BlockDefinition => {
  const col1Id = uuidv4();
  const col2Id = uuidv4();
  const col3Id = uuidv4();
  const columnsId = uuidv4();
  const columnComponent = getComponentDefinition("Column", "advanced")!;
  const colBase = columnComponent.base;
  const columnsComponent = getComponentDefinition("Columns", "advanced")!;
  const columnsBase = columnsComponent.base;
  let colClassName = {};
  let colsClassName = {};
  if (typeof colBase.props.className === "object") {
    colClassName = {
      ...colBase.props.className
    };
  } else {
    colClassName = {};
  }

  if (typeof columnsBase.props.className === "object") {
    colsClassName = {
      ...columnsBase.props.className,
      "h-12": "h-12"
    };
  } else {
    colsClassName = {};
  }

  return {
    name: "Header & Footer",
    key: "HeaderFooterLayout",
    type: "block",
    icon: "c2-icons-header-footer-layout",
    category: "layout",
    disabled: false,
    hidden: false,
    availableDesignMode: "advanced",
    components: {
      [columnsId]: {
        ...columnsBase,
        props: {
          className: colsClassName
        },
        parentId,
        id: columnsId,
        children: [
          {
            type: "component",
            value: col1Id
          },
          {
            type: "component",
            value: col2Id
          },
          {
            type: "component",
            value: col3Id
          }
        ]
      },
      [col1Id]: {
        ...colBase,
        props: {
          className: {
            ...colClassName,
            "h-1": "h-1"
          },
          cols: "12"
        },
        id: col1Id,
        parentId: columnsId
      },
      [col2Id]: {
        ...colBase,
        props: {
          className: {
            ...colClassName,
            "h-10": "h-10"
          },
          cols: "12"
        },
        id: col2Id,
        parentId: columnsId
      },
      [col3Id]: {
        ...colBase,
        props: {
          className: {
            ...colClassName,
            "h-1": "h-1"
          },
          cols: "12"
        },
        id: col3Id,
        parentId: columnsId
      }
    }
  };
};
