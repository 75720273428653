import Badge from "./Badge";
import Button from "./Button";
import Calendar from "./Calendar";
import Card from "./Card";
import Checkbox from "./Checkbox";
import Column from "./Column";
import Container from "./Container";
import DataTable from "./DataTable";
import Dropdown from "./Dropdown";
import Editor from "./Editor";
import InputNumber from "./InputNumber";
import InputSwitch from "./InputSwitch";
import InputText from "./InputText";
import InputTextarea from "./InputTextarea";
import Label from "./Label";
import Link from "./Link";
import MultiSelect from "./MultiSelect";
import Password from "./Password";
import RadioButton from "./RadioButton";
import RadioButtonGroup from "./RadioButtonGroup";
import Rating from "./Rating";
import SelectButton from "./SelectButton";
import Sidebar from "./Sidebar";
import Skeleton from "./Skeleton";
import Slider from "./Slider";
import SplitButton from "./SplitButton";
import Tag from "./Tag";
import Title from "./Title";
import ToggleButton from "./ToggleButton";

// charts
import AreaChart from "./charts/AreaChart";
import BarChart from "./charts/BarChart";
import ColumnChart from "./charts/ColumnChart";
import LineChart from "./charts/LineChart";
import PieChart from "./charts/PieChart";
import HeatmapChart from "./charts/HeatmapChart";

import { cloneDeep } from "lodash";
import type {
  BlockDefinition,
  BlockList,
  ComponentDefinition,
  ComponentList
} from "../types";
import Columns from "./Columns";
import Form from "./Form";
import { HeaderFooterLayout } from "./blocks/HeaderFooterLayout";
import { HeaderSidebarFooterLayout } from "./blocks/HeaderSidebarFooterLayout";
import { HeaderSidebarLayout } from "./blocks/HeaderSidebarLayout";
import { SidebarLayout } from "./blocks/SidebarLayout";
import { ThreeColumnLayout } from "./blocks/ThreeColumnLayout";
import { TwoColumnLayout } from "./blocks/TwoColumnLayout";
import CombinedCharts from "./charts/CombinedCharts";
import GridContainer from "./GridContainer";
import type { DesignMode } from "@code2io/fe-engine/dist/types";
import Spacer from "./Spacer";

//const designMode = store.getState().appMeta?.designMode;

const getComponentList = (designMode: DesignMode): ComponentList => ({
  Badge: Badge(designMode),
  Button: Button(designMode),
  Calendar: Calendar(designMode),
  Card,
  Checkbox: Checkbox(designMode),
  Column,
  Container,
  DataTable: DataTable(designMode),
  Dropdown: Dropdown(designMode),
  Editor: Editor(designMode),
  InputNumber: InputNumber(designMode),
  InputSwitch: InputSwitch(designMode),
  InputText: InputText(designMode),
  InputTextarea: InputTextarea(designMode),
  Label: Label(designMode),
  Link: Link(designMode),
  MultiSelect: MultiSelect(designMode),
  Password: Password(designMode),
  RadioButton: RadioButton(designMode),
  RadioButtonGroup: RadioButtonGroup(designMode),
  Rating: Rating(designMode),
  SelectButton: SelectButton(designMode),
  Sidebar,
  Slider: Slider(designMode),
  Skeleton: Skeleton(designMode),
  SplitButton: SplitButton(designMode),
  Tag: Tag(designMode),
  Title: Title(designMode),
  ToggleButton: ToggleButton(designMode),
  Form,
  Columns,
  GridContainer: GridContainer(),
  Spacer: Spacer(),

  // charts
  AreaChart: AreaChart(designMode),
  BarChart: BarChart(designMode),
  ColumnChart: ColumnChart(designMode),
  LineChart: LineChart(designMode),
  PieChart: PieChart(designMode),
  CombinedCharts: CombinedCharts(designMode),
  HeatmapChart: HeatmapChart(designMode)
});

const blockList: BlockList = {
  ThreeColumnLayout,
  TwoColumnLayout,
  SidebarLayout,
  HeaderFooterLayout,
  HeaderSidebarLayout,
  HeaderSidebarFooterLayout
};

const getComponentMap = (designMode: DesignMode) =>
  new Map<string, ComponentDefinition>(
    Object.entries(getComponentList(designMode))
  );

const blockMap = new Map(Object.entries(blockList));

function getComponent(
  name: string,
  designMode: DesignMode
): ComponentDefinition | null {
  const componentMap = getComponentMap(designMode);
  if (componentMap.get(name)) {
    return cloneDeep(componentMap.get(name)!);
  }
  return null;
}

function getBlock(name: string, parentId: string): BlockDefinition | null {
  if (blockMap.get(name)) {
    return cloneDeep(blockMap.get(name)!(parentId));
  }
  return null;
}

export function getComponentDefinition(
  name: string,
  designMode: DesignMode
): ComponentDefinition | null {
  return getComponent(name, designMode);
}

export function getComponentOrBlockDefinition(
  name: string,
  parentId: string,
  designMode: DesignMode
): ComponentDefinition | BlockDefinition | null {
  const component = getComponent(name, designMode) ?? getBlock(name, parentId);
  if (component) {
    return component;
  }
  return null;
}

export function isComponentDefinition(
  def: BlockDefinition | ComponentDefinition
): def is ComponentDefinition {
  return typeof (def as ComponentDefinition).base !== "undefined";
}

export function isBlockDefinition(
  def: BlockDefinition | ComponentDefinition
): def is BlockDefinition {
  return typeof (def as BlockDefinition).components !== "undefined";
}

export { blockList, getComponentList };

export default getComponentList;
