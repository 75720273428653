import merge from "lodash/merge";
import type { Props } from "@code2io/fe-engine/dist/types";
import type {
  ComponentEvents,
  ComponentProps,
  EventDefinition
} from "../../types";
import { PropertyDefinitionCategory } from "../../types";
import { v4 as uuidv4 } from "uuid";
import { getSelectAllVisualSQLQuery } from "../../../features/dataModel/utils";
import type { Table } from "../../../features/dataModel/types";

export const getProps = (props: Props): Props => {
  return merge(
    {
      className: {
        relative: true,
        "h-30rem": true,
        "w-full": true
      },
      containerProps: {
        className: "absolute top-0 right-0 bottom-0 left-0"
      },
      dataColumns: sampleDataColumns,
      sampleValue: sampleDataItems,
      chartVisualSqlQuery: null,
      options: {
        chart: {
          backgroundColor: "",
          style: {
            color: ""
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: ""
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            },
            marker: {
              enabled: true
            }
          }
        },
        series: [],
        subtitle: {
          text: "",
          style: {
            color: ""
          }
        },
        title: {
          text: "",
          style: {
            color: ""
          }
        },
        tooltip: {
          enabled: true
        },
        xAxis: {
          title: {
            text: ""
          }
        },
        yAxis: {
          title: {
            text: ""
          }
        }
      }
    },
    props
  );
};

export const getCombineChartProps = (props: Props): Props => {
  return merge(
    {
      className: {
        relative: true,
        "h-30rem": true,
        "w-full": true
      },
      containerProps: {
        className: "absolute top-0 right-0 bottom-0 left-0"
      },
      dataColumns: sampleCombinedDataColumns,
      sampleValue: sampleDataItems,
      chartVisualSqlQuery: null,
      options: {
        chart: {
          backgroundColor: "",
          style: {
            color: ""
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: ""
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            },
            marker: {
              enabled: true
            }
          }
        },
        series: [],
        subtitle: {
          text: ""
        },
        title: {
          text: ""
        },
        tooltip: {
          enabled: true
        },
        yAxis: [
          {
            id: uuidv4(),
            title: {
              text: "Left"
            },
            opposite: false
          },
          {
            id: uuidv4(),
            title: {
              text: "Right"
            },
            opposite: true
          }
        ],
        xAxis: {
          title: {
            text: ""
          }
        }
      }
    },
    props
  );
};

export const getPropDefs = (
  propDefs: ComponentProps,
  options: { excludedKeys?: string[] } = { excludedKeys: [] }
): ComponentProps => {
  const result: ComponentProps = [
    {
      name: "options.title.text",
      displayName: "Title",
      key: "options.title.text",
      category: PropertyDefinitionCategory.BASIC_SETTINGS,
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "Chart",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.subtitle.text",
      displayName: "Subtitle",
      key: "options.subtitle.text",
      category: PropertyDefinitionCategory.BASIC_SETTINGS,
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.yAxis.title.text",
      displayName: "Y Axis Title",
      key: "options.yAxis.title.text",
      category: PropertyDefinitionCategory.BASIC_SETTINGS,
      type: "none",
      render: {
        component: "none",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "dataSource",
      displayName: "Data Source",
      key: "dataSource",
      category: PropertyDefinitionCategory.DATA,
      type: "TableSelector",
      render: {
        component: "TableSelector",
        initialValue: null,
        valueMap: {}
      },
      onChange: (value: Table | string | null): unknown => {
        if (typeof value === "undefined" || value === null) {
          return {
            dataSource: null,
            chartVisualSqlQuery: null
          };
        }
        if (typeof value === "string") {
          return {
            dataSource: value,
            chartVisualSqlQuery: null
          };
        }
        return {
          dataSource: value,
          chartVisualSqlQuery: getSelectAllVisualSQLQuery(value, {
            limit: 50
          })
        };
      }
    },
    {
      name: "colMapping",
      displayName: "Column Mapping",
      key: "colMapping",
      category: PropertyDefinitionCategory.DATA,
      type: "ChartColumnMapping",
      render: {
        component: "ChartColumnMapping",
        initialValue: "",
        valueMap: {}
      }
    },
    {
      name: "chartVisualSqlQuery",
      displayName: "Query",
      key: "chartVisualSqlQuery",
      category: PropertyDefinitionCategory.DATA,
      type: "VisualSQLEditorFlowProperty",
      render: {
        component: "VisualSQLEditorFlowProperty",
        initialValue: null,
        valueMap: {}
      }
    },
    ...propDefs,
    {
      name: "options.chart.style.color",
      displayName: "Font Color",
      key: "options.chart.style.color",
      category: PropertyDefinitionCategory.COLORS,
      type: "ColorSelector",
      render: {
        component: "ColorSelector",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.chart.backgroundColor",
      displayName: "Background Color",
      key: "options.chart.backgroundColor",
      category: PropertyDefinitionCategory.COLORS,
      type: "ColorSelector",
      render: {
        component: "ColorSelector",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "colors",
      displayName: "Color Set",
      key: "colors",
      category: PropertyDefinitionCategory.COLORS,
      type: "ColorPaletteSelector",
      render: {
        component: "ColorPaletteSelector",
        initialValue: [
          "var(--indigo-500)",
          "var(--blue-500)",
          "var(--cyan-500)",
          "var(--pink-500)",
          "var(--orange-500)",
          "var(--green-500)",
          "var(--yellow-500)",
          "var(--red-500)",
          "var(--purple-500)",
          "var(--teal-500)"
        ],
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.plotOptions.series.marker.enabled",
      displayName: "Show Markers",
      key: "options.plotOptions.series.marker.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "options.plotOptions.series.dataLabels.enabled",
      displayName: "Show Labels",
      key: "options.plotOptions.series.dataLabels.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "options.legend.enabled",
      displayName: "Show Legend",
      key: "options.legend.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "options.tooltip.enabled",
      displayName: "Show Tooltip",
      key: "options.tooltip.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "loading",
      displayName: "Loading",
      key: "loading",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    }
  ];

  return result.filter(
    (propDef) => !options.excludedKeys?.includes(propDef.key)
  );
};

export const getCombinedChartPropDefs = (
  propDefs: ComponentProps,
  options: { excludedKeys?: string[] } = { excludedKeys: [] }
): ComponentProps => {
  const result: ComponentProps = [
    {
      name: "options.title.text",
      displayName: "Title",
      key: "options.title.text",
      category: PropertyDefinitionCategory.BASIC_SETTINGS,
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "Chart",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.subtitle.text",
      displayName: "Subtitle",
      key: "options.subtitle.text",
      category: PropertyDefinitionCategory.BASIC_SETTINGS,
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.xAxis.title.text",
      displayName: "X Axis Title",
      key: "options.xAxis.title.text",
      type: "expr",
      category: PropertyDefinitionCategory.BASIC_SETTINGS,
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "dataSource",
      displayName: "Data Source",
      key: "dataSource",
      category: PropertyDefinitionCategory.DATA,
      type: "TableSelector",
      render: {
        component: "TableSelector",
        initialValue: null,
        valueMap: {}
      },
      onChange: (value: Table | string | null): unknown => {
        if (typeof value === "undefined" || value === null) {
          return {
            dataSource: null,
            chartVisualSqlQuery: null
          };
        }
        if (typeof value === "string") {
          return {
            dataSource: value,
            chartVisualSqlQuery: null
          };
        }
        return {
          dataSource: value,
          chartVisualSqlQuery: getSelectAllVisualSQLQuery(value, {
            limit: 50
          })
        };
      }
    },
    {
      name: "colMapping",
      displayName: "Column Mapping",
      key: "colMapping",
      category: PropertyDefinitionCategory.DATA,
      type: "ChartColumnMapping",
      render: {
        component: "ChartColumnMapping",
        initialValue: "",
        valueMap: {}
      }
    },
    {
      name: "chartVisualSqlQuery",
      displayName: "Query",
      key: "chartVisualSqlQuery",
      category: PropertyDefinitionCategory.DATA,
      type: "VisualSQLEditorFlowProperty",
      render: {
        component: "VisualSQLEditorFlowProperty",
        initialValue: null,
        valueMap: {}
      }
    },

    ...propDefs,

    {
      name: "options.chart.style.color",
      displayName: "Font Color",
      key: "options.chart.style.color",
      category: PropertyDefinitionCategory.COLORS,
      type: "ColorSelector",
      render: {
        component: "ColorSelector",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.chart.backgroundColor",
      displayName: "Background Color",
      key: "options.chart.backgroundColor",
      category: PropertyDefinitionCategory.COLORS,
      type: "ColorSelector",
      render: {
        component: "ColorSelector",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "colors",
      displayName: "Color Set",
      key: "colors",
      category: PropertyDefinitionCategory.COLORS,
      type: "ColorPaletteSelector",
      render: {
        component: "ColorPaletteSelector",
        initialValue: [
          "var(--indigo-500)",
          "var(--blue-500)",
          "var(--cyan-500)",
          "var(--pink-500)",
          "var(--orange-500)",
          "var(--green-500)",
          "var(--yellow-500)",
          "var(--red-500)",
          "var(--purple-500)",
          "var(--teal-500)"
        ],
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "options.plotOptions.series.marker.enabled",
      displayName: "Show Markers",
      key: "options.plotOptions.series.marker.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "options.plotOptions.series.dataLabels.enabled",
      displayName: "Show Labels",
      key: "options.plotOptions.series.dataLabels.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "options.legend.enabled",
      displayName: "Show Legend",
      key: "options.legend.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "options.tooltip.enabled",
      displayName: "Show Tooltip",
      key: "options.tooltip.enabled",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "loading",
      displayName: "Loading",
      key: "loading",
      category: PropertyDefinitionCategory.OTHER_SETTINGS,
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    }
  ];

  return result.filter(
    (propDef) => !options.excludedKeys?.includes(propDef.key)
  );
};

export const getEventList = (eventList: ComponentEvents): EventDefinition[] => {
  return [
    {
      name: "onLoad",
      readableName: "On Load",
      description: "Fires when table initialized"
    },
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when label is clicked"
    },
    ...eventList
  ];
};

export function getSampleChartColumns(type: string) {
  if (type === "CombinedCharts") {
    return sampleCombinedDataColumns;
  }
  if (type === "HeatmapChart") {
    return sampleHeatmapChartColumns;
  }
  return sampleDataColumns;
}

export function getSampleChartDataItems(type: string) {
  if (type === "HeatmapChart") {
    return sampleHeatmapDataItems;
  }
  return sampleDataItems;
}

const sampleHeatmapChartColumns = [
  {
    field: "day",
    header: "Day",
    show: true,
    type: "VARCHAR",
    axis: "y"
  },
  {
    field: "employee",
    header: "Employee",
    show: true,
    type: "VARCHAR",
    axis: "x"
  },
  {
    field: "sales",
    header: "Sales",
    show: true,
    type: "BIGINT",
    axis: "value"
  }
];
export const sampleCombinedDataColumns = [
  {
    field: "date",
    header: "Date",
    show: true,
    type: "date",
    chartType: ""
  },
  {
    field: "freeCashFlow",
    header: "Free Cash Flow",
    show: true,
    type: "BIGINT",
    chartType: "column"
  },
  {
    field: "netProfit",
    header: "Net Profit",
    show: true,
    type: "BIGINT",
    chartType: "spline",
    yAxis: 0
  },
  {
    field: "revenue",
    header: "Revenue",
    show: true,
    type: "BIGINT",
    chartType: "spline",
    yAxis: 1
  }
];

export const sampleDataColumns = [
  {
    field: "date",
    header: "Date",
    show: true,
    type: "DATETIME"
  },
  {
    field: "netProfit",
    header: "Net Profit",
    show: true,
    type: "BIGINT"
  },
  {
    field: "revenue",
    header: "Revenue",
    show: true,
    type: "BIGINT"
  },
  {
    field: "freeCashFlow",
    header: "Free Cash Flow",
    show: true,
    type: "BIGINT"
  }
];

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const salespeople = ["Alexander", "Marie", "Maximillian", "Sophia", "Lukas"];
const sales = [
  10, 19, 8, 24, 67, 92, 58, 78, 117, 48, 35, 15, 123, 64, 52, 72, 132, 114, 19,
  16, 38, 5, 8, 117, 115
];
const data = days
  .map((day, i) => {
    return salespeople.map((person, j) => {
      const numOfDays = days.length;
      return {
        day,
        employee: person,
        sales: sales[i * numOfDays + j]
      };
    });
  })
  .flat();

export const sampleHeatmapDataItems = data;

export const sampleDataItems = [
  {
    date: "2021-04-08",
    netProfit: 44,
    revenue: 76,
    freeCashFlow: 35
  },
  {
    date: "2021-04-09",
    netProfit: 55,
    revenue: 85,
    freeCashFlow: 41
  },
  {
    date: "2021-04-10",
    netProfit: 57,
    revenue: 101,
    freeCashFlow: 36
  },
  {
    date: "2021-04-11",
    netProfit: 56,
    revenue: 98,
    freeCashFlow: 26
  },
  {
    date: "2021-04-12",
    netProfit: 61,
    revenue: 87,
    freeCashFlow: 45
  },
  {
    date: "2021-04-13",
    netProfit: 58,
    revenue: 105,
    freeCashFlow: 48
  },
  {
    date: "2021-04-14",
    netProfit: 63,
    revenue: 91,
    freeCashFlow: 52
  },
  {
    date: "2021-04-15",
    netProfit: 60,
    revenue: 114,
    freeCashFlow: 53
  },
  {
    date: "2021-04-16",
    netProfit: 66,
    revenue: 94,
    freeCashFlow: 41
  }
];
