import type { ICollaborativeOperation } from "../../app/services/collaboration";

export interface CollaborativeOperationsCommand {
  type: "op";
  payload: {
    collectionId: string;
    documentId: string;
    ops: ICollaborativeOperation[];
  };
}

export interface CreateCollaborativeDocumentCommand {
  type: "create";
  payload: {
    collectionId: string;
    documentId: string;
    data: unknown;
  };
}

export interface DeleteCollaborativeDocumentCommand {
  type: "delete";
  payload: {
    collectionId: string;
    documentId: string;
    data: unknown;
  };
}

export type Command =
  | CollaborativeOperationsCommand
  | CreateCollaborativeDocumentCommand
  | DeleteCollaborativeDocumentCommand;

export function isOpCommand(
  command: Command
): command is CollaborativeOperationsCommand {
  return command.type === "op";
}

export function isCreateCommand(
  command: Command
): command is CreateCollaborativeDocumentCommand {
  return command.type === "create";
}

export function isDeleteCommand(
  command: Command
): command is DeleteCollaborativeDocumentCommand {
  return command.type === "delete";
}
