import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Link = (designMode: DesignMode): ComponentDefinition =>
  getLinkDef(designMode);

const getLinkDef = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonLinkDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 1,
      h: 2
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.styleDefs = [
      "spacing",
      "size",
      "typography",
      "background",
      "effects",
      "border"
    ];
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true,
      "pt-2": true,
      "pb-2": true,
      "pl-2": true,
      "pr-2": true
    };
  }
  return commonDef;
};

const getCommonLinkDef = (): ComponentDefinition => ({
  icon: "fa-solid fa-link text-xl",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "Link",
    key: "Link",
    events: {},
    props: {
      className: {},
      linkProps: {
        type: "internal",
        to: "home",
        label: "New Link",
        openIn: "sameTab"
      }
    },
    propMask: {
      linkProps: {
        type: "internal",
        to: "home",
        label: "New Link",
        openIn: "sameTab"
      }
    },
    name: "Link",
    children: [],
    uiBlock: ["DefaultComponent"]
  },
  propDefs: [
    {
      name: "LinkPropertyEditor",
      displayName: "LinkPropertyEditor",
      key: "linkProps",
      type: "LinkPropertyEditor",
      render: {
        component: "LinkPropertyEditor",
        initialValue: "",
        valueMap: {}
      }
    }
  ],
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when link is clicked"
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default Link;
