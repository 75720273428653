import mixpanel from "mixpanel-browser";
import { EnvironmentConstants } from "../../environmentConstants";
import { getDynamicConfigValue } from "./dynamicConfig";
import type { User } from "../../features/auth/authSlice";

export const sendMixpanelEvent = (...args: any[]) => {
  if (getDynamicConfigValue(EnvironmentConstants.MIXPANEL_ENABLED) == "true") {
    const eventName = args.shift();
    mixpanel.track(eventName, ...args);
  }
};

export const identifyMXPanel = (user: User) => {
  if (getDynamicConfigValue(EnvironmentConstants.MIXPANEL_ENABLED) == "true") {
    mixpanel.identify(user.email);

    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName ?? ""}`,
      $email: user.email
    });
  }
};
