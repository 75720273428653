import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Label = (designMode: DesignMode): ComponentDefinition =>
  getLabelDefs(designMode);

const getCommonLabelDefs = (): ComponentDefinition => ({
  icon: "fa-kit fa-label-component",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "Label",
    key: "Label",
    events: {},
    props: {
      className: {}
    },
    propMask: {
      value: "New Label"
    },
    name: "Label",
    children: [],
    uiBlock: ["DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Content",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "New label",
        valueMap: {}
      }
    }
  ],
  eventList: [
    {
      name: "onClick",
      readableName: "On Change",
      description: "Fires when label is clicked"
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

const getLabelDefs = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonLabelDefs();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 2,
      h: 2
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true,
      "pt-2": true,
      "pb-2": true,
      "pl-2": true,
      "pr-2": true
    };
  }
  return commonDef;
};

export default Label;
