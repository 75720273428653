import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const InputNumber = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonInputNumberDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 5
    };
    commonDef.styleDefs = ["effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true
    };
  }
  return commonDef;
};

const getCommonInputNumberDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-input-number-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "InputNumber",
    events: {},
    props: {
      c2FieldType: "InputNumber",
      format: true,
      inputStyle: { textAlign: "inherit" },
      className: {}
    },
    propMask: {},
    name: "Input Number",
    children: [],
    uiBlock: ["InputNumber", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: null,
        valueMap: {}
      },
      validateResultAsType: ["number", "null"]
    },
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} },
      validateResultAsType: "string"
    },
    {
      name: "labelPlacement",
      displayName: "Label Placement",
      key: "labelPlacement",
      type: "SelectButton",
      render: {
        component: "SelectButton",
        options: [
          { label: "Vertical", value: "vertical" },
          { label: "Horizontal", value: "horizontal" }
        ],
        initialValue: "horizontal",
        valueMap: {}
      }
    },
    {
      name: "hint",
      displayName: "Hint",
      key: "hint",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} },
      validateResultAsType: "string"
    },
    {
      name: "placeholder",
      displayName: "Placeholder",
      key: "placeholder",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} },
      validateResultAsType: "string"
    },
    {
      name: "format",
      displayName: "Format",
      key: "format",
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "min",
      displayName: "Min Value",
      key: "min",
      type: "input",
      render: { component: "inputtext", initialValue: null, valueMap: {} },
      validateResultAsType: ["number", "null"]
    },
    {
      name: "max",
      displayName: "Max Value",
      key: "max",
      type: "input",
      render: { component: "inputtext", initialValue: null, valueMap: {} },
      validateResultAsType: ["number", "null"]
    },
    {
      name: "minFractionDigits",
      displayName: "Min Fraction Digits",
      key: "minFractionDigits",
      type: "select",
      render: {
        component: "dropdown",
        options: Array.from({ length: 21 }).map((e, i) => ({
          label: `${i}`,
          value: `${i}`
        })) as { label: string; value: string }[],
        initialValue: "0",
        valueMap: {}
      },
      validateResultAsType: "number"
    },
    {
      name: "maxFractionDigits",
      displayName: "Max Fraction Digits",
      key: "maxFractionDigits",
      type: "select",
      render: {
        component: "dropdown",
        options: Array.from({ length: 21 }).map((e, i) => ({
          label: `${i}`,
          value: `${i}`
        })) as { label: string; value: string }[],
        initialValue: "0",
        valueMap: {}
      },
      validateResultAsType: "number"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    },
    {
      name: "onFocus",
      readableName: "On Focus",
      description: "Fires when input receives focus."
    },
    {
      name: "onBlur",
      readableName: "On Blur",
      description: "Fires when input loses focus."
    },
    {
      name: "onKeyDown",
      readableName: "On Key Down",
      description: "Fires when a key pressed."
    }
  ],
  styleDefs: ["spacing", "size", "effects", "border"],
  availableDesignMode: "both"
});

export default InputNumber;
