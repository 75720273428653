import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const SelectButton = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonSelectButtonDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 2,
      h: 5
    };
    commonDef.styleDefs = ["effects"];
  } else {
    commonDef.base.props.className = {
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonSelectButtonDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-select-buttons-component",
  category: "buttons",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "SelectButton",
    events: {},
    props: {
      c2FieldType: "SelectButton",
      className: {}
    },
    propMask: {
      multiple: false,
      options: [
        { label: "On", value: "On" },
        { label: "Off", value: "Off" }
      ],
      unselectable: true
    },
    name: "Select Button",
    children: [],
    uiBlock: ["SelectButton", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      }
    },
    {
      name: "options",
      displayName: "Options",
      key: "options",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: `{{ [{ label: "On", value: "On" }, { label: "Off", value: "Off" }] }}`,
        valueMap: {}
      },
      validateResultAsType: "array"
    },
    {
      name: "optionLabel",
      displayName: "Option Label",
      key: "optionLabel",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "label",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "optionValue",
      displayName: "Option Value",
      key: "optionValue",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "value",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "hint",
      displayName: "Hint",
      key: "hint",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "multiple",
      displayName: "Multiple",
      key: "multiple",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "unselectable",
      displayName: "Unselectable",
      key: "unselectable",
      type: "switch",
      render: { component: "inputswitch", initialValue: true, valueMap: {} },
      validateResultAsType: "boolean"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: ["spacing", "effects"],
  availableDesignMode: "both"
});

export default SelectButton;
