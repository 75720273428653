import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Tag = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonTagDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 1,
      h: 2
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonTagDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-tag-component",
  category: "dataview",
  disabled: false,
  base: {
    package: "primereact",
    type: "Tag",
    key: "Tag",
    events: {},
    props: {
      className: {}
    },
    propMask: {
      rounded: false,
      severity: "",
      value: "Tag"
    },
    name: "Tag",
    children: [],
    uiBlock: ["DefaultComponent"]
  },
  propDefs: [
    {
      name: "rounded",
      displayName: "Rounded",
      key: "rounded",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "value",
      displayName: "Label",
      key: "value",
      type: "input",
      render: {
        component: "inputtext",
        initialValue: "Tag",
        valueMap: {}
      }
    },
    {
      name: "severity",
      displayName: "Severity",
      key: "severity",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          { label: "Primary", value: "" },
          { label: "Success", value: "success" },
          { label: "Info", value: "info" },
          { label: "Warning", value: "warning" },
          { label: "Danger", value: "danger" }
        ],
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    }
  ],
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when button is clicked"
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

export default Tag;
