import type { ComponentDefinition } from "../types";

const GridContainer = (): ComponentDefinition => {
  return {
    icon: "",
    category: "layout",
    disabled: true,
    hidden: true,
    base: {
      package: "code2",
      type: "GridContainer",
      key: "GridContainer",
      events: {},
      props: {
        className: {
          "flex-column": "flex-column",
          "flex-nowrap": true,
          "align-items-start": "align-items-start",
          "border-round": "border-round",
          "border-1": "border-1",
          "border-dashed": "border-dashed",
          "border-bluegray-200": "border-bluegray-200",
          "pt-2": "pt-2",
          "pl-2": "pl-2",
          "pb-2": "pb-2",
          "pr-2": "pr-2",
          "bg-white-50": true,
          "max-w-12": "max-w-12"
        }
      },
      propMask: {},
      name: "GridContainer",
      children: [],
      uiBlock: ["GridContainer"]
    },
    propDefs: [
      {
        name: "cols",
        displayName: "Column Count",
        key: "cols",
        type: "input",
        render: {
          component: "inputnumber",
          initialValue: 12,
          valueMap: {}
        },
        validateResultAsType: "number",
        isPropObj: true
      }
    ],
    eventList: [],
    styleDefs: ["border", "background", "effects", "overflow"],
    availableDesignMode: "basic"
  };
};

export default GridContainer;
