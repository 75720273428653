import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../../types";
import {
  getCombineChartProps,
  getCombinedChartPropDefs,
  getEventList
} from "./utils";

const CombinedCharts = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonCombinedChartsDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 6,
      h: 22
    };
    commonDef.styleDefs = ["text", "border", "background"];
  } else {
    commonDef.base.props.className = {
      relative: true,
      "h-30rem": true,
      "w-12": true,
      "mt-2": true,
      "mb-2": true
    };
  }
  return commonDef;
};

const getCommonCombinedChartsDef = (): ComponentDefinition => ({
  icon: "c2-icons-combined-chart",
  category: "charts",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Chart",
    key: "CombinedCharts",
    events: {},
    props: {
      className: {},
      chartType: "CombinedCharts"
    },
    propMask: getCombineChartProps({
      options: {
        title: { text: "Combined Chart" }
      }
    }),
    name: "Combined Chart",
    children: [],
    uiBlock: ["DefaultComponent", "Chart"]
  },
  propDefs: getCombinedChartPropDefs([]),
  eventList: getEventList([]),
  styleDefs: ["spacing", "size", "text", "border", "background"],
  availableDesignMode: "both",
  privateVariablePropList: ["onLoadCounter"]
});

export default CombinedCharts;
