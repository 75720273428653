import type { ComponentDefinition } from "../types";

const Container: ComponentDefinition = {
  icon: "fa-kit fa-container-component",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Container",
    key: "Container",
    events: {},
    props: {
      className: {
        flex: true,
        "w-12": true,
        "pt-3": true,
        "pl-3": true,
        "pb-3": true,
        "pr-3": true,
        "border-round-md": true,
        "border-1": true,
        "border-solid": true,
        "border-white-300": true,
        "gap-2": true,
        "min-h-6": true,
        "flex-wrap": true,
        "align-items-start": true,
        "flex-row": true,
        "mt-1": true,
        "mb-1": true,
        "bg-white-50": true
      }
    },
    propMask: {},
    name: "Container",
    children: []
  },
  eventList: [
    {
      name: "onMouseEnter",
      readableName: "On Mouse Enter",
      description: "Fires when mouse enters the container"
    },
    {
      name: "onMouseLeave",
      readableName: "On Mouse Leave",
      description: "Fires when mouse leave the container"
    }
  ],
  propDefs: [],
  styleDefs: [
    "layout",
    "spacing",
    "size",
    "overflow",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "advanced"
};

export default Container;
