import type { ComponentDefinition } from "../types";

const Form: ComponentDefinition = {
  icon: "c2-icons-container",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Form",
    key: "Form",
    events: {},
    props: {
      className: {
        flex: true,
        "w-12": true,
        "border-round-md": true,
        "gap-2": true,
        "min-h-6": true,
        "flex-wrap": true,
        "align-items-start": true,
        "flex-column": true,
        "pb-2": true,
        "pt-2": true,
        "pr-2": true,
        "pl-2": true,
        "mt-1": true,
        "mb-1": true,
        "border-1": true,
        "border-solid": true,
        "border-white-300": true
      }
    },
    propMask: {},
    name: "Form",
    children: [],
    uiBlock: ["Form", "DefaultComponent"]
  },
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires when form is changed"
    }
  ],
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "none",
      render: {
        component: "none",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    }
  ],
  styleDefs: [
    "layout",
    "spacing",
    "size",
    "overflow",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "advanced"
};

export default Form;
