export const iconOptions = [
  { label: "Sort Alt Slash", value: "pi pi-sort-alt-slash" },
  { label: "Arrows H", value: "pi pi-arrows-h" },
  { label: "Arrows V", value: "pi pi-arrows-v" },
  { label: "Pound", value: "pi pi-pound" },
  { label: "Prime", value: "pi pi-prime" },
  { label: "Chart Pie", value: "pi pi-chart-pie" },
  { label: "Reddit", value: "pi pi-reddit" },
  { label: "Code", value: "pi pi-code" },
  { label: "Sync", value: "pi pi-sync" },
  { label: "Shopping Bag", value: "pi pi-shopping-bag" },
  { label: "Server", value: "pi pi-server" },
  { label: "Database", value: "pi pi-database" },
  { label: "Hashtag", value: "pi pi-hashtag" },
  { label: "Bookmark Fill", value: "pi pi-bookmark-fill" },
  { label: "Filter Fill", value: "pi pi-filter-fill" },
  { label: "Heart Fill", value: "pi pi-heart-fill" },
  { label: "Flag Fill", value: "pi pi-flag-fill" },
  { label: "Circle", value: "pi pi-circle" },
  { label: "Circle Fill", value: "pi pi-circle-fill" },
  { label: "Bolt", value: "pi pi-bolt" },
  { label: "History", value: "pi pi-history" },
  { label: "Box", value: "pi pi-box" },
  { label: "At", value: "pi pi-at" },
  { label: "Arrow Up Right", value: "pi pi-arrow-up-right" },
  { label: "Arrow Up Left", value: "pi pi-arrow-up-left" },
  { label: "Arrow Down Left", value: "pi pi-arrow-down-left" },
  { label: "Arrow Down Right", value: "pi pi-arrow-down-right" },
  { label: "Telegram", value: "pi pi-telegram" },
  { label: "Stop Circle", value: "pi pi-stop-circle" },
  { label: "Stop", value: "pi pi-stop" },
  { label: "Whatsapp", value: "pi pi-whatsapp" },
  { label: "Building", value: "pi pi-building" },
  { label: "Qrcode", value: "pi pi-qrcode" },
  { label: "Car", value: "pi pi-car" },
  { label: "Instagram", value: "pi pi-instagram" },
  { label: "Linkedin", value: "pi pi-linkedin" },
  { label: "Send", value: "pi pi-send" },
  { label: "Slack", value: "pi pi-slack" },
  { label: "Moon", value: "pi pi-moon" },
  { label: "Sun", value: "pi pi-sun" },
  { label: "Youtube", value: "pi pi-youtube" },
  { label: "Vimeo", value: "pi pi-vimeo" },
  { label: "Flag", value: "pi pi-flag" },
  { label: "Wallet", value: "pi pi-wallet" },
  { label: "Map", value: "pi pi-map" },
  { label: "Link", value: "pi pi-link" },
  { label: "Credit Card", value: "pi pi-credit-card" },
  { label: "Discord", value: "pi pi-discord" },
  { label: "Percentage", value: "pi pi-percentage" },
  { label: "Euro", value: "pi pi-euro" },
  { label: "Book", value: "pi pi-book" },
  { label: "Shield", value: "pi pi-shield" },
  { label: "Paypal", value: "pi pi-paypal" },
  { label: "Amazon", value: "pi pi-amazon" },
  { label: "Phone", value: "pi pi-phone" },
  { label: "Filter Slash", value: "pi pi-filter-slash" },
  { label: "Facebook", value: "pi pi-facebook" },
  { label: "Github", value: "pi pi-github" },
  { label: "Twitter", value: "pi pi-twitter" },
  { label: "Step Backward Alt", value: "pi pi-step-backward-alt" },
  { label: "Step Forward Alt", value: "pi pi-step-forward-alt" },
  { label: "Forward", value: "pi pi-forward" },
  { label: "Backward", value: "pi pi-backward" },
  { label: "Fast Backward", value: "pi pi-fast-backward" },
  { label: "Fast Forward", value: "pi pi-fast-forward" },
  { label: "Pause", value: "pi pi-pause" },
  { label: "Play", value: "pi pi-play" },
  { label: "Compass", value: "pi pi-compass" },
  { label: "Id Card", value: "pi pi-id-card" },
  { label: "Ticket", value: "pi pi-ticket" },
  { label: "File O", value: "pi pi-file-o" },
  { label: "Reply", value: "pi pi-reply" },
  { label: "Directions Alt", value: "pi pi-directions-alt" },
  { label: "Directions", value: "pi pi-directions" },
  { label: "Thumbs Up", value: "pi pi-thumbs-up" },
  { label: "Thumbs Down", value: "pi pi-thumbs-down" },
  { label: "Sort Numeric Down Alt", value: "pi pi-sort-numeric-down-alt" },
  { label: "Sort Numeric Up Alt", value: "pi pi-sort-numeric-up-alt" },
  { label: "Sort Alpha Down Alt", value: "pi pi-sort-alpha-down-alt" },
  { label: "Sort Alpha Up Alt", value: "pi pi-sort-alpha-up-alt" },
  { label: "Sort Numeric Down", value: "pi pi-sort-numeric-down" },
  { label: "Sort Numeric Up", value: "pi pi-sort-numeric-up" },
  { label: "Sort Alpha Down", value: "pi pi-sort-alpha-down" },
  { label: "Sort Alpha Up", value: "pi pi-sort-alpha-up" },
  { label: "Sort Alt", value: "pi pi-sort-alt" },
  { label: "Sort Amount Up", value: "pi pi-sort-amount-up" },
  { label: "Sort Amount Down", value: "pi pi-sort-amount-down" },
  { label: "Sort Amount Down Alt", value: "pi pi-sort-amount-down-alt" },
  { label: "Sort Amount Up Alt", value: "pi pi-sort-amount-up-alt" },
  { label: "Palette", value: "pi pi-palette" },
  { label: "Undo", value: "pi pi-undo" },
  { label: "Desktop", value: "pi pi-desktop" },
  { label: "Sliders V", value: "pi pi-sliders-v" },
  { label: "Sliders H", value: "pi pi-sliders-h" },
  { label: "Search Plus", value: "pi pi-search-plus" },
  { label: "Search Minus", value: "pi pi-search-minus" },
  { label: "File Excel", value: "pi pi-file-excel" },
  { label: "File Pdf", value: "pi pi-file-pdf" },
  { label: "Check Square", value: "pi pi-check-square" },
  { label: "Chart Line", value: "pi pi-chart-line" },
  { label: "User Edit", value: "pi pi-user-edit" },
  { label: "Exclamation Circle", value: "pi pi-exclamation-circle" },
  { label: "Android", value: "pi pi-android" },
  { label: "Google", value: "pi pi-google" },
  { label: "Apple", value: "pi pi-apple" },
  { label: "Microsoft", value: "pi pi-microsoft" },
  { label: "Heart", value: "pi pi-heart" },
  { label: "Mobile", value: "pi pi-mobile" },
  { label: "Tablet", value: "pi pi-tablet" },
  { label: "Key", value: "pi pi-key" },
  { label: "Shopping Cart", value: "pi pi-shopping-cart" },
  { label: "Comments", value: "pi pi-comments" },
  { label: "Comment", value: "pi pi-comment" },
  { label: "Briefcase", value: "pi pi-briefcase" },
  { label: "Bell", value: "pi pi-bell" },
  { label: "Paperclip", value: "pi pi-paperclip" },
  { label: "Share Alt", value: "pi pi-share-alt" },
  { label: "Envelope", value: "pi pi-envelope" },
  { label: "Volume Down", value: "pi pi-volume-down" },
  { label: "Volume Up", value: "pi pi-volume-up" },
  { label: "Volume Off", value: "pi pi-volume-off" },
  { label: "Eject", value: "pi pi-eject" },
  { label: "Money Bill", value: "pi pi-money-bill" },
  { label: "Images", value: "pi pi-images" },
  { label: "Image", value: "pi pi-image" },
  { label: "Sign In", value: "pi pi-sign-in" },
  { label: "Sign Out", value: "pi pi-sign-out" },
  { label: "Wifi", value: "pi pi-wifi" },
  { label: "Sitemap", value: "pi pi-sitemap" },
  { label: "Chart Bar", value: "pi pi-chart-bar" },
  { label: "Camera", value: "pi pi-camera" },
  { label: "Dollar", value: "pi pi-dollar" },
  { label: "Lock Open", value: "pi pi-lock-open" },
  { label: "Table", value: "pi pi-table" },
  { label: "Map Marker", value: "pi pi-map-marker" },
  { label: "List", value: "pi pi-list" },
  { label: "Eye Slash", value: "pi pi-eye-slash" },
  { label: "Eye", value: "pi pi-eye" },
  { label: "Folder Open", value: "pi pi-folder-open" },
  { label: "Folder", value: "pi pi-folder" },
  { label: "Video", value: "pi pi-video" },
  { label: "Inbox", value: "pi pi-inbox" },
  { label: "Lock", value: "pi pi-lock" },
  { label: "Unlock", value: "pi pi-unlock" },
  { label: "Tags", value: "pi pi-tags" },
  { label: "Tag", value: "pi pi-tag" },
  { label: "Power Off", value: "pi pi-power-off" },
  { label: "Save", value: "pi pi-save" },
  { label: "Question Circle", value: "pi pi-question-circle" },
  { label: "Question", value: "pi pi-question" },
  { label: "Copy", value: "pi pi-copy" },
  { label: "File", value: "pi pi-file" },
  { label: "Clone", value: "pi pi-clone" },
  { label: "Calendar Times", value: "pi pi-calendar-times" },
  { label: "Calendar Minus", value: "pi pi-calendar-minus" },
  { label: "Calendar Plus", value: "pi pi-calendar-plus" },
  { label: "Ellipsis V", value: "pi pi-ellipsis-v" },
  { label: "Ellipsis H", value: "pi pi-ellipsis-h" },
  { label: "Bookmark", value: "pi pi-bookmark" },
  { label: "Globe", value: "pi pi-globe" },
  { label: "Replay", value: "pi pi-replay" },
  { label: "Filter", value: "pi pi-filter" },
  { label: "Print", value: "pi pi-print" },
  { label: "Align Right", value: "pi pi-align-right" },
  { label: "Align Left", value: "pi pi-align-left" },
  { label: "Align Center", value: "pi pi-align-center" },
  { label: "Align Justify", value: "pi pi-align-justify" },
  { label: "Cog", value: "pi pi-cog" },
  { label: "Cloud Download", value: "pi pi-cloud-download" },
  { label: "Cloud Upload", value: "pi pi-cloud-upload" },
  { label: "Cloud", value: "pi pi-cloud" },
  { label: "Pencil", value: "pi pi-pencil" },
  { label: "Users", value: "pi pi-users" },
  { label: "Clock", value: "pi pi-clock" },
  { label: "User Minus", value: "pi pi-user-minus" },
  { label: "User Plus", value: "pi pi-user-plus" },
  { label: "Trash", value: "pi pi-trash" },
  { label: "Window Minimize", value: "pi pi-window-minimize" },
  { label: "Window Maximize", value: "pi pi-window-maximize" },
  { label: "External Link", value: "pi pi-external-link" },
  { label: "Refresh", value: "pi pi-refresh" },
  { label: "User", value: "pi pi-user" },
  { label: "Exclamation Triangle", value: "pi pi-exclamation-triangle" },
  { label: "Calendar", value: "pi pi-calendar" },
  { label: "Chevron Circle Left", value: "pi pi-chevron-circle-left" },
  { label: "Chevron Circle Down", value: "pi pi-chevron-circle-down" },
  { label: "Chevron Circle Right", value: "pi pi-chevron-circle-right" },
  { label: "Chevron Circle Up", value: "pi pi-chevron-circle-up" },
  { label: "Angle Double Down", value: "pi pi-angle-double-down" },
  { label: "Angle Double Left", value: "pi pi-angle-double-left" },
  { label: "Angle Double Right", value: "pi pi-angle-double-right" },
  { label: "Angle Double Up", value: "pi pi-angle-double-up" },
  { label: "Angle Down", value: "pi pi-angle-down" },
  { label: "Angle Left", value: "pi pi-angle-left" },
  { label: "Angle Right", value: "pi pi-angle-right" },
  { label: "Angle Up", value: "pi pi-angle-up" },
  { label: "Upload", value: "pi pi-upload" },
  { label: "Download", value: "pi pi-download" },
  { label: "Ban", value: "pi pi-ban" },
  { label: "Star Fill", value: "pi pi-star-fill" },
  { label: "Star", value: "pi pi-star" },
  { label: "Chevron Left", value: "pi pi-chevron-left" },
  { label: "Chevron Right", value: "pi pi-chevron-right" },
  { label: "Chevron Down", value: "pi pi-chevron-down" },
  { label: "Chevron Up", value: "pi pi-chevron-up" },
  { label: "Caret Left", value: "pi pi-caret-left" },
  { label: "Caret Right", value: "pi pi-caret-right" },
  { label: "Caret Down", value: "pi pi-caret-down" },
  { label: "Caret Up", value: "pi pi-caret-up" },
  { label: "Search", value: "pi pi-search" },
  { label: "Check", value: "pi pi-check" },
  { label: "Check Circle", value: "pi pi-check-circle" },
  { label: "Times", value: "pi pi-times" },
  { label: "Times Circle", value: "pi pi-times-circle" },
  { label: "Plus", value: "pi pi-plus" },
  { label: "Plus Circle", value: "pi pi-plus-circle" },
  { label: "Minus", value: "pi pi-minus" },
  { label: "Minus Circle", value: "pi pi-minus-circle" },
  { label: "Circle On", value: "pi pi-circle-on" },
  { label: "Circle Off", value: "pi pi-circle-off" },
  { label: "Sort Down", value: "pi pi-sort-down" },
  { label: "Sort Up", value: "pi pi-sort-up" },
  { label: "Sort", value: "pi pi-sort" },
  { label: "Step Backward", value: "pi pi-step-backward" },
  { label: "Step Forward", value: "pi pi-step-forward" },
  { label: "Th Large", value: "pi pi-th-large" },
  { label: "Arrow Down", value: "pi pi-arrow-down" },
  { label: "Arrow Left", value: "pi pi-arrow-left" },
  { label: "Arrow Right", value: "pi pi-arrow-right" },
  { label: "Arrow Up", value: "pi pi-arrow-up" },
  { label: "Bars", value: "pi pi-bars" },
  { label: "Arrow Circle Down", value: "pi pi-arrow-circle-down" },
  { label: "Arrow Circle Left", value: "pi pi-arrow-circle-left" },
  { label: "Arrow Circle Right", value: "pi pi-arrow-circle-right" },
  { label: "Arrow Circle Up", value: "pi pi-arrow-circle-up" },
  { label: "Info", value: "pi pi-info" },
  { label: "Info Circle", value: "pi pi-info-circle" },
  { label: "Home", value: "pi pi-home" },
  { label: "Spinner", value: "pi pi-spinner" }
];
