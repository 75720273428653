import type { Nullable } from "../../types";
import type { IEndSwitchOptionsCases } from "./components/helpers/EndSwitchOptions";
import { NodeTypes } from "./constants";
import {
  getEndLoopNodeIdFor,
  getEndSwitchNodeIdFor,
  getStartNode
} from "./data";
import { notNull } from "./typeGuards";
import type {
  IBackendFlow,
  IFlow,
  IFrontendFlow,
  INode,
  INodeData,
  IReusableFlow,
  NodeMap
} from "./types";
import { TerminalNodeTypes } from "./types";
interface Hierarchy {
  [key: string]: number;
}

function isTerminalAction(actionType: INodeData["actionType"]) {
  return TerminalNodeTypes.includes(actionType);
}

function isTerminalNode(node: INode<INodeData>) {
  return TerminalNodeTypes.includes(node.data.actionType);
}

function isSwitchNode(node: INode<INodeData>) {
  return node.type === NodeTypes.Switch;
}

function isEndSwitchNode(node: INode<INodeData>) {
  return node.type === NodeTypes.EndSwitch;
}

function isLoopNode(node: INode<INodeData>) {
  return node.type === NodeTypes.Loop;
}

function isEndLoopNode(node: INode<INodeData>) {
  return node.type === NodeTypes.EndLoop;
}

function isFlow(obj: unknown): obj is IFlow {
  if (!obj) {
    return false;
  }
  if (typeof obj !== "object") {
    return false;
  }
  return (
    "type" in obj && ["fe", "be", "reusable"].includes((obj as IFlow).type)
  );
}

function isFrontendFlow(obj: unknown): obj is IFrontendFlow {
  if (!isFlow(obj)) {
    return false;
  }
  return obj.type === "fe";
}

function isBackendFlow(obj: unknown): obj is IBackendFlow {
  if (!isFlow(obj)) {
    return false;
  }
  return obj.type === "be";
}

function isReusableFlow(obj: unknown): obj is IReusableFlow {
  if (!isFlow(obj)) {
    return false;
  }
  return obj.type === "reusable";
}

function joinedSwitchNodes(
  node: INode<INodeData>,
  nodes: NodeMap,
  cases: string[]
): IEndSwitchOptionsCases[] {
  const newCases: IEndSwitchOptionsCases[] = cases.map((item, i) => ({
    bounded: false,
    node: null,
    index: i
  }));
  newCases.push({ bounded: false, node: null, index: newCases.length });
  const switchNodeId = node.id.split("-switchend")[0];
  const nexts = nodes[switchNodeId].next;
  nexts.forEach((next, i) => {
    if (!next) {
      newCases[i].node = nodes[switchNodeId];
      return;
    }

    let nextItem: INode<INodeData> | null = nodes[next];
    let previousNode: INode<INodeData> | null = nodes[switchNodeId];
    while (nextItem) {
      if (node.id === nextItem.id || nextItem.type === NodeTypes.EndSwitch) {
        newCases[i] = { bounded: true, node: previousNode, index: i };
        break;
      }
      previousNode = nextItem;
      nextItem = nextItem.next[0] ? nodes[nextItem.next[0]] : null;
      if (!nextItem) {
        newCases[i].node = previousNode;
      }
    }
  });
  return newCases;
}

function getPreviousNode(
  node: INode<INodeData>,
  nodes: NodeMap
): INode<INodeData> {
  return node;
}

function closestEndNodeId(
  node: INode<INodeData>,
  nodes: NodeMap
): Nullable<string> {
  if (node.type === NodeTypes.EndLoop || node.type === NodeTypes.EndSwitch) {
    return node.id;
  }
  const start = getStartNode(nodes);
  let loopIdList: Nullable<string>[] = [];
  const queue = [start];

  while (queue.length > 0) {
    const curr = queue.shift()!;
    if (curr.type === NodeTypes.Loop) {
      loopIdList = [...loopIdList, getEndLoopNodeIdFor(curr.id)];
    }
    if (curr.type === NodeTypes.Switch) {
      loopIdList = [...loopIdList, getEndSwitchNodeIdFor(curr.id)];
    }
    if (curr.type === NodeTypes.EndLoop || curr.type === NodeTypes.EndSwitch) {
      loopIdList = loopIdList.filter((id) => id !== curr.id);
    }
    if (curr.id === node.id) {
      return loopIdList.length > 0 ? loopIdList[loopIdList.length - 1] : null;
    }
    curr.next.filter(notNull).forEach((id) => queue.push(nodes[id]));
  }
  return null;
}

function isSubflowNode(node: INode<INodeData>, nodes: NodeMap): boolean {
  const start = getStartNode(nodes);
  let level = 0;
  const queue = [start];

  while (queue.length > 0) {
    const curr = queue.shift()!;
    if (curr.type === NodeTypes.Loop) {
      level++;
    }
    if (curr.type === NodeTypes.EndLoop) {
      level--;
    }
    if (curr.id === node.id) {
      break;
    }
  }
  return level > 0;
}

function buildHierarchy(arr: string[]): Hierarchy {
  let hierarchy: Hierarchy = {};
  function findChildren(index: number, level: number): Hierarchy {
    let children: Hierarchy = {};

    let i = index + 1;
    while (i < arr.length && !arr[i].includes("-end")) {
      children[arr[i]] = level + 1;
      children = { ...children, ...findChildren(i, level + 1) };
      i = arr.indexOf(`${arr[i]}-end`) + 1;
    }

    return { ...children };
  }

  arr.forEach((item, index) => {
    if (!item.includes("-end") && !item.includes("adder-")) {
      if (!hierarchy[item]) {
        hierarchy[item] = 1;
        hierarchy = { ...hierarchy, ...findChildren(index, 1) };
      }
    }
  });

  return hierarchy;
}

export {
  isTerminalNode,
  isTerminalAction,
  isSubflowNode,
  closestEndNodeId,
  isSwitchNode,
  isEndSwitchNode,
  isLoopNode,
  isEndLoopNode,
  isFlow,
  isFrontendFlow,
  isBackendFlow,
  isReusableFlow,
  joinedSwitchNodes,
  getPreviousNode,
  buildHierarchy
};
