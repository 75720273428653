import type { ComponentDefinition } from "../types";

const Spacer = (): ComponentDefinition => getCommonSpacerDefs();

const getCommonSpacerDefs = (): ComponentDefinition => ({
  icon: "c2-icons-button",
  category: "layout",
  disabled: false,
  eventList: [],
  base: {
    name: "Spacer",
    package: "code2",
    type: "Spacer",
    key: "Spacer",
    events: {},
    children: [],
    props: {},
    propMask: {},
    uiBlock: ["DefaultComponent"]
  },
  propDefs: [
    {
      name: "seperator",
      displayName: "Seperator",
      key: "seperator",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      }
    },
    {
      name: "orientation",
      displayName: "Seperator Orientation",
      key: "orientation",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          {
            label: "Horizontal",
            value: "horizontal"
          },
          {
            label: "Vertical",
            value: "vertical"
          }
        ],
        initialValue: "vertical",
        valueMap: {}
      }
    },
    {
      name: "seperatorAlignment",
      displayName: "Seperator Position",
      key: "seperatorAlignment",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          {
            label: "Start",
            value: "start"
          },
          {
            label: "Center",
            value: "center"
          },
          {
            label: "End",
            value: "end"
          }
        ],
        initialValue: "center",
        valueMap: {}
      }
    },
    {
      name: "seperatorColor",
      displayName: "Seperator Color",
      key: "seperatorColor",
      type: "ColorSelector",
      render: {
        component: "ColorSelector",
        initialValue: "#d3d5d8",
        valueMap: {}
      }
    },
    {
      name: "seperatorThickness",
      displayName: "Seperator Thickness",
      key: "seperatorThickness",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          {
            label: "1px",
            value: "1"
          },
          {
            label: "2px",
            value: "2"
          },
          {
            label: "3px",
            value: "3"
          },
          {
            label: "4px",
            value: "4"
          },
          {
            label: "5px",
            value: "5"
          }
        ],
        initialValue: "1",
        valueMap: {}
      }
    }
  ],
  styleDefs: [],
  availableDesignMode: "basic"
});

export default Spacer;
