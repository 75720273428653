import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

export interface MenuBarState {
  collapsed: boolean;
  collapsableButtonIsVisible: boolean;
}

const initialState: MenuBarState = {
  collapsed: localStorage.getItem("collapsed") === "true",
  collapsableButtonIsVisible: true
};

export const menuBarSlice = createSlice({
  name: "menu-bar",
  initialState,
  reducers: {
    setCollapsed: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem("collapsed", action.payload.toString());
      state.collapsed = action.payload;
    },
    reset: (state) => {
      localStorage.removeItem("collapsed");
      state.collapsed = false;
    },
    setCollapsableButtonIsVisible: (state, action: PayloadAction<boolean>) => {
      state.collapsableButtonIsVisible = action.payload;
    }
  }
});

export const { setCollapsed, reset, setCollapsableButtonIsVisible } =
  menuBarSlice.actions;

export const selectCollapsed = (state: RootState) => state.menuBar.collapsed;
export const selectCollapsableButtonIsVisible = (state: RootState) =>
  state.menuBar.collapsableButtonIsVisible;
export default menuBarSlice.reducer;
