import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Slider = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getCommonSliderDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 3
    };
    commonDef.styleDefs = ["background", "effects"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true,
      "w-3": true
    };
  }
  return commonDef;
};

const getCommonSliderDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-slider-component",
  category: "forms",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "Slider",
    events: {},
    props: {
      c2FieldType: "Slider",
      className: {}
    },
    propMask: {},
    name: "Slider",
    children: [],
    uiBlock: ["Slider", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "labelPlacement",
      displayName: "Label Placement",
      key: "labelPlacement",
      type: "SelectButton",
      render: {
        component: "SelectButton",
        options: [
          { label: "Vertical", value: "vertical" },
          { label: "Horizontal", value: "horizontal" }
        ],
        initialValue: "horizontal",
        valueMap: {}
      }
    },
    {
      name: "hint",
      displayName: "Hint",
      key: "hint",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "min",
      displayName: "Min Value",
      key: "min",
      type: "input",
      render: { component: "inputnumber", initialValue: 0, valueMap: {} },
      validateResultAsType: "number"
    },
    {
      name: "max",
      displayName: "Max Value",
      key: "max",
      type: "input",
      render: { component: "inputnumber", initialValue: 100, valueMap: {} },
      validateResultAsType: "number"
    },
    {
      name: "step",
      displayName: "Step",
      key: "step",
      type: "input",
      render: { component: "inputnumber", initialValue: 1, valueMap: {} },
      validateResultAsType: "number"
    },
    {
      name: "vertical",
      displayName: "Vertical",
      key: "orientation",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: "horizontal",
        trueValue: "vertical",
        falseValue: "horizontal",
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: ["spacing", "size", "background", "effects"],
  availableDesignMode: "both"
});

export default Slider;
