import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../features/auth/useAuth";
import type { ReactElement } from "react";

interface IProtectedRouteProps {
  roleRequired?: ("DEVELOPER" | "PARTNER_GUEST" | "USER" | "")[];
  children?: ReactElement<any, any>;
}

const ProtectedRoutes = (props: IProtectedRouteProps) => {
  const { children, roleRequired } = props;
  const { auth, role } = useAuth();

  //if the role required is there or not
  if (roleRequired && auth) {
    return role && roleRequired.includes(role) ? (
      children ? (
        children
      ) : (
        <Outlet />
      )
    ) : (
      <Navigate to="/denied" />
    );
  } else {
    return auth ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default ProtectedRoutes;
