import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const Title = (designMode: DesignMode) => getTitleDefs(designMode);

const getCommonTitleDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-title-component",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Header",
    key: "Title",
    events: {},
    props: {
      className: {}
    },
    propMask: {
      type: "h1",
      content: "New Title"
    },
    name: "Title",
    children: [],
    uiBlock: ["DefaultComponent"]
  },
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when header is clicked"
    }
  ],
  propDefs: [
    {
      name: "content",
      displayName: "Content",
      key: "content",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      }
    },
    {
      name: "type",
      displayName: "Heading Size",
      key: "type",
      type: "select",
      render: {
        component: "dropdown",
        options: [
          {
            label: "H1",
            value: "h1"
          },
          {
            label: "H2",
            value: "h2"
          },
          {
            label: "H3",
            value: "h3"
          },
          {
            label: "H4",
            value: "h4"
          },
          {
            label: "H5",
            value: "h5"
          },
          {
            label: "H6",
            value: "h6"
          }
        ],
        initialValue: "h1",
        valueMap: {}
      }
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

const getTitleDefs = (designMode: DesignMode) => {
  const commonDef = getCommonTitleDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 2,
      h: 3
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true,
      "pt-2": true,
      "pb-2": true,
      "pl-2": true,
      "pr-2": true
    };

    commonDef.styleDefs = [
      "spacing",
      "size",
      "typography",
      "background",
      "effects",
      "border"
    ];
  }

  return commonDef;
};

export default Title;
