import { classNames } from "primereact/utils";
import { memo, useState } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import Icon from "../../../../app/components/Icon";
import type { IconProp } from "../../../../app/components/Icon/types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import type { Nullable } from "../../../../types";
import { selectSelectedNode, setSelectedNode } from "../../flowDesignerSlice";
import type { ITriggerNodeData } from "../../types";
import { Dropdown } from "primereact/dropdown";

const icons: {
  [eventName: string]: IconProp;
} = {
  onClick: "click",
  onMouseEnter: "enter",
  onMouseLeave: "load",
  webhook: "load",
  scheduled: "load",
  onLoad: "load",
  onChange: "load",
  default: "load"
};

const TriggerNode = ({
  id,
  data: { value, options, onChange, disabled, orientation },
  isConnectable
}: NodeProps<ITriggerNodeData>) => {
  const [overlayWidth, setOverlayWidth] = useState(0);
  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectSelectedNode) === id;
  const maybeSelectedOption = options.find((option) => option.value === value);
  const optionSelected = typeof maybeSelectedOption !== "undefined";
  const placeholder = (
    <>
      <i className="c2-icons-cursor text-color text-xl" />
      <div className="white-space-nowrap">Select a trigger event</div>
    </>
  );

  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <div
        onClick={() => dispatch(setSelectedNode(id))}
        className={classNames(
          `w-full h-full flex justify-content-center align-items-center 
          border-none pointer-events-auto`,
          { "border-white": selected },
          "trigger-node"
        )}
      >
        <div
          className={classNames("w-full h-full flex border-round-md border-1", {
            "border-transparent": !selected,
            "border-white": selected,
            "trigger-node-wrapper": true
          })}
        >
          <div
            className={classNames(
              "absolute h-full flex align-items-center justify-content-between z-1 cursor-auto py-2 px-1",
              {
                "px-1": !optionSelected,
                "px-2": optionSelected
              }
            )}
            style={{
              width: `${overlayWidth}px`
            }}
          >
            {optionSelected ? (
              <div className="flex gap-1 align-items-center">
                <Icon
                  className="bg-indigo-500 border-round-md p-1 text-xs"
                  icon={icons[maybeSelectedOption.value]}
                />
                <span>{maybeSelectedOption.label}</span>
              </div>
            ) : (
              placeholder
            )}
          </div>
          <Dropdown
            disabled={disabled}
            className="w-full h-full border-round-md bg-white-700"
            appendTo="self"
            value={value}
            options={options}
            itemTemplate={(option: (typeof options)[number]) => {
              return (
                <div className="flex gap-1 align-items-center">
                  <Icon
                    className="bg-indigo-500 border-round-md p-1 text-xs"
                    icon={icons[option.value]}
                  />
                  {option.label}
                </div>
              );
            }}
            valueTemplate={(option: Nullable<(typeof options)[number]>) => {
              return (
                <div
                  ref={(ele) => setOverlayWidth(ele?.clientWidth ?? 0)}
                  className="w-full h-full"
                >
                  <div className="flex gap-1">{option?.label}</div>
                </div>
              );
            }}
            onChange={({ value }) => onChange(value as string)}
          />
        </div>
      </div>
      <Handle
        type="source"
        position={sourceHandlePosition}
        isConnectable={isConnectable}
        className={sourceHandleClassName}
        style={{
          visibility: "hidden"
        }}
      />
    </>
  );
};

export default memo(TriggerNode);
