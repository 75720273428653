import type { TaskState } from "@code2io/fe-engine/dist/flowExecutor";

interface ITaskBuilder {
  manifest: TaskState;
}

class TaskBuilder implements ITaskBuilder {
  manifest: TaskState;

  constructor(resource: string) {
    this.manifest = {
      Type: "Task",
      Resource: resource
    };
  }

  setComment(comment: string) {
    this.manifest.Comment = comment;
    return this;
  }

  setParameters(parameters: unknown) {
    this.manifest.Parameters = parameters;
    return this;
  }

  setResultSelector(resultSelector: unknown) {
    this.manifest.ResultSelector = resultSelector;
    return this;
  }

  setNext(next?: string) {
    if (next) {
      this.manifest.Next = next;
    }
    return this;
  }

  setEnd(end?: boolean) {
    if (end) {
      this.manifest.End = end;
    }
    return this;
  }

  setInputPath(path: string) {
    this.manifest.InputPath = path;
    return this;
  }

  setResultPath(path: string) {
    this.manifest.ResultPath = path;
    return this;
  }

  setOutputPath(path: string) {
    this.manifest.OutputPath = path;
    return this;
  }

  setRetry(retry: object[]) {
    this.manifest.Retry = retry;
    return this;
  }

  build(): TaskState {
    return this.manifest;
  }
}

export { TaskBuilder };
