import type { ComponentDefinition } from "../types";

const Column: ComponentDefinition = {
  icon: "",
  category: "dataview",
  disabled: true,
  hidden: true,
  base: {
    package: "code2",
    type: "C2Column",
    key: "Column",
    events: {},
    props: {
      className: {
        "flex-column": "flex-column",
        "flex-nowrap": true,
        "align-items-start": "align-items-start",
        "border-round": "border-round",
        "border-1": "border-1",
        "border-dashed": "border-dashed",
        "border-bluegray-200": "border-bluegray-200",
        "pt-2": "pt-2",
        "pl-2": "pl-2",
        "pb-2": "pb-2",
        "pr-2": "pr-2",
        "bg-white-50": true,
        "max-w-12": "max-w-12"
      }
    },
    propMask: {},
    name: "Column",
    children: [],
    uiBlock: ["Column"]
  },
  propDefs: [
    {
      name: "cols",
      displayName: "Column Size",
      key: "cols",
      type: "SelectButton",
      render: {
        component: "SelectButton",
        options: [
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          { value: "6", label: "6" },
          { value: "7", label: "7" },
          { value: "8", label: "8" },
          { value: "9", label: "9" },
          { value: "10", label: "10" },
          { value: "11", label: "11" },
          { value: "12", label: "12" }
        ],
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    }
  ],
  eventList: [],
  styleDefs: [
    "layout",
    "spacing",
    "size",
    "border",
    "background",
    "effects",
    "overflow"
  ],
  availableDesignMode: "advanced"
};

export default Column;
