import { classNames } from "primereact/utils";
import { memo, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import Icon from "../../../../app/components/Icon";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSelectedNode, setSelectedNode } from "../../flowDesignerSlice";
import "./AnimatedEntry.css";
import styles from "./Node.module.css";
import { useFlowId } from "../../hooks/useFlowId";
import { useGetFlowDataQuery } from "../../../../app/services/collaboration/collaborationApi";
import { PeakaTooltip } from "@code2io/fe-studio-component-library";

const EndLoopNode = ({
  id,
  data: { orientation }
}: NodeProps<{ orientation: "horizontal" | "vertical" }>) => {
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);
  const selected = useAppSelector(selectSelectedNode) === id;
  const selectedFlowId = useFlowId();
  const outputRef = useRef<HTMLDivElement>(null);
  const { data } = useGetFlowDataQuery(selectedFlowId ?? "");
  const nodeData = data?.draft.nodes[id]?.data.variables;
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInProp(true);
  }, []);

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";
  const loopHandlePosition =
    orientation === "horizontal" ? Position.Bottom : Position.Left;
  const loopHandleClassName =
    orientation === "horizontal" ? "bottom-0" : "left-0";

  return (
    <>
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{ visibility: "hidden" }}
      />
      <CSSTransition
        nodeRef={nodeRef}
        timeout={500}
        in={inProp}
        classNames="animated-entry-node"
      >
        <div
          ref={nodeRef}
          onClick={() => dispatch(setSelectedNode(id))}
          className="w-full h-full relative cursor-pointer"
        >
          <div
            className={classNames(
              "w-full h-full flex align-items-center border-1 border-round-sm bg-white-700 relative",
              styles.nodeContainer,
              {
                "border-white-50": selected,
                "border-transparent": !selected
              }
            )}
          >
            <PeakaTooltip
              position="right"
              content={
                <div className="p-2 block" style={{ width: "100%" }}>
                  <table>
                    <tbody>
                      {nodeData?.map((item, i) => {
                        return (
                          <tr style={{ fontFamily: "monospace" }} key={i}>
                            <td
                              style={{
                                fontFamily: "monospace",
                                color: "var(--indigo-300)"
                              }}
                            >
                              {item.label}
                            </td>
                            <td>:</td>
                            <td>{item.value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              }
              target={outputRef}
              showDelay={50}
            />
            <div
              ref={outputRef}
              className="absolute text-3xs"
              style={{
                bottom: 2,
                right: 2,
                fontFamily: "monospace",
                color: "var(--indigo-300)"
              }}
            >
              {nodeData && nodeData.length > 1
                ? `${nodeData[0]?.label}, ...`
                : nodeData?.[0]?.label}
            </div>
            <div
              className="h-full bg-blue-500 border-round-left-sm"
              style={{ width: "0.25rem" }}
            />
            <div className="flex w-full px-2 justify-content-between">
              <div className="flex gap-2 align-items-center">
                <Icon icon="flag" className="text-2xl" />
                End Loop
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <Handle
        id={`${id}-endLoopHandle`}
        type="source"
        position={loopHandlePosition}
        className={loopHandleClassName}
        style={{ visibility: "hidden" }}
      />
      <Handle
        id={`source`}
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

export default memo(EndLoopNode);
