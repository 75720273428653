import { createContext } from "react";
import type { EvaluationContext } from "./types";

const OnboardingContext = createContext<{
  evaluationContext: EvaluationContext;
  setEvaluationContext: (
    evaluationContext:
      | EvaluationContext
      | ((evaluationContext: EvaluationContext) => EvaluationContext)
  ) => void;
}>({
  evaluationContext: {},
  setEvaluationContext: () => undefined
});

export const OnboardingProvider = OnboardingContext.Provider;

export default OnboardingContext;
