import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

export interface RouterState {
  dataModel: string;
  connect: string;
  flows: string;
  design: string;
}

interface RouterPayload {
  field: "dataModel" | "connect" | "flows" | "design";
  path: string;
}

const initialState: RouterState = {
  dataModel: "",
  connect: "",
  flows: "",
  design: ""
};

export const routerSlice = createSlice({
  name: "routerState",
  initialState,
  reducers: {
    setNewPath: (state, action: PayloadAction<RouterPayload>) => {
      const { field, path } = action.payload;
      state[field] = path;
    },
    setInitialPath: (state) => {
      state = {
        dataModel: "",
        flows: "",
        connect: "",
        design: ""
      };
      return state;
    }
  }
});

export const { setNewPath, setInitialPath } = routerSlice.actions;

export const selectDataModelPath = (state: RootState) =>
  state.routerReducer.dataModel;
export const selectFlowsPath = (state: RootState) => state.routerReducer.flows;
export const selectConnectPath = (state: RootState) =>
  state.routerReducer.connect;
export const selectDesignPath = (state: RootState) =>
  state.routerReducer.design;

export default routerSlice.reducer;
