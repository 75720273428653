import { useAppSelector } from "../../app/hooks";
import { selectToken, selectUser, type User } from "./authSlice";
import jwt_decode from "jwt-decode";

export type IRole = "DEVELOPER" | "PARTNER_GUEST" | "USER";

const useAuth = () => {
  const token = useAppSelector(selectToken);
  const user: User | null =
    useAppSelector(selectUser) ?? token !== null ? jwt_decode(token!) : null;

  const role: IRole = user?.developerFlag
    ? "DEVELOPER"
    : user?.partnerGuestUser
    ? "PARTNER_GUEST"
    : "USER";

  if (user) {
    return {
      auth: true,
      role
    };
  } else {
    return {
      auth: false,
      role: null
    };
  }
};

export { useAuth };
