import type { ComponentDefinition } from "../types";

const Sidebar: ComponentDefinition = {
  icon: "fa-kit fa-sidebar-component",
  category: "layout",
  disabled: true,
  base: {
    package: "primereact",
    type: "Sidebar",
    key: "Sidebar",
    events: {},
    props: {
      className: {}
    },
    name: "Sidebar",
    children: []
  },
  propDefs: [],
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when button is clicked"
    }
  ],
  styleDefs: ["spacing", "size", "text", "border", "background"],
  availableDesignMode: "advanced"
};

export default Sidebar;
