import * as React from "react";
const SvgMastercardLogo = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 16, height: 10, viewBox: "0 0 16 10", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M5.62109 1.0575H10.3815V8.83263H5.62109V1.0575Z", fill: "#FF5F00", style: {
  fill: "#FF5F00",
  fill: "color(display-p3 1.0000 0.3725 0.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.11114 4.94589C6.11055 4.19722 6.28027 3.45821 6.60745 2.78481C6.93463 2.11141 7.4107 1.52126 7.99964 1.05901C7.27021 0.485764 6.39423 0.129293 5.47179 0.0303434C4.54936 -0.0686065 3.6177 0.0939569 2.78328 0.499454C1.94887 0.904951 1.24537 1.53702 0.753187 2.32343C0.261006 3.10983 0 4.01884 0 4.94657C0 5.87429 0.261006 6.7833 0.753187 7.56971C1.24537 8.35611 1.94887 8.98818 2.78328 9.39368C3.6177 9.79918 4.54936 9.96174 5.47179 9.86279C6.39423 9.76384 7.27021 9.40737 7.99964 8.83412C7.41052 8.37173 6.93434 7.78136 6.60715 7.1077C6.27996 6.43405 6.11034 5.69478 6.11114 4.94587V4.94589Z", fill: "#EB001B", style: {
  fill: "#EB001B",
  fill: "color(display-p3 0.9216 0.0000 0.1059)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M15.5274 8.0099V7.8506H15.596V7.81763H15.4326V7.8506H15.4972V8.00993L15.5274 8.0099ZM15.8446 8.0099V7.81765H15.7952L15.7375 7.95498L15.6798 7.81763H15.6304V8.0099H15.6661V7.86433L15.7197 7.98933H15.7567L15.8103 7.86433V8.0099H15.8446Z", fill: "#F79E1B", style: {
  fill: "#F79E1B",
  fill: "color(display-p3 0.9686 0.6196 0.1059)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M16.0004 4.94571C16.0004 5.87351 15.7393 6.78258 15.247 7.56901C14.7548 8.35545 14.0511 8.98751 13.2166 9.39294C12.3821 9.79838 11.4503 9.96084 10.5279 9.86174C9.60536 9.76265 8.72937 9.406 8 8.83257C8.5887 8.36991 9.06464 7.7796 9.3919 7.10616C9.71917 6.43273 9.88922 5.69376 9.88922 4.94501C9.88922 4.19627 9.71917 3.4573 9.3919 2.78387C9.06464 2.11043 8.5887 1.52012 8 1.05746C8.72937 0.484027 9.60537 0.127379 10.5279 0.0282852C11.4504 -0.0708088 12.3821 0.09165 13.2166 0.497091C14.0512 0.902532 14.7548 1.5346 15.247 2.32103C15.7393 3.10747 16.0004 4.01654 16.0004 4.94434V4.94571Z", fill: "#F79E1B", style: {
  fill: "#F79E1B",
  fill: "color(display-p3 0.9686 0.6196 0.1059)",
  fillOpacity: 1
} }));
export default SvgMastercardLogo;
