import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

interface ModalOptions {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "none";
  defaultPadding?: boolean;
}

interface Content {
  component: string;
  props?: { [key: string]: unknown };
}

export interface C2ModalState {
  visible: boolean;
  content: Content;
  header: string;
  footer: string;
  options?: ModalOptions;
  formData?: unknown;
  loading?: boolean;
}

const initialState: C2ModalState = {
  visible: false,
  header: "Initial Header",
  content: {
    component: "CreateAppForm"
  },
  footer: "CreateAppButtons",
  options: {
    size: "md"
  },
  loading: false
};

export const c2ModalSlice = createSlice({
  name: "c2Modal",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setHeader: (state, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
    setContent: (state, action: PayloadAction<Content>) => {
      state.content = action.payload;
    },
    setFooter: (state, action: PayloadAction<string>) => {
      state.footer = action.payload;
    },
    setOptions: (state, action: PayloadAction<ModalOptions>) => {
      state.options = action.payload;
    },
    setFormData: (state, action: PayloadAction<unknown>) => {
      state.formData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  }
});

export const {
  setVisible,
  setHeader,
  setContent,
  setFooter,
  setOptions,
  setFormData,
  setLoading
} = c2ModalSlice.actions;

export const selectVisible = (state: RootState) => {
  return state.c2Modal.visible;
};
export const selectHeader = (state: RootState) => {
  return state.c2Modal.header;
};
export const selectContent = (state: RootState) => {
  return state.c2Modal.content;
};
export const selectFooter = (state: RootState) => {
  return state.c2Modal.footer;
};
export const selectOptions = (state: RootState) => {
  return state.c2Modal.options;
};
export const selectFormData = (state: RootState) => {
  return state.c2Modal.formData;
};
export const selectLoading = (state: RootState) => {
  return state.c2Modal.loading;
};
export default c2ModalSlice.reducer;
