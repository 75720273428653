import { TieredMenu } from "primereact/tieredmenu";
import { memo, useRef } from "react";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import type { IActionAdderNodeData } from "../../types";
import styles from "./ActionAdderNode.module.css";
import { classNames } from "primereact/utils";
import Icon from "../../../../app/components/Icon";

const ActionAdderNode = ({
  data: { menuItems, size, orientation } = {
    menuItems: [],
    size: "lg",
    orientation: "horizontal"
  }
}: NodeProps<IActionAdderNodeData>) => {
  const menu = useRef<TieredMenu>(null);

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{
          visibility: "hidden"
        }}
      />
      <div
        className={classNames(
          styles.actionAdderNode,
          "w-full h-full cursor-pointer"
        )}
      >
        <TieredMenu model={menuItems} popup ref={menu} id="overlay_tmenu" />
        <div
          data-test-class="flow-action-adder-node"
          className={`w-full h-full pointer-events-auto border-circle 
                      border-1 border-indigo-500 bg-white-50 text-primary 
                      flex align-items-center justify-content-center`}
          onClick={(event) => {
            menu.current?.toggle(event);
          }}
        >
          <Icon
            icon="add"
            className={classNames({
              "text-3xs": size === "sm",
              "text-base": size === "lg"
            })}
          />
        </div>
      </div>
      <Handle
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{
          visibility: "hidden"
        }}
      />
    </>
  );
};

export default memo(ActionAdderNode);
