import { createSlice } from "@reduxjs/toolkit";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataModelTableState {}

const initialState: DataModelTableState = {};

export const dataModelTableSlice = createSlice({
  name: "dataModelTable",
  initialState,
  reducers: {}
});

export default dataModelTableSlice.reducer;
