import type { C2IconProp } from "@code2io/fe-c2-icons";

export const getC2IconContent = (icon: C2IconProp) => {
  const element = document.createElement("span");
  element.classList.add(`c2-icons-${icon as string}`);
  document.body.append(element);
  const content = window
    .getComputedStyle(element, "before")
    .getPropertyValue("content");
  element.remove();
  return content.slice(1, -1);
};

export const getAppId = () => {
  return window.location.pathname.split("/")[2];
};

export const detectDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
    userAgent
  );
  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
    userAgent
  );

  if (isMobile) {
    return "mobile";
  } else if (isTablet) {
    return "tablet";
  } else {
    return "desktop";
  }
};
