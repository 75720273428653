import type { TreeNode } from "primereact/treenode";
import type {
  AppFolderStructureItem,
  ISharedAppMeta,
  ISharedAppMetav100
} from "./types";
import { kebabCase } from "lodash";

export const APP_META_VERSION = "1.1.0";

export function migrateFrom100to110(input: ISharedAppMetav100): ISharedAppMeta {
  return {
    id: input.id,
    homePage: input.homePage,
    folderStructure: migrateFolderStructure(input.folderStructure.root),
    version: "1.1.0"
  };
}

export function migrateFolderStructure(
  input: TreeNode[]
): AppFolderStructureItem[] {
  return input.map((item) => ({
    id: item.key as string,
    type: item.icon === "pi c2-icons-pages" ? "page" : "folder",
    name: item.label ?? "Not Found",
    path: kebabCase(item.label),
    children: migrateFolderStructure(item.children ?? [])
  }));
}
