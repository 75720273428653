import type { Toast } from "primereact/toast";
import ReactDOM from "react-dom/client";
import { PeakaToast } from "@code2io/fe-studio-component-library";

const toast: { current: Toast | null } = { current: null };

const container = document.createElement("div");
container.id = "app-toast-container";
document.body.append(container);
const root = ReactDOM.createRoot(container);

root.render(<PeakaToast ref={toast} />);

export default toast;

export const toastSuccess = (message: string, summary?: string) => {
  toast.current?.show({
    severity: "success",
    summary: summary ?? "Success",
    detail: message
  });
};

export const toastError = (error: unknown, summary?: string) => {
  if (error instanceof Error) {
    toast.current?.show({
      severity: "error",
      summary: summary ?? "Error",
      detail: error instanceof Error ? error.message : String(error)
    });
  }
};
