import type {
  FlowDefinition,
  MapState
} from "@code2io/fe-engine/dist/flowExecutor";

interface ILoopBuilder {
  manifest: MapState;
}

class LoopBuilder implements ILoopBuilder {
  manifest: MapState;

  constructor() {
    this.manifest = {
      Type: "Map",
      Iterator: {
        StartAt: "",
        States: {}
      },
      Parameters: {
        forever: false,
        array: [],
        variableName: "item"
      }
    };
  }

  setParameters(parameters: (typeof this.manifest)["Parameters"]) {
    this.manifest.Parameters = parameters;
    return this;
  }

  setIterator(subFlow: FlowDefinition) {
    this.manifest.Iterator = subFlow;
    return this;
  }

  setNext(next?: string) {
    if (next) {
      this.manifest.Next = next;
    }
    return this;
  }

  setEnd(end?: boolean) {
    if (end) {
      this.manifest.End = end;
    }
    return this;
  }

  build(): MapState {
    return this.manifest;
  }
}

export { LoopBuilder };
