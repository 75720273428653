import type { FlowDefinition } from "@code2io/fe-engine/dist/flowExecutor";

export const ResourceTypes = {
  executeBackendFlow: "executeBackendFlow"
} as const;

export const emptyFlow: FlowDefinition = {
  StartAt: "",
  States: {}
};
