import type { RequestDeveloperAccessFormData } from "../../features/advancedSettings/components/AdvancedSettingsRequestForm";
import { api } from "./api";

export interface ApiKeyResultType {
  id: string;
  name: string;
  keyPrefix: string;
  active: boolean;
  createdAt: string;
  scopes: string[];
  userId: string;
  authorizedAppId: string;
  partner: boolean;
}

export interface CreateOrUpdateApiKeyQueryArg {
  scopes?: string[];
  name: string;
  authorizedAppId?: string;
  partner: boolean;
  catalogId?: string;
}

export interface UpdateApiKeyQueryArg {
  id: string;
  body: CreateOrUpdateApiKeyQueryArg;
}

export interface CreateApiKeyResultType {
  apiKey: string;
  id: string;
}

export interface requestDeveloperAccessAndPartnerAPIKeyQueryArg {
  companyName: string;
  username: string;
  purposeOfDeveloperAccess: string;
  email: string;
}

export type requestDeveloperAccessStatus =
  | "APPROVED"
  | "PENDING"
  | "REJECTED"
  | null;

export interface requestDeveloperAccessAndPartnerAPIKeyQueryResult {
  companyName: string;
  createdAt: string;
  id: string;
  purpose: string;
  status: requestDeveloperAccessStatus;
  updatedAt: string;
  userEmail: string;
  userName: string;
}

export interface requestDeveloperAccessAndPartnerAPIKeyQueryStatusResult {
  companyName: string;
  createdAt: string;
  id: string;
  purpose: string | null;
  status: requestDeveloperAccessStatus;
  updatedAt: string | null;
  userEmail: string;
  userName: string;
}

export const apiKeyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApiKeys: builder.query<ApiKeyResultType[], { appId?: string }>({
      query: ({ appId }) => ({
        url: `/integration/apikey${appId ? "?appId=" + appId : ""}`,
        method: "GET"
      }),
      providesTags: ["ApiKeys"]
    }),
    createApiKey: builder.mutation<
      CreateApiKeyResultType,
      CreateOrUpdateApiKeyQueryArg
    >({
      query: (arg) => ({
        url: `/integration/apikey`,
        method: "POST",
        body: arg
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    updateApiKey: builder.mutation<ApiKeyResultType, UpdateApiKeyQueryArg>({
      query: (arg) => ({
        url: `/integration/apikey/${arg.id}`,
        method: "PUT",
        body: arg.body
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    deleteApiKey: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/integration/apikey/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    activateApiKey: builder.mutation<ApiKeyResultType, { id: string }>({
      query: ({ id }) => ({
        url: `/integration/apikey/${id}/activate`,
        method: "POST"
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    deActivateApiKey: builder.mutation<ApiKeyResultType, { id: string }>({
      query: ({ id }) => ({
        url: `/integration/apikey/${id}/deactivate`,
        method: "POST"
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    requestDeveloperAccessAndPartnerAPIKey: builder.mutation<
      requestDeveloperAccessAndPartnerAPIKeyQueryResult,
      RequestDeveloperAccessFormData
    >({
      query: (body) => ({
        url: `/auth/partner-api-access`,
        method: "POST",
        body
      }),
      invalidatesTags: ["RequesDeveloperAccessAndPartnerAPIKey"]
    }),
    getRequesDeveloperAccessAndPartnerAPIKeyStatus: builder.query<
      requestDeveloperAccessAndPartnerAPIKeyQueryStatusResult,
      unknown
    >({
      query: () => ({
        url: "/auth/partner-api-access",
        method: "GET"
      }),
      providesTags: ["RequesDeveloperAccessAndPartnerAPIKeyStatus"]
    })
  })
});

export const {
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useActivateApiKeyMutation,
  useDeActivateApiKeyMutation,
  useUpdateApiKeyMutation,
  useRequestDeveloperAccessAndPartnerAPIKeyMutation,
  useGetRequesDeveloperAccessAndPartnerAPIKeyStatusQuery
} = apiKeyApi;
