import * as React from "react";
const SvgFacebookAds = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Lag_1", style: {
  enableBackground: "new 0 0 204 204"
}, viewBox: "0 0 204 204", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#SVGID_1_);}\n	.st1{fill:url(#SVGID_2_);}\n	.st2{fill:#4B73B1;}\n	.st3{fill:#365493;}\n	.st4{fill:#FFFFFF;}\n	.st5{fill:#5989C4;}\n	.st6{fill:#E7E9EA;}\n	.st7{fill:#4268AD;}\n"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "SVGID_1_", x1: 102.0005, x2: 102.0005, y1: 204.0009, y2: -2671186e-15 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#263B74"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#4D78BF"
} })), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M161.5,204h-119C19.1,204,0,184.9,0,161.5v-119C0,19.1,19.1,0,42.5,0h119C184.9,0,204,19.1,204,42.5v119   C204,184.9,184.9,204,161.5,204z" }), /* @__PURE__ */ React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "SVGID_2_", x1: 126.2491, x2: 126.2491, y1: 204.0009, y2: 28.042 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#1F3157"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#3F63AF"
} })), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M204,161.5v-56.2L125.5,28l-77,118.5l58.8,57.4h54.1C184.9,204,204,184.9,204,161.5z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "st2", d: "M122.6,101.5l-11.4-21.6c-3.6-6.9-12.2-9.6-19.1-6l-52.5,27.6c-6.9,3.6-9.6,12.2-6,19.1l11.4,21.6    c3.6,6.9,12.2,9.6,19.1,6l3.4-1.8c0,0,9.6,18.5,13.4,25.5c3.8,7,8.5,5.5,13,3.5s11-6.5,11-6.5l-18.2-32.5l30-15.7    C123.5,117,126.2,108.4,122.6,101.5z" }), /* @__PURE__ */ React.createElement("path", { className: "st3", d: "M93.9,175.4c4.5-2,11-6.5,11-6.5l-18.2-32.5l21.6-11.3l-13.4-22.4L39.6,132l5.4,10.3c3.6,6.9,12.2,9.6,19.1,6    l3.4-1.8c0,0,9.6,18.5,13.4,25.5C84.6,178.9,89.4,177.4,93.9,175.4z" }), /* @__PURE__ */ React.createElement("polygon", { className: "st4", points: "68.1,86 93.8,132.3 157.4,120.3 114.1,38.3   " }), /* @__PURE__ */ React.createElement("circle", { className: "st4", cx: 147.8, cy: 73.6, r: 10.7 }), /* @__PURE__ */ React.createElement("path", { className: "st5", d: "M157.6,122.2l-45.1-85.7c-1.1-2.1-0.3-4.7,1.8-5.7l6.4-3.4c2.1-1.1,4.7-0.3,5.7,1.8l45.1,85.7    c1.1,2.1,0.3,4.7-1.8,5.7l-6.4,3.4C161.3,125.1,158.7,124.3,157.6,122.2z" }), /* @__PURE__ */ React.createElement("polygon", { className: "st6", points: "93.8,132.3 156.7,120.5 136,81.2 81.3,109.8   " }), /* @__PURE__ */ React.createElement("path", { className: "st7", d: "M163.4,124l6.4-3.4c2.1-1.1,2.9-3.7,1.8-5.7l-21.3-40.5l-14.1,7l21.5,40.9C158.7,124.3,161.3,125.1,163.4,124    z" }))));
export default SvgFacebookAds;
