import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectSelectedFlow } from "../flowDesignerSlice";

export function useFlowId(): string | null {
  const { id: routerFlowId } = useParams();
  const reduxStoreFlowId = useAppSelector(selectSelectedFlow);

  return routerFlowId ?? reduxStoreFlowId;
}
