import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";

const getCommonInputTextDef = (): ComponentDefinition => ({
  icon: "fa-kit fa-input-text-component",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "C2Field",
    key: "InputText",
    events: {},
    props: {
      c2FieldType: "InputText",
      className: {}
    },
    propMask: {},
    name: "Input Text",
    children: [],
    uiBlock: ["InputText", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "value",
      displayName: "Value",
      key: "value",
      type: "expr",
      render: {
        component: "expressioneditor",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "label",
      displayName: "Label",
      key: "label",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "labelPlacement",
      displayName: "Label Placement",
      key: "labelPlacement",
      type: "SelectButton",
      render: {
        component: "SelectButton",
        options: [
          { label: "Vertical", value: "vertical" },
          { label: "Horizontal", value: "horizontal" }
        ],
        initialValue: "horizontal",
        valueMap: {}
      }
    },
    {
      name: "hint",
      displayName: "Hint",
      key: "hint",
      type: "input",
      render: { component: "inputtext", initialValue: "", valueMap: {} }
    },
    {
      name: "placeholder",
      displayName: "Placeholder",
      key: "placeholder",
      type: "expr",
      render: { component: "expressioneditor", initialValue: "", valueMap: {} }
    },
    {
      name: "disabled",
      displayName: "Disabled",
      key: "disabled",
      type: "switch",
      render: { component: "inputswitch", initialValue: false, valueMap: {} },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onChange",
      readableName: "On Change",
      description: "Fires on value change."
    }
  ],
  styleDefs: [
    "spacing",
    "size",
    "typography",
    "background",
    "effects",
    "border"
  ],
  availableDesignMode: "both"
});

const InputText = (designMode: DesignMode) => {
  const commonDef = getCommonInputTextDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 4,
      h: 5
    };
    commonDef.styleDefs = ["typography", "background", "effects", "border"];
  } else {
    commonDef.styleDefs = [
      "spacing",
      "size",
      "typography",
      "background",
      "effects",
      "border"
    ];
    commonDef.base.props.className = {
      "mt-1": true,
      "mb-1": true,
      "pt-2": true,
      "pb-2": true,
      "pl-2": true,
      "pr-2": true
    };
  }
  return commonDef;
};

export default InputText;
