import type { DesignMode } from "@code2io/fe-engine/dist/types";
import type { ComponentDefinition } from "../types";
import type {
  Column,
  Query,
  Table,
  VisualSQLQuery
} from "../../features/dataModel/types";
import { getSelectAllVisualSQLQuery } from "../../features/dataModel/utils";

const DataTable = (designMode: DesignMode): ComponentDefinition =>
  getDatatableDef(designMode);

export interface DataTablePropMask {
  paginator: boolean;
  rows: string;
  sampleValue: unknown[];
  dataSource: Table | string | null;
  columns: Column[];
  isSampleLoading: boolean;
  dataTableVisualSqlQuery: VisualSQLQuery | null;
  dataModelColumns: Query["columns"] | Column[];
  value: unknown[] | string;
}

const getDatatableCommonDef = (): ComponentDefinition => ({
  icon: "c2-icons-datatable",
  category: "mostUsed",
  disabled: false,
  base: {
    package: "code2",
    type: "C2DataTable",
    key: "DataTable",
    events: {},
    props: {
      responsiveLayout: "scroll"
    },
    propMask: {
      paginator: true,
      rows: "{{10}}",
      sampleValue: [],
      dataSource: null,
      columns: [],
      isSampleLoading: false,
      dataTableVisualSqlQuery: null,
      dataModelColumns: []
    },
    name: "Data Table",
    children: [],
    uiBlock: ["DataTable", "DefaultComponent"]
  },
  propDefs: [
    {
      name: "dataSource",
      displayName: "Data Source",
      key: "dataSource",
      type: "DataSourceSelector",
      render: {
        component: "DataSourceSelector",
        initialValue: null,
        valueMap: {}
      },
      onChange: (
        value: DataTablePropMask["dataSource"]
      ): Partial<DataTablePropMask> => {
        if (typeof value === "undefined" || value === null) {
          return {
            dataSource: null,
            dataTableVisualSqlQuery: null,
            columns: [],
            sampleValue: []
          };
        }
        if (typeof value === "string") {
          return {
            dataSource: value,
            dataTableVisualSqlQuery: null,
            columns: [],
            sampleValue: [],
            value
          };
        }
        return {
          dataSource: value,
          dataTableVisualSqlQuery: getSelectAllVisualSQLQuery(value, {
            limit: 50
          })
        };
      }
    },
    {
      name: "data",
      displayName: "Data",
      key: "data",
      type: "none",
      render: {
        component: "none",
        initialValue: "",
        valueMap: {}
      },
      validateResultAsType: "string"
    },
    {
      name: "isSampleLoading",
      displayName: "SampleLoading",
      key: "isSampleLoading",
      type: "none",
      render: {
        component: "none",
        initialValue: true,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "colMapping",
      displayName: "Column Mapping",
      key: "colMapping",
      type: "TableColumnMapping",
      render: {
        component: "TableColumnMapping",
        initialValue: "",
        valueMap: {}
      },
      onChange: (value) => {
        console.log("colMapping", value);
        return {
          colMapping: value
        };
      }
    },
    {
      name: "dataTableVisualSqlQuery",
      displayName: "Query",
      key: "dataTableVisualSqlQuery",
      type: "VisualSQLEditorFlowProperty",
      render: {
        component: "VisualSQLEditorFlowProperty",
        initialValue: null,
        valueMap: {}
      }
    },
    {
      name: "actionButtons",
      displayName: "Action Buttons",
      key: "actionButtons",
      type: "ActionButton",
      render: {
        component: "ActionButton",
        initialValue: {
          actionColumn: false,
          buttons: [],
          columnTitle: "Action",
          columnPosition: "right",
          freezeColumn: false
        },
        valueMap: {}
      },
      isPropObj: true
    },
    {
      name: "sortable",
      displayName: "Sorting",
      key: "sortable",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "filter",
      displayName: "Filter",
      key: "filter",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    },
    {
      name: "loading",
      displayName: "Loading",
      key: "loading",
      type: "switch",
      render: {
        component: "inputswitch",
        initialValue: false,
        valueMap: {}
      },
      validateResultAsType: "boolean"
    }
  ],
  eventList: [
    {
      name: "onLoad",
      readableName: "On Load",
      description: "Fires when table initialized"
    },
    {
      name: "onFilter",
      readableName: "On Filter",
      description: "Fires when filters changed"
    },
    {
      name: "onActionButtonClick",
      readableName: "On Action Button Click",
      description: "Fires when action button clicked"
    }
  ],
  styleDefs: ["spacing", "size", "typography", "effects", "border"],
  availableDesignMode: "both",
  privateVariablePropList: ["onLoadCounter"]
});

const getDatatableDef = (designMode: DesignMode): ComponentDefinition => {
  const commonDef = getDatatableCommonDef();
  if (designMode === "basic") {
    commonDef.basicDesignInitialSize = {
      w: 6,
      h: 10
    };
    commonDef.styleDefs = ["typography", "effects", "border"];
  } else {
    commonDef.base.props.className = {
      "w-12": true,
      "mt-1": true,
      "mb-1": true,
      "pt-1": true,
      "pb-1": true,
      "pl-1": true,
      "pr-1": true
    };
  }
  return commonDef;
};

export default DataTable;
