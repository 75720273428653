import type {
  ChecklistItemCompleteLabelOptions,
  ChecklistItemTitleOptions,
  ChecklistTitleOptions,
  ParsedField,
  TourItemBackLabelOptions,
  TourItemNextLabelOptions,
  TourItemTitleOptions
} from "../types";

const optionPrefix = "--";

export const parseChecklistTitle = (
  completeLabel: string | undefined | null
) => {
  return parseField<ChecklistTitleOptions>(completeLabel);
};

export const parseChecklistItemCompleteLabel = (
  completeLabel: string | undefined | null
) => {
  return parseField<ChecklistItemCompleteLabelOptions>(completeLabel);
};

export const parseChecklistItemTitle = (title: string | undefined | null) => {
  return parseField<ChecklistItemTitleOptions>(title);
};

export const parseTourItemBackLabel = (
  backLabel: string | undefined | null
) => {
  return parseField<TourItemBackLabelOptions>(backLabel);
};

export const parseTourItemNextLabel = (
  nextLabel: string | undefined | null
) => {
  return parseField<TourItemNextLabelOptions>(nextLabel);
};

export const parseTourItemTitle = (title: string | undefined | null) => {
  return parseField<TourItemTitleOptions>(title);
};

export const parseField = <Options = { [key: string]: unknown }>(
  originalValue: string | undefined | null
): ParsedField<Options> => {
  if (originalValue == null) {
    return { options: {} as Options, originalValue, value: "" };
  }

  const optionList = originalValue
    .split(" ")
    .filter((word) => word.startsWith(optionPrefix));
  const options = Object.fromEntries(
    optionList.map((option) => {
      const [key, value] = option.slice(optionPrefix.length).split("=");
      return [
        key,
        value === "true" ? true : value === "false" ? false : value ?? true
      ];
    })
  ) as Options;
  const value = originalValue
    .split(" ")
    .filter((word) => !word.startsWith(optionPrefix))
    .join(" ");

  return { options, originalValue, value };
};
