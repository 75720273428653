import type { IActionType, INodeData, INodeExecutionType } from "./types";

export const nodeExecutionTypes: {
  [key in IActionType]: INodeExecutionType;
} = {
  "webhook:response": "be",
  http: "be",
  "db:get": "be",
  "db:query": "be",
  "db:insert": "be",
  "db:bulkInsert": "be",
  "db:update": "be",
  "db:bulkUpdate": "be",
  "db:delete": "be",
  "db:bulkDelete": "be",
  showNotification: "fe",
  setVariable: "fe",
  setAppVariables: "fe",
  setPageVariables: "fe",
  setComponentProperty: "fe",
  reloadComponentData: "fe",
  log: "fe",
  navigate: "fe",
  switch: "both",
  endSwitch: "both",
  loopList: "both",
  loopForever: "both",
  fail: "both",
  callable: "be",
  webhook: "be",
  scheduled: "be",
  executeBackendFlow: "both",
  success: "both",
  endLoop: "both",
  break: "both",
  end: "both",
  customAction: "both",
  transformation: "both",
  trigger: "both",
  transform: "both",
  "insider:upsert": "be"
} as const;

export function getExecutionType(data: INodeData): INodeExecutionType {
  const existingType = nodeExecutionTypes[data.actionType] as
    | INodeExecutionType
    | undefined;
  return existingType ?? data.executionType ?? "both";
}
