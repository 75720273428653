/* eslint-disable */
const subtypes = {};
function invertComponent(c) {
  const c_ = { p: c.p };

  // handle subtype ops
  if (c.t && subtypes[c.t]) {
    c_.t = c.t;
    c_.o = subtypes[c.t].invert(c.o);
  }

  if (c.si !== void 0) c_.sd = c.si;
  if (c.sd !== void 0) c_.si = c.sd;
  if (c.oi !== void 0) c_.od = c.oi;
  if (c.od !== void 0) c_.oi = c.od;
  if (c.li !== void 0) c_.ld = c.li;
  if (c.ld !== void 0) c_.li = c.ld;
  if (c.na !== void 0) c_.na = -c.na;

  if (c.lm !== void 0) {
    c_.lm = c.p[c.p.length - 1];
    c_.p = c.p.slice(0, c.p.length - 1).concat([c.lm]);
  }

  return c_;
}

export function invert(ops) {
  var op_ = ops.slice().reverse();
  var iop = [];
  for (var i = 0; i < op_.length; i++) {
    iop.push(invertComponent(op_[i]));
  }
  return iop;
}

/* eslint-enable */
