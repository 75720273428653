import { groupBy } from "lodash";
import type { ICollaborativeOperation } from "../../app/services/collaboration";
import { invert } from "./json0/invert";
import type {
  CollaborativeOperationsCommand,
  Command,
  CreateCollaborativeDocumentCommand,
  DeleteCollaborativeDocumentCommand
} from "./types";
import { isCreateCommand, isDeleteCommand, isOpCommand } from "./types";

// TODO LESS COPYING

export function invertOpCommand(
  command: CollaborativeOperationsCommand
): CollaborativeOperationsCommand {
  return {
    ...command,
    payload: {
      ...command.payload,
      ops: invert(command.payload.ops) as ICollaborativeOperation[]
    }
  };
}

export function invertCreateCommand(
  command: CreateCollaborativeDocumentCommand
): DeleteCollaborativeDocumentCommand {
  return {
    ...command,
    type: "delete"
  };
}

export function invertDeleteCommand(
  command: DeleteCollaborativeDocumentCommand
): CreateCollaborativeDocumentCommand {
  return {
    ...command,
    type: "create"
  };
}

export function invertCommands(commands: Command[]): Command[] {
  const opCommands = commands.filter(isOpCommand);
  const opCommandsByCollection = groupBy(opCommands, (opCommand) => {
    return opCommand.payload.collectionId;
  });
  const opCommandsGrouped = Object.values(opCommandsByCollection).map(
    (opCommands) => {
      return opCommands.reduce((acc, opCommand) => {
        return {
          ...acc,
          payload: {
            ...acc.payload,
            ops: [...acc.payload.ops, ...opCommand.payload.ops]
          }
        };
      });
    }
  );

  const invertedOpCommands = opCommandsGrouped.map(invertOpCommand);
  const invertedCreateCommands = commands
    .filter(isCreateCommand)
    .map(invertCreateCommand);

  const invertedDeleteCommands = commands
    .filter(isDeleteCommand)
    .map(invertDeleteCommand);

  const invertedCommands = [
    ...invertedOpCommands,
    ...invertedCreateCommands,
    ...invertedDeleteCommands
  ].reverse();

  return invertedCommands;
}
