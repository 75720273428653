import type { ComponentDefinition } from "../types";

const Card: ComponentDefinition = {
  icon: "fa-kit fa-card-component",
  category: "dataview",
  disabled: false,
  base: {
    package: "primereact",
    type: "Card",
    key: "Card",
    events: {},
    props: {
      title: "Simple Card",
      subTitle: "Subtitle",
      footer: "Footer",
      className: {
        "mt-2": true,
        "mb-2": true
      }
    },
    propMask: {},
    name: "Card",
    children: [
      {
        type: "text",
        value:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!"
      }
    ]
  },
  propDefs: [],
  eventList: [
    {
      name: "onClick",
      readableName: "On Click",
      description: "Fires when button is clicked"
    }
  ],
  styleDefs: ["spacing", "size", "typography", "border", "background"],
  availableDesignMode: "advanced"
};

export default Card;
