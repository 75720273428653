import { Skeleton } from "primereact/skeleton";

const DataModelTableSkeleton = () => {
  return (
    <>
      <Skeleton height="100%" width="100%" className="border-noround" />
    </>
  );
};

export default DataModelTableSkeleton;
