import type { ComponentDefinition } from "../types";

const Columns: ComponentDefinition = {
  icon: "c2-icons-button",
  category: "mostUsed",
  disabled: false,
  hidden: true,
  base: {
    package: "code2",
    type: "C2Columns",
    key: "Columns",
    events: {},
    props: {
      className: {
        "mt-1": true,
        "mb-1": true,
        "w-12": true,
        "h-12": true
      }
    },
    propMask: {},
    name: "Columns",
    children: [],
    uiBlock: ["Columns"]
  },
  eventList: [],
  propDefs: [],
  styleDefs: ["spacing", "size", "text", "border", "background"],
  availableDesignMode: "advanced"
};

export default Columns;
