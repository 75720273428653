import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";
import type { Command } from "./types";
import { invertCommands } from "./undoRedoUtils";

export interface UndoRedoState {
  undo: Command[][];
  redo: Command[][];
}

const initialState: UndoRedoState = {
  undo: [],
  redo: []
};

export const undoRedoSlice = createSlice({
  name: "undoRedo",
  initialState,
  reducers: {
    undo: (state) => {
      if (state.undo.length > 0) {
        const item = state.undo.pop()!;
        const inverted = invertCommands(item);
        // @ts-expect-error this should not happen
        state.redo.push(inverted);
      } else {
        console.warn(
          "Undo stack is empty, this action should not be called. There might be a bug."
        );
      }
    },
    undoWithoutPushingToRedo: (state) => {
      if (state.undo.length > 0) {
        state.undo.pop()!;
      } else {
        console.warn(
          "Undo stack is empty, this action should not be called. There might be a bug."
        );
      }
    },
    redo: (state) => {
      if (state.redo.length > 0) {
        const item = state.redo.pop()!;
        const inverted = invertCommands(item);
        // @ts-expect-error this should not happen
        state.undo.push(inverted);
      } else {
        console.warn(
          "Undo stack is empty, this action should not be called. There might be a bug."
        );
      }
    },
    redoWithoutPushingToUndo: (state) => {
      if (state.redo.length > 0) {
        state.redo.pop()!;
      } else {
        console.warn(
          "Undo stack is empty, this action should not be called. There might be a bug."
        );
      }
    },
    pushUndoableCommands: (state, action: PayloadAction<Command[]>) => {
      const commands = action.payload;
      const invertedCommands = invertCommands(commands);
      // @ts-expect-error ???
      state.undo.push(invertedCommands);
      state.redo = [];
    },
    mergeUndoableCommands: (state, action: PayloadAction<Command[]>) => {
      const commands = action.payload;
      const invertedCommands = invertCommands(commands);
      // @ts-expect-error ???
      state.undo[state.undo.length - 1].push(...invertedCommands);
      state.redo = [];
    },
    clear: (state) => {
      state.undo = [];
      state.redo = [];
    }
  }
});

export const {
  undo,
  redo,
  pushUndoableCommands,
  mergeUndoableCommands,
  clear,
  undoWithoutPushingToRedo,
  redoWithoutPushingToUndo
} = undoRedoSlice.actions;

export const selectUndo = (state: RootState) => state.undoRedo.undo;
export const selectRedo = (state: RootState) => state.undoRedo.redo;

export default undoRedoSlice.reducer;
