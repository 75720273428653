import { classNames } from "primereact/utils";
import { memo, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import type { NodeProps } from "reactflow";
import { Handle, Position } from "reactflow";
import "./AnimatedEntry.css";
import type { IDefaultCaseNodeData } from "../../types";
import { useAppDispatch } from "../../../../app/hooks";
import { setSelectedNode } from "../../flowDesignerSlice";

const DefaultCaseNode = ({
  data: { switchNodeId, orientation }
}: NodeProps<IDefaultCaseNodeData>) => {
  const [inProp, setInProp] = useState(false);
  const nodeRef = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setInProp(true);
  }, []);

  const targetHandlePosition =
    orientation === "horizontal" ? Position.Left : Position.Top;
  const targetHandleClassName =
    orientation === "horizontal" ? "left-0" : "top-0";
  const sourceHandlePosition =
    orientation === "horizontal" ? Position.Right : Position.Bottom;
  const sourceHandleClassName =
    orientation === "horizontal" ? "right-0" : "bottom-0";

  return (
    <>
      <Handle
        type="target"
        position={targetHandlePosition}
        className={targetHandleClassName}
        style={{ visibility: "hidden" }}
      />
      <CSSTransition
        nodeRef={nodeRef}
        timeout={500}
        in={inProp}
        classNames="animated-entry-node"
      >
        <div ref={nodeRef} className="w-full h-full relative cursor-pointer">
          <div
            className={classNames(
              "w-full h-full flex px-2 align-items-center justify-content-center border-round-sm bg-indigo-100 text-indigo-500"
            )}
            onClick={() => {
              dispatch(setSelectedNode(switchNodeId));
            }}
          >
            Default
          </div>
        </div>
      </CSSTransition>
      <Handle
        type="source"
        position={sourceHandlePosition}
        className={sourceHandleClassName}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

export default memo(DefaultCaseNode);
