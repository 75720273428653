import type { ChoiceRules } from "@code2io/fe-engine/dist/common-flow-utils";
import type { ChoiceState } from "@code2io/fe-engine/dist/flowExecutor";

interface IChoiceBuilder {
  manifest: ChoiceState;
}

class ChoiceBuilder implements IChoiceBuilder {
  manifest: ChoiceState;

  constructor() {
    this.manifest = {
      Type: "Choice",
      Choices: [],
      Default: ""
    };
  }

  setChoices(rules: ChoiceRules) {
    this.manifest.Choices = rules.Choices;
    this.manifest.Default = rules.Default;
    return this;
  }

  setComment(comment: string) {
    this.manifest.Comment = comment;
    return this;
  }

  build(): ChoiceState {
    return this.manifest;
  }
}

export { ChoiceBuilder };
